// --------------------------------------------------
//
// file upload
//
// --------------------------------------------------

// required　modules
// --------------------------------------------------
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core/";

import { formatPeriod } from "../../../../Utils/DateUtil";
import styled from "styled-components";

// personal modules
// --------------------------------------------------
import Panel from "../../../atoms/panels/Panel";
import IconNotInsurance from "../../../atoms/icons/IconNotInsurance";
import FontP from "../../../atoms/fonts/FontP";
import PatientInfo from "../../dialog/PatientInfo";
import { japaneseList } from "../../../../Resources/japaneseList";
const Resources = japaneseList.Components.Appointment.AppointmentApprovedDialog;

// style
// --------------------------------------------------
const styles = (theme) => ({
  dialogTitle: {
    width: "810px",
    height: "68px",
    margin: "0",
    padding: "24px 0 24px 24px",
    borderBottom: "1px solid #cccccc",
  },
  dialogContent: {
    width: "810px",
    margin: "0",
    padding: "22px 25px 20px 24px",
  },
  dialogActions: {
    width: "810px",
    height: "70px",
    margin: "0",
    padding: "14px 25px 16px 24px",
    borderTop: "1px solid #cccccc",
    "& button": {
      height: "40px",
    },
    "& span": {
      fontSize: "14px",
      fontWeight: "bold",
      color: "#ffffff",
    },
  },
  buttonOk: {
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
  },
  dialogActionsButtonOk: {
    width: "120px",
    margin: "0",
    padding: "13px 46px",
    borderRadius: "5px",
  },
  buttonCancel: {
    backgroundColor: "#808080",
    boxShadow: `0px 2px 0px ${theme.button.cancel.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
  },
  dialogActionsButtonCancel: {
    width: "120px",
    margin: "0 20px 0 0",
    padding: "13px 25px",
    borderRadius: "5px",
  },
  iconNotInsurance: {
    border: `1px solid ${theme.color.notInsurance}`,
    fontSize: "10px",
    padding: "0",
  },
  iconInsurance: {
    fontSize: "10px",
    border: `1px solid ${theme.color.primary1}`,
    padding: "0",
  },
  panel: {
    padding: "15px 0 0 0",
    margin: "0",
    color: "#808080",
  },
  font: {
    fontSize: "12px",
    lineHeight: "1.4",
  },
});

const DialogTitleText = styled.div`
  height: 20px;
  line-height: 20px;
  font-size: 20px;
  font-weight: bold;
`;

const DialogContentText = styled.div`
  height: 14px;
  line-height: 14px;
  font-size: 14px;
`;

const PatientArea = styled.div`
  margin: 0;
`;

const AppointmentArea = styled.div`
  margin: 21px 0 0 0;
`;

const Container = styled.div`
  border-bottom: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
`;

const Item = styled.div`
  display: grid;
  grid-template-columns: 80px auto;
  border-top: 1px solid #cccccc;
`;

const Title = styled.div`
  background-color: #eeeeee;
  height: 35px;
  padding-left: 10px;
  color: #808080;
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
`;

const Content = styled.div`
  height: 35px;
  padding-left: 20px;
  color: #393939;
  text-align: left;
  font-size: 14px;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  word-break: break-word;
`;

// class define
// --------------------------------------------------
class AppointmentApprovedDialog extends Component {
  // default state
  // --------------------------------------------------
  static defaultProps = {
    open: false,
    onClose: function () {},
    maxWidth: "md",
    handleClickOk: function () {},
    handleClickCancel: function () {},
  };

  // render
  // --------------------------------------------------
  render() {
    const {
      classes,
      open,
      onClose,
      maxWidth,
      handleOpenApprovedStatusDialog,
      handleCloseConfirmApprovedDialog,
      appointmentInfor,
    } = this.props;
    const appointmentTime = {
      from: appointmentInfor.from,
      to: appointmentInfor.to,
    };

    const patient = {
      familyName: appointmentInfor.familyName,
      givenName: appointmentInfor.givenName,
      familyNameKana: appointmentInfor.familyNameKana,
      givenNameKana: appointmentInfor.givenNameKana,
      birthdate: appointmentInfor.birthdate,
      phoneNumber: appointmentInfor.phoneNumber,
      examinationMenuName: appointmentInfor.displayName,
      supportsInsuranceTreatment: appointmentInfor.supportsInsuranceTreatment,
      gender: appointmentInfor.gender,
    };

    const doctor = appointmentInfor.doctor;

    const appointmetKey = {
      patientId: appointmentInfor.patientId,
      createTime: appointmentInfor.createTime,
      doctorId: doctor.doctorId,
    };

    const enterpriseName = appointmentInfor.enterpriseName || "";

    return (
      <Dialog
        disableBackdropClick
        open={open}
        onClose={onClose}
        maxWidth={maxWidth}
      >
        <DialogTitle className={classes.dialogTitle}>
          <DialogTitleText>
            {Resources.render.Dialog.DialogTitle.j001}
          </DialogTitleText>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            {Resources.render.Dialog.DialogContent.j001}
          </DialogContentText>
          <PatientArea>
            <PatientInfo patient={patient} enterpriseName={enterpriseName} />
          </PatientArea>
          <AppointmentArea>
            <Container>
              <Item>
                <Title>
                  {
                    Resources.render.Dialog.DialogContent.Appointment.Container
                      .Item.Title.j001
                  }
                </Title>
                <Content>
                  {doctor.familyName} {doctor.givenName}
                </Content>
              </Item>
              <Item>
                <Title>
                  {
                    Resources.render.Dialog.DialogContent.Appointment.Container
                      .Item.Title.j002
                  }
                </Title>
                <Content>
                  {patient.examinationMenuName}{" "}
                  <IconNotInsurance
                    className={
                      patient.supportsInsuranceTreatment
                        ? classes.iconInsurance
                        : classes.iconNotInsurance
                    }
                  >
                    {patient.supportsInsuranceTreatment
                      ? Resources.render.Dialog.DialogContent.InsuranceCard.ok
                      : Resources.render.Dialog.DialogContent.InsuranceCard.ng}
                  </IconNotInsurance>
                </Content>
              </Item>
              <Item>
                <Title>
                  {
                    Resources.render.Dialog.DialogContent.Appointment.Container
                      .Item.Title.j003
                  }
                </Title>
                <Content>
                  {appointmentTime.from && appointmentTime.to
                    ? formatPeriod(appointmentTime.from, appointmentTime.to)
                    : ""}
                </Content>
              </Item>
            </Container>
          </AppointmentArea>
          <Panel padding={"vmh0"} className={classes.panel}>
            <FontP className={classes.font} color="gray" whiteSpace={"preWrap"}>
              {Resources.render.Dialog.DialogContent.Panel.j001}
            </FontP>
          </Panel>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={handleCloseConfirmApprovedDialog}
            className={`${classes.buttonCancel} ${classes.dialogActionsButtonCancel}`}
          >
            {Resources.render.Dialog.DialogActions.Button.buttonCancel}
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={`${classes.buttonOk} ${classes.dialogActionsButtonOk}`}
            onClick={() =>
              handleOpenApprovedStatusDialog(appointmetKey, appointmentTime)
            }
          >
            {Resources.render.Dialog.DialogActions.Button.buttonOk}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(AppointmentApprovedDialog);
