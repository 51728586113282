import { isLnln } from "../Utils/checkLnln";
import { DELIVERY_OPTION, BILLING_CATEGORY_OPTION } from "../Utils/const";

const service_name = isLnln()
  ? "ルナルナ オンライン診療"
  : "CARADA オンライン診療";

export const japaneseList = {
  service: {
    name: `${service_name}`,
  },
  ExamTypes: {
    j001: "対面診療",
    j002: "オンライン相談",
    j003: "オンライン診療",
    j004: "産業医面談",
  },
  insurance: {
    ok: "保険適用",
    no: "保険適用外",
  },
  general: {
    datePostfix: {
      year: "年",
      month: "月",
      date: "日",
    },
    submitTexts: {
      confirm: "確定",
      cancel: "キャンセル",
    },
  },
  atoms: {
    forms: {
      InputFile: {
        noFile: "選択されていません",
        button: "選択",
      },
      DateSelect: {
        prevMonth: "＜前月",
        nextMonth: "翌月＞",
      },
    },
    others: {
      Operator: {
        patient: "（患者）",
        system: "（システム）",
        administrator: "（システム）",
      },
    },
  },
  models: {
    appointment: {
      payment: {
        deliverySelectedOption: {
          [DELIVERY_OPTION.empty]: "選択してください",
          [DELIVERY_OPTION.yamato]: "宅急便コンパクト",
          [DELIVERY_OPTION.csvPost]: "クリックポスト",
          [DELIVERY_OPTION.other]: "その他",
          [DELIVERY_OPTION.noDelivery]: "配送なし",
        },
      },
      deliveryProductName: {
        medicine: "薬",
        prescription: "処方箋",
      },
    },
  },
  molecules: {
    PasswordPolicy: {
      render: {
        j000: "※",
        j001: "半角英字（大文字と小文字をそれぞれ1文字以上）、数字を組み合わせて、8文字以上で入力してください。",
        j002: "ログインに使用しているメールアドレスの＠より前と一致する文字列は使用できません。",
      },
    },
    dialogs: {
      DialogInsuranceCardConfirm: {
        title: "保険証",
        caution:
          "保険証の画像は毎月１日の0時に自動的に削除されます。お早めにご確認ください。",
        button: "閉じる",
        rotateButton: "画像を90°回転",
      },
      DialogInsuranceCardCaution: {
        title: "保険証が登録されていません",
        text: "患者様より、保険証画像のアップロードがされていないため表示できません。 保険が適用される診療では、保険証の確認をお願いしております。患者様が下記の条件に当てはまる場合、保険証画像のアップロードが必要な旨メールにてご案内しております。お急ぎの場合は、電話にて直接ご連絡をお願いいたします",
        condition:
          "・毎月１日の時点で、当月に保険が適用されるオンライン診療の予約がある場合\n・保険が適用されるオンライン診療の予約１時間前",
        button: "閉じる",
      },
      DialogPatientInfo: {
        title: "患者基本情報",
        button: "閉じる",
        infoTitle: {
          j001: "生年月日",
          j002: "年齢",
          j003: "性別",
          j004: "電話",
          j005: "住所",
          j006: "メールアドレス",
          j007: "クレジットカード登録",
        },
        dateFormat: "yyyy/MM/dd",
        cardRegistered: "登録済み",
        unregistered: "未登録",
      },
      DialogUtil: {
        button: { cancel: "閉じる", ok: "ok" },
      },
      UploadAlert: {
        success: "ファイルのアップロードが完了しました。",
        error:
          "アップロード中にエラーが発生しました。時間を空けて再度お試しください。",
      },
      DialogCallingUnready: {
        title: {
          doctor: "通話不可（医療機関側）",
          patient: "通話不可（患者側）",
        },
        content: {
          doctor: {
            text: "医療機関側で以下の原因があるため、通話を開始することができません。",
            condition: {
              j001: "・医療機関の端末がマイク/カメラの使用を許可していないため",
            },
          },
          patient: {
            text: "患者様側で以下のいずれかの原因があるため、通話を開始することができません。",
            condition: {
              j001: "・サイトにログインしていないため",
              j002: "・サイトにログイン後、ビデオ通話画面にアクセスしていないため",
              j003: "・患者様の端末がマイク/カメラの使用を許可していないため",
            },
          },
        },
        button: "閉じる",
      },
      DialogConfirm: {
        button: {
          j001: "終了",
          j002: "キャンセル",
          j003: "閉じる",
        },
      },
      DialogError: {
        button: {
          j001: "閉じる",
        },
      },
      DialogCancelConfirm: {
        Text: {
          j001: "このまま終了してよろしいですか？",
          j002: "※入力した内容は保存されません。",
        },
        Button: {
          j001: "キャンセル",
          j002: "終了",
        },
      },
    },
    pager: {
      prev: "＜",
      next: "＞",
      more: "…",
      displayItemCount: "#totalItemCount件中 #start 〜 #end件",
      displayNoItemCount: "0件中 0件",
    },
    operationTypes: {
      Create: {
        j001: "予約：",
        j002: "受付",
        j003: "作成",
        j004: "\r\n",
      },
      Approve: {
        j001: "予約：",
        j002: "承認",
      },
      Unapprove: {
        j001: "予約：",
        j002: "否認（承認期限経過）",
        j003: "否認",
      },
      UpdateTime: {
        j001: "予約：",
        j002: "日時変更",
        j003: "\r\n",
      },
      Cancel: {
        j001: "予約：",
        j002: "キャンセル",
      },
      UpdateStatus: {
        j001: "\r\n",
        j002: "予約：",
        j003: "ステータス変更",
        j004: "→",
      },
      StartVideoChat: {
        j001: "ビデオ通話：",
        j002: "開始",
      },
      RestartVideoChat: {
        j001: "ビデオ通話：",
        j002: "再開",
      },
      EndVideoChat: {
        j001: "ビデオ通話：",
        j002: "終了",
      },
      UploadAttachment: {
        j001: "ファイル：",
        j002: "アップロード",
        j003: "ファイルの有効期限切れ",
      },
      RemoveAttachment: {
        j001: "ファイル：",
        j002: "削除",
      },
      Settle: {
        j001: "\r\n",
        j002: "会計：",
        j003: "確定",
        j004: "決済エラー",
        j005: "配送エラー",
      },
      ChageMedicalExpense: {
        j001: "会計：",
        j002: "変更",
      },
      Administrator: {
        j001: "その他",
      },
      CancelPayment: {
        j001: "会計：",
        j002: "取り消し",
      },
      DoctorLowNetwork: {
        j001: "ビデオ通話：",
        j002: "ネット接続レベル低下（医療機関側）",
      },
      PatientLowNetwork: {
        j001: "ビデオ通話：",
        j002: "ネット接続レベル低下（患者側）",
      },
    },
    operationTypeRemarks: {
      AppointmentStatus: {
        beforeExam: "診療前",
        examCompleted: "診療完了",
        rejected: "予約否認",
        paymentCompleted: "会計完了",
        unapproved: "未承認",
        canceledBeforeApproving: "承認前キャンセル",
        canceledDoctor: "キャンセル",
        canceledPatientPreviousDay: "キャンセル",
        canceledPatientFromToday: "キャンセル",
      },
    },
  },
  Authenticator: {
    SignIn: {
      showVerifyCaptchaError: {
        j001: "認証できませんでした。しばらく経ってから再度お試しください。",
      },
      signIn: {
        j001: "メールアドレスまたはパスワードが正しくありません。5回間違えると一定時間アカウントがロックされます。",
        j002: "アカウントがロックされています。少し時間を空けてから、再度お試しください。",
        j003: "アカウントの状態が不正です。お問い合わせ窓口までご連絡ください。",
        j004: "予期せぬエラーが発生しました。少し時間を空けてから、再度お試しください。",
        j005: "メールアドレスまたはパスワードに全角文字が含まれています。すべて半角文字で入力してください。",
        j006: "メールアドレスまたはパスワードに不要なスペースが含まれています。入力内容をご確認ください。",
      },
      render: {
        Grid: {
          div: {
            image: {
              j001: `${service_name}`,
            },
          },
          Grid: {
            form: {
              TextField: {
                j001: "メールアドレス",
                j002: "パスワード",
              },
              Button: {
                j001: "ログイン",
                Typography: {
                  j001: "パスワードを忘れた方は",
                  j002: "パスワードを忘れた方はこちら＞",
                },
              },
              Typography: {
                j001: "動作ブラウザ：Google Chrome 64以上",
              },
              CheckBox: {
                j001: "パスワードを表示",
              },
            },
          },
          Notification: {
            Title: {
              j001: "【ご利用のブラウザをご確認ください】",
            },
            Body: {
              j001: "以下のブラウザ以外ではご利用になれませんのでご注意ください。",
            },
            OS: {
              j001: "WindowsOS：",
              j002: "MacOS：",
              j003: "iOS：",
            },
            Browser: {
              j001: "Google Chrome",
              j002: "Safari",
              j003: "Microsoft Edge",
            },
          },
        },
      },
    },
    ForgotPassword: {
      requestAuthCode: {
        j001: "このメールアドレスは登録されていません。入力内容をご確認ください。",
        j002: "予期せぬエラーが発生しました。時間をおいても解消されない場合、メールに記載の問い合わせ窓口までご連絡ください。",
      },
      submit: {
        j001: "パスワードは、8文字以上で設定してください。",
        j002: "パスワードは、半角英字の大文字小文字と半角数字をそれぞれ1文字以上含めてください。",
        j003: "メールアドレスを含む文字列はパスワードとして設定できません。",
        j004: "パスワードが一致しません。入力内容をご確認ください。",
        j005: "認証コードに誤りがあるか、有効期限切れです。入力内容と有効期限をご確認ください。",
        j006: "パスワードは、半角英字の大文字小文字と半角数字をそれぞれ1文字以上含めた8文字以上で入力してください。",
        j007: "予期せぬエラーが発生しました。時間をおいても解消されない場合、メールに記載の問い合わせ窓口までご連絡ください。",
      },
      render: {
        Grid: {
          Grid: {
            Typography: {
              j001: "パスワード再設定",
              j002: "パスワードは、下記を避けご登録ください。",
              j003: "※他人に推測されやすい生年月日などをもとにしたパスワード",
              j004: "※他サービスで利用しているパスワード",
              j005: "パスワード再設定",
            },
            TextField: {
              j001: "メールアドレス",
              j002: "認証コード",
              j003: "新パスワード",
              j004: "新パスワード確認",
            },
            CheckBox: {
              j001: "パスワードを表示",
            },
            Button: {
              j001: "認証コード送信",
              Typography: {
                j001: "ログインページへ戻る",
              },
              j002: "登録",
            },
          },
        },
      },
    },
    NewPassword: {
      change: {
        j001: "パスワードは、8文字以上で設定してください。",
        j002: "パスワードは、半角英字の大文字小文字と半角数字をそれぞれ1文字以上含めてください。",
        j003: "メールアドレスを含む文字列はパスワードとして設定できません。",
        j004: "パスワードが一致しません。入力内容をご確認ください。",
        j005: "しばらく操作がないためログアウトしました。初期パスワードを利用して、再度ログインしてください。",
        j006: "パスワードは、半角英字の大文字小文字と半角数字をそれぞれ1文字以上含めた8文字以上で入力してください。",
        j007: "予期せぬエラーが発生しました。時間をおいても解消されない場合、メールに記載の問い合わせ窓口までご連絡ください。",
      },
      render: {
        Grid: {
          Grid: {
            Typography: {
              j001: "新パスワード設定",
            },
            TextField: {
              j001: "新パスワード",
              j002: "パスワード確認",
            },
            CheckBox: {
              j001: "パスワードを表示",
            },
            Button: {
              j001: "設定",
              Typography: {
                j001: "ログインページへ戻る",
              },
            },
          },
        },
      },
    },
  },
  Components: {
    Appointment: {
      AppointConditionInput: {
        handleDateChange: {
          j001: "終了日は開始日以降の日付を設定してください。",
        },
        render: {
          div: {
            MuiPickersUtilsProvider: {
              div: {
                DatePicker: {
                  j001: "診療日",
                  j002: "終了",
                },
                Button: {
                  j001: "検索",
                  j002: "翌日>",
                  j003: "<前日",
                },
              },
            },
          },
        },
      },
      AppointmentList: {
        insuranceCard: {
          ok: "保険適用",
          ng: "保険適用外",
        },
        consentRegistration: {
          unregistered: "未登録",
          registering: "登録中",
          registered: "登録済",
          unregistrable: "-",
        },
        examinationWelfare: {
          j001: "オンライン診療",
          j002: "(福利厚生・保険適用外)",
        },
        EnterpriseName: "法人名：",
        render: {
          div: {
            table: {
              thead: {
                tr: {
                  td: {
                    j001: "No ",
                    j002: "予約時間",
                    j003: "名前",
                    j003_2: "保険証",
                    j004: "生年月日",
                    j005: "年齢",
                    j006: "メニュー",
                    j007: "ステータス",
                    j008: "通話開始",
                    j009: "ファイル",
                    j010: "取消",
                    j011: "担当者",
                    j012: "資格確認同意",
                  },
                },
              },
            },
            Typography: {
              j001: "検索条件に一致する予約はありません。",
            },
            div: {
              table: {
                tbody: {
                  tr: {
                    td: {
                      button: {
                        j001: "ビデオ通話",
                        j002: "変更",
                        j003: "取消",
                        j004: "追加・確認",
                        j005: "未登録",
                        j006: "確認",
                        j007: "変更",
                        j008: "ファイル有",
                      },
                      type: {
                        j001: "なし（来院予約）",
                      },
                    },
                  },
                },
              },
            },
          },
        },
        DialogConfirm: {
          title: "このまま終了してよろしいですか？",
          content: "※入力した内容は保存されません。",
        },
        ErrorResources: {
          buttonCancel: "閉じる",
          buttonOk: "閉じる",
          Errors: [
            {
              //Internal error
              code: "E01",
              text: "予期せぬエラーが起こりました。ページを再読み込みしてください。",
            },
            {
              //Cannot find param
              code: "E02",
              text: "予期せぬエラーが起こりました。ページを再読み込みしてください。",
            },
            {
              //Error cannot return value from db
              code: "E03",
              text: "予期せぬエラーが起こりました。ページを再読み込みしてください。",
            },
            {
              //The appointment was canceled before the doctor change status
              code: "E04",
              text: "患者側でメニューのキャンセルが行われたためステータス変更できません。\nお手数ですが、ステータスを変更する場合は問い合わせ窓口までご連絡ください。",
            },
            {
              //The status has already changed. Please reload the page.
              code: "E05",
              text: "既にステータスが変更されています。ページを再読み込みしてください。",
            },
            {
              //Unexpected error when insert/update/delete into database
              code: "E06",
              text: "予期せぬエラーが起こりました。\n画面をリロードすることで解消されることがあります。",
            },
            {
              //It cannot be edited because it was the month following the medical treatment month
              //If you would like to edit, please contact us.
              code: "E07",
              text: "診療月の翌月となったため、編集することができません。\n編集を行う場合は問い合わせ窓口までご連絡ください。",
            },
          ],
          accessFileError:
            "指定のファイルは削除されているため、アップロードできません。",
        },
        StaffDialog: {
          text: {
            j001: "ご利用のアカウントではビデオ通話ができません。",
            j002: "担当者のアカウントでログインしてください。",
          },
          button: {
            j001: "閉じる",
          },
        },
        ConsentRegistrationDialog: {
          title: {
            text: "オンライン資格確認への同意：",
            unregistered: "未登録",
            registering: "登録中",
            registered: "登録済",
          },
          description: {
            unregistered:
              "患者様がオンライン資格確認の同意情報を登録していません。同意情報が登録されると、登録済に更新されます。​",
            registering:
              "オンライン資格確認の登録状況が不明です。同意情報の登録有無はオンライン資格確認等システムをご確認ください。​",
            registered: {
              common:
                "同意内容等の詳細はオンライン資格確認等システムをご確認ください。",
              consentModificationAttempted:
                "に同意情報の更新もしくは取消がありました。最新の同意内容の詳細はオンライン資格確認等システムをご確認ください。​",
            },
          },
          close: "閉じる",
        },
      },
      AppointmentUnapprovedList: {
        insuranceCard: {
          yes: "保険適用",
          no: "保険適用外",
        },
        render: {
          div: {
            table: {
              thead: {
                tr: {
                  td: {
                    j001: "No. ",
                    j002: "予約登録日時",
                    j003: "予約日時",
                    j004: "名前",
                    j005: "メニュー",
                    j006: "ステータス",
                    j007: "操作",
                    j008: "担当者",
                    j009: "ファイル",
                    j010: "資格確認同意",
                  },
                },
              },
            },
            Typography: {
              j001: "未承認の予約はありません。",
            },
            div: {
              table: {
                tbody: {
                  tr: {
                    td: {
                      button: {
                        j001: "承認",
                        j002: "否認",
                        j003: "ファイル有",
                      },
                      consentRegistration: {
                        unregistered: "未登録",
                        registering: "登録中",
                        registered: "登録済",
                        unregistrable: "-",
                      },
                    },
                  },
                },
              },
            },
          },
        },
        EnterpriseName: "法人名：",
      },
      AppointmentDoctors: {
        text: "担当者",
      },
      AppointmentCancelDialog: {
        handleCancelAppointment: {
          j001: "予約更新失敗",
        },
        render: {
          div: {
            Dialog: {
              DialogTitle: {
                j001: "予約の取り消し",
                j002: "予約取り消し完了",
              },
              DialogContent: {
                j001: "こちらの予約を取り消してよろしいですか？",
                j002: "予約を取り消しました。",
                Button: {
                  DisplayText: {
                    j001: "閉じる",
                  },
                },
              },
              Appointment: {
                Container: {
                  Item: {
                    Title: {
                      j001: "担当者",
                      j002: "予約日時",
                    },
                    Content: {
                      j001: "なし（来院予約）",
                    },
                  },
                },
              },
              DialogActions: {
                Button: {
                  j001: "キャンセル",
                  j002: "予約を取り消す",
                },
              },
            },
          },
        },
      },
      AppointmentRejectDialog: {
        render: {
          div: {
            Dialog: {
              DialogTitle: {
                j001: "否認理由の選択",
              },
              DialogContent: {
                NoteTitle: {
                  j001: "こちらの予約を否認する理由を選択してください。",
                },
                Item: {
                  j001: "担当者",
                  j002: "メニュー",
                  j003: "診療日時",
                  j004: "否認理由",
                },
                Reason: {
                  symbol: "▼",
                  j001: "否認理由を選択してください",
                  j002: "【必須】",
                  j003: "来院での診療が必要なため",
                  j004: "その他",
                  j005: "理由を入力してください",
                },
                TextareaCustom: {
                  j001: "200字以内で否認理由を入力してください。",
                },
                Note: {
                  j001: "※否認した予約は「診療一覧」にてご確認いただけます。\n​※一度否認を確定すると、訂正することができませんのでご注意ください。​",
                },
                Button: {
                  DisplayText: {
                    j001: "キャンセル",
                    j002: "確認",
                  },
                },
              },
            },
          },
        },
      },
      AppointmentRejectConfirmDialog: {
        render: {
          div: {
            Dialog: {
              DialogTitle: {
                j001: "否認理由の確認",
                j002: "こちらの内容で確定してよろしいですか？",
              },
              DialogContent: {
                Item: {
                  j001: "担当者",
                  j002: "メニュー",
                  j003: "診療日時",
                  j004: "否認理由",
                },
                Reason: {
                  j001: "否認理由",
                  j002: "来院での診療が必要なため ",
                  j003: "【その他】",
                },
                Note: {
                  j001: "※ 否認した予約は「診療一覧」にてご確認いただけます。\n※ 一度否認を確定すると、訂正することができませんのでご注意ください。",
                },
                Button: {
                  DisplayText: {
                    j001: "＜戻る",
                    j002: "キャンセル",
                    j003: "確定",
                  },
                },
              },
            },
          },
        },
      },
      AppointmentRejectStatusDialog: {
        title: "否認が完了しました",
        successHospital: "否認した予約は「診療一覧」にてご確認いただけます。",
        successPharmacy:
          "否認した予約は「服薬指導一覧」にてご確認いただけます。",
        error: {
          j001: "対象の予約がキャンセルされたため、\n承認または否認することができません。",
          j002: "診療時間が経過したため、\n対象の予約は自動的に否認されました。",
          j003: "承認または否認中にエラーが発生しました。\nしばらくしてから再度お試しください。",
          j004: "予約された診療時間を過ぎているため、操作できません。\nこの予約は自動的に否認されます。",
        },
        buttonOkHospital: "診療一覧へ",
        buttonOkPharmacy: "服薬指導一覧へ",
        buttonCancel: "閉じる",
      },
      AppointmentUpdate: {
        handleEndTimeChange: {
          j001: "不正な時間が設定されています。終了時間は開始時間よりも後の時間にしてください。",
        },
        calculateEndTime: {
          j001: "不正な時間が設定されています。終了時間は開始時間よりも後の時間にしてください。",
        },
        update: {
          j001: "エラーが発生しました。",
          j002: "指定した日時に既に他の予約枠または予約が登録されています。日時を変更してください。",
          j003: "指定した日時に既に他の予約枠または予約が登録されています。日時を変更してください。",
          j004: "診療終了時刻は過去の時刻を指定できません。",
          j005: "システムエラーが発生しました。",
          j006: "該当の患者様は指定した日時に既に予約があります。日時を変更してください。",
          j007: "この日時はシステムメンテナンスが予定されています。 日時を変更してください。",
          j008: "指定日時に予約を希望する患者様がいるようです。日時を変更するか、しばらくしてから再度お試しください。",
          j009: "不正な時間が設定されています。終了時間は開始時間よりも後の時間にしてください。",
        },
        render: {
          MuiPickersUtilsProvider: {
            DialogTitle: {
              Text: {
                j001: "予約日時の変更",
              },
            },
            DialogContent: {
              Appointment: {
                Container: {
                  Title: {
                    j001: "担当者",
                    j002: "予約日",
                    j003: "時間",
                    j004: "開始時間",
                    j005: "終了時間",
                  },
                  Content: {
                    j001: "なし（来院予約）",
                  },
                  ItemSecondaryAction: {
                    Select: {
                      option: {
                        j001: "分",
                      },
                    },
                  },
                },
              },
            },
            DialogActions: {
              Button: {
                j001: "キャンセル",
                j002: "更新",
              },
            },
          },
        },
      },
      FileuploadConfirmDialogFile: {
        FileError: {
          j001: "指定のファイルは削除されているため、表示できません。",
          j002: "閉じる",
        },
      },
      FileuploadDialog: {
        title: "ファイルの共有",
        thumbnailListTitle: "患者のアップロードファイル",
        existFileTitle: "医療機関のアップロードファイル",
        noHaveFileUpload: "アップロード済みのファイルはありません。",
        deletedFileMessage:
          "ファイルを削除しました。追加するファイルが無い場合は「閉じる」ボタンを押してください。",
        fileSelectText: "ファイルのアップロード",
        fileSelectNoteText: "（1ファイル2MBまで）",
        fileNameText: "ファイル名",
        fileNameNoteText: "（全角20文字まで）",
        fileNamePlaceholder: "選択されていません",
        buttonSelectFile: "選択",
        buttonOk: "確定",
        buttonUpload: "アップロード",
        buttonCancel: "キャンセル",
        buttonClose: "閉じる",
        buttonDelete: "削除",
        undertaker: "担当者",
        notCompleteYet: "ビデオ通話完了後にアップロードが可能になります。",
        notUploadFile:
          "来院予約メニューでは、ファイルのアップロードはできません。",
        notCompleteYetcaution:
          "※アップロードできるファイル形式は、.pdf/.png/.jpgです。",
        cautionText:
          "※アップロードできるファイル形式は、.pdf/.png/.jpgです。\n※アップロードするファイル名と異なるファイル名を患者側に表示したい場合は、ファイルを選択後、テキストボックス内で修正してください。\n※指定がない場合は、アップロードするファイル名がそのまま患者側へ表示されます。",
        Errors: [
          {
            code: "tooBigFileSize",
            text: "ファイルサイズが大きすぎます。2MB未満のファイルをご用意ください。",
          },
          {
            code: "tooLongLocalFileName",
            text: "選択したファイル名が全角20文字を超えています。ファイル名を修正のうえ再度ファイルを選択するか、ファイル名入力欄に新しいファイル名を入力してください。",
          },
          {
            code: "tooLongFileName",
            text: "入力文字数が全角20文字を超えています。入力内容を修正してください。",
          },
          {
            code: "unusableFileName",
            text: '使用できない文字列が含まれています。特殊文字や次の半角記号（¥ / : * ? " < > |.）が含まれていないかご確認ください。',
          },
          {
            code: "invalidExtension",
            text: "アップロードできるファイル形式は、.pdf/.png/.jpgです。選択したファイルを確認してください。",
          },
          {
            code: "errSameName",
            text: "同じ名前のファイルが既に存在します。ファイル名を修正のうえ再度ファイルを選択するか、ファイル名入力欄に新しいファイル名を入力してください。",
          },
          {
            code: "emptyFileList",
            text: "ファイルが選択されていません。「選択」ボタンからファイルを選択してください。",
          },
          {
            code: "localFileModified",
            text: "選択されたファイルが存在しません。存在するファイルを選択してください。",
          },
        ],
        placeHolderInputFileName: "ファイルを選択してください。",
        expiredDay: {
          j001: "表示期限:",
          j002: "まで",
        },
        contentNoFile: "アップロード済みのファイルはありません。",
      },
      FileuploadConfirmDialog: {
        title: "アップロードするファイルの確認",
        desc: "このファイルをアップロードしますか？",
        buttonOk: "アップロード",
        buttonCancel: "キャンセル",
        backButton: "＜戻る",
      },
      FileuploadCompleteDialog: {
        desc: "ファイルのアップロードが完了しました。",
        buttonOk: "閉じる",
        buttonNext: "会計画面へ",
        err001: "アップロード中にエラーが発生しました。",
        err002: "時間を空けて再度お試しください。",
      },
      FileUploadDeleteConfirmDialog: {
        title: " を削除しますか？",
        buttonOk: "削除",
        buttonCancel: "キャンセル",
      },
      FileUploadAccessErrorDialog: {
        title: "指定のファイルは削除されているため、表示できません。",
        buttonClose: "閉じる",
      },
      Stepper: {
        CalendarStepper: {
          constructor: {
            j001: "選択",
            j002: "検索",
            j003: "登録",
            j004: "選択",
            j005: "登録",
          },
          onHoverAppointmentButton: {
            j001: "選択",
            j002: "検索",
            j003: "登録",
          },
          onHoverSlotButton: {
            j001: "選択",
            j002: "登録",
          },
          render: {
            Query: {
              Stepper: {
                Typography: {
                  j001: "ご利用のアカウントにメニューが設定されておりませんので、スケジュールからの予約/確認はできません。",
                },
              },
            },
          },
        },
        Menu: {
          render: {
            div: {
              DialogContent: {
                j001: "どちらを作成しますか？",
              },
              DialogActions: {
                Button: {
                  j001: "産業医面談予約へ進む",
                  j002: "患者指定予約",
                  j003: "予約受付枠",
                  j004: "キャンセル",
                },
              },
            },
          },
        },
        AppointmentDesignation: {
          DialogTitle: {
            title: "患者指定予約の作成",
          },
          DialogActions: {
            button: {
              j001: "<戻る",
              j002: "キャンセル",
              j003: "確認",
            },
          },
        },
        PatientSearch: {
          search: {
            j001: "電話番号の形式が不正です。電話番号はハイフン無しの半角数字で入力してください。",
            j002: "この電話番号で登録された会員は見つかりませんでした。",
            j003: "電話番号の形式が不正です。電話番号はハイフン無しの半角数字で入力してください。",
            j004: "この電話番号で登録された会員は見つかりませんでした。",
            j005: "内部エラーが発生しました。",
          },
          render: {
            div: {
              Text: {
                j001: "電話番号",
                j002: "患者の電話番号を入力してください。",
              },
              Button: {
                j001: "検索",
                j002: "戻る",
              },
            },
          },
        },
        Appointment: {
          handleEndTimeChange: {
            j001: "不正な時間が設定されています。終了時間は開始時間よりも後の時間にしてください。",
          },
          calculateEndTime: {
            j001: "不正な時間が設定されています。終了時間は開始時間よりも後の時間にしてください。",
          },
          create: {
            j001: "患者様のクレジットカード登録が完了していないため、予約を行うことができません。",
            j002: "患者様の住所登録が完了していないため、予約を行うことができません。",
            j003: "過去日時となっているため、予約できません。",
            j004: "指定の患者情報が見つかりませんでした",
            j005: "指定した日時に既に他の予約枠または予約が登録されています。日時を変更してください。",
            j006: "指定した日時に既に他の予約枠または予約が登録されています。日時を変更してください。",
            j007: "診療終了時刻は過去の時刻を指定できません",
            j008: "内部エラーが発生しました。",
            j009: "内部エラーが発生しました。",
            j010: "不正な時刻が設定されています。開始時刻または終了時刻を変更してください。",
            j011: "該当の患者様は指定した日時に既に予約があります。日時を変更してください。",
            j012: "この日時はシステムメンテナンスが予定されています。 日時を変更してください。",
            j013: "この予約枠は削除された可能性があります。スケジュール管理画面に戻り、はじめからやり直してください。",
            j014: "指定日時に予約を希望する患者様がいるようです。日時を変更するか、しばらくしてから再度お試しください。",
            j015: "この電話番号で登録された会員は見つかりませんでした。",
            j016: "不正な時間が設定されています。終了時間は開始時間よりも後の時間にしてください。",
          },
          Patient: {
            Title: {
              j001: "生年月日",
              j002: "年齢",
              j003: "性別",
              j004: "電話番号",
            },
          },
          ListItem: {
            ListItemText: {
              j001: "担当者",
              j002: "予約日",
              j003: "開始時間",
              j004: "終了時間",
              j005: "メニュー",
            },
            ListItemPlaceholder: {
              j001: "選択してください",
            },
            ListItemSecondaryAction: {
              Select: {
                option: {
                  j001: "分",
                },
              },
              TimePicker: {
                j001: "現在時刻",
              },
            },
            ValidateError: {
              j001: "診療メニューを選択してください。",
            },
          },
        },
        AppointmentConfirm: {
          DialogTitle: {
            j001: "患者指定予約の作成",
          },
          DialogContent: {
            Text: {
              j001: "こちらの内容で登録してよろしいですか？",
            },
            Patient: {
              Title: {
                j001: "生年月日",
                j002: "年齢",
                j003: "性別",
                j004: "電話番号",
              },
            },
            ListItem: {
              Text: {
                j001: "担当者",
                j002: "予約日時",
                j003: "メニュー",
              },
              Format: {
                j001: "yyyy年MM月dd日(iii)　HH:mm～",
                j002: "HH:mm",
              },
            },
          },
          DialogActions: {
            Button: {
              j001: "<戻る",
              j002: "キャンセル",
              j003: "登録",
            },
          },
        },
        Finish: {
          text: {
            j001: "登録が完了しました。",
          },
          render: {
            div: {
              Button: {
                j001: "閉じる",
              },
            },
          },
        },
        Slot: {
          DialogTitle: {
            DialogTitleText: {
              j001: "予約受付枠の作成",
            },
          },
          menuMethodNames: {
            j001: "オンライン",
            j002: "来院予約",
          },
          initData: {
            j001: "オンライン相談",
            j002: "対面診療",
            j003: "産業医面談",
          },
          handleEndTimeChange: {
            j002: "診療時間は15分〜24時間の間で設定してください。",
            j003: "診療時間は15分〜1時間の間で設定してください。",
            j004: "不正な時間が設定されています。終了時間は開始時間よりも後の時間にしてください。",
          },
          calculateEndTime: {
            j001: "不正な時間が設定されています。終了時間は開始時間よりも後の時間にしてください。",
          },
          create: {
            j001: "過去日時となっているため、予約できません。",
            j002: "予約受付可",
            j003: "予約枠を1つ以上指定してください。",
            j004: "各予約枠の時間が被らない様に指定してください。",
            j005: "15分単位で開始時間を指定してください。例)9:00、11:15、17:30、18:45",
            j006: "予約枠の時間は15分、30分、60分のみ指定可能です。",
            j007: "指定した日時に既に他の予約枠または予約が登録されています。日時を変更してください。",
            j008: "指定した日時に既に他の予約枠または予約が登録されています。日時を変更してください。",
            j009: "内部エラーが発生しました。",
            j010: "内部エラーが発生しました。",
            j011: "枠人数は1〜20の間で設定してください。",
            j012: "予約枠開始日より過去の日付を繰り返し終了の指定日に指定できません。",
            j013: "曜日が未選択です。",
            j014: "、他#overlappingCount件",
            j015: "予約データを作成中です。しばらくしてから再度お試しください。",
            j016: "予約枠開始日から終了日までに、繰り返しの曜日が含まれていません。日付または繰り返しの曜日を変更してください。",
          },
          handleIsRepetition: {
            j001: "なし",
            j002: "毎日",
            j003: "毎週",
            j004: "毎月",
            j005: "毎年",
            j006: "カスタム",
          },
          render: {
            weekCheckButton: {
              j001: "日",
              j002: "月",
              j003: "火",
              j004: "水",
              j005: "木",
              j006: "金",
              j007: "土",
            },
            endDateSelect: {
              j001: "指定する",
              j002: "指定しない",
            },
            MuiPickersUtilsProvider: {
              DialogContent: {
                Container: {
                  Item: {
                    Title: {
                      j002: "予約枠開始日",
                      j003: "開始時間",
                      j004: "終了時間",
                      j005: "診療タイプ",
                      j006: "枠人数",
                      j007: "繰り返し",
                      j008: "予約枠終了日",
                      j009: "毎週",
                    },
                  },
                },
              },
            },
          },
          DialogActions: {
            button: {
              j001: "＜戻る",
              j002: "キャンセル",
              j003: "確認",
            },
          },
        },
        SlotConfirm: {
          DialogTitle: {
            DialogTitleText: {
              j001: "予約受付枠の作成",
            },
          },
          DialogContent: {
            DialogContentText: {
              j001: "こちらの内容で登録してよろしいですか？",
            },
            AppointmentArea: {
              Container: {
                Item: {
                  Title: {
                    j001: "担当者",
                    j002: "予約枠開始日",
                    j003: "診療タイプ",
                    j004: "枠人数",
                    j005: "繰り返し",
                    j006: "予約枠終了日",
                  },
                },
              },
              Format: {
                j001: "~",
              },
              Type: {
                j001: "来院予約",
                j002: "オンライン",
                j003: "なし（来院予約）",
              },
              Slot: {
                j001: "指定しない",
              },
            },
          },
          DialogActions: {
            Button: {
              j001: "<戻る",
              j002: "キャンセル",
              j003: "登録",
            },
          },
          Errors: {
            j001: "内部エラーが発生しました。",
          },
        },
        DatePicker: {
          render: {
            Dialog: {
              DialogTitle: {
                j001: "時間指定",
              },
              DialogActions: {
                Button: {
                  j001: "取り消し",
                  j002: "確定",
                },
              },
            },
          },
        },
      },
      PatientSearch: {
        handleSearchButtonClicked: {
          j001: "電話番号の形式が不正です。電話番号はハイフン無しの半角数字で入力してください。",
          j002: "この電話番号で登録された会員は見つかりませんでした。",
          j003: "内部エラーが発生しました。",
        },
        render: {
          div: {
            TextField: {
              j001: "電話番号",
            },
            Button: {
              j001: "検索",
            },
          },
        },
      },
      AppointmentRegister: {
        _createAppointment: {
          j001: "指定日時すでに予約がありました",
        },
        _updateAppointment: {
          j001: "指定日時すでに予約がありました",
        },
        calcTime: {
          j001: "開始時刻",
          j002: "終了時刻",
          j003: "を正しく指定してください。",
          j004: "時間は1分以上でご指定ください。",
        },
        render: {
          j001: "患者様のクレジットカード登録が完了していないため、予約を行うことができません。",
          j002: "患者様の住所登録が完了していないため、予約を行うことができません。",
          j003: "病院の設定がされていません、管理者に問い合わせてください。",
          div: {
            Dialog: {
              DialogTitle: {
                j001: "予約設定",
              },
              DialogContent: {
                Grid: {
                  Grid: {
                    j001: "患者氏名:",
                    j002: "フリガナ:",
                    j003: "生年月日:",
                    j004: "予約日時:",
                    j005: "開始時刻 (24h):",
                    j006: "終了時刻 (24h):",
                    j007: "時間:",
                    j008: "分",
                  },
                },
                j001: "予約が完了しました。",
                Button: {
                  j001: "診療一覧",
                },
              },
              DialogActions: {
                Button: {
                  j001: "キャンセル",
                  j002: "変更",
                  j003: "登録",
                },
              },
            },
          },
        },
      },
      SearchResult: {
        render: {
          div: {
            div: {
              Typography: {
                j001: "患者氏名",
                j002: "フリガナ",
                j003: "生年月日",
              },
              Button: {
                j001: "検索",
              },
            },
          },
        },
      },
      AppointmentApprovedDialog: {
        render: {
          Dialog: {
            DialogTitle: {
              j001: "承認内容の確認",
            },
            DialogContent: {
              j001: "こちらの予約を承認しますか？",
              Appointment: {
                Container: {
                  Item: {
                    Title: {
                      j001: "担当者",
                      j002: "メニュー",
                      j003: "診療日時",
                    },
                  },
                },
              },
              InsuranceCard: {
                ok: "保険適用",
                ng: "保険適用外",
              },
              Panel: {
                j001: "※「オンライン診療の適切な実施に関する指針」に基づき、オンライン診療の実施可能な患者のみを承認してください。​\n※承認した予約は「診療一覧」にてご確認いただけます。\n​※一度承認を確定すると、訂正することができませんのでご注意ください。",
              },
            },
            DialogActions: {
              Button: {
                buttonCancel: "キャンセル",
                buttonOk: "承認",
              },
            },
          },
        },
      },
      ApprovedStatusComplete: {
        ApprovedComplete: {
          buttonCancel: "閉じる",
          buttonHospitalOk: "診療一覧へ",
          buttonPharmacyOk: "服薬指導一覧へ",
          render: {
            div: {
              Dialog: {
                DialogTitle: {
                  j001: "承認が完了しました。",
                },
                DialogContent: {
                  j001: "承認した予約は「診療一覧」にてご確認いただけます。",
                  j002: "承認した予約は「服薬指導一覧」にてご確認いただけます。",
                },
              },
            },
          },
        },
        ApprovedError: {
          //buttonCancel: "閉じる",
          buttonOk: "閉じる",
          Errors: [
            {
              //Because this appointment has been canceled from the patient, you cannnot approve or reject the appointment.
              code: "E01",
              text: "対象の予約がキャンセルされたため、\n承認または否認することができません。",
            },
            {
              //There was a error approving or rejecting this appointment . Please retry after a few minutes.
              code: "E02",
              text: "承認または否認中にエラーが発生しました。\nしばらくしてから再度お試しください。",
            },
            {
              //finish time of appointment is pass
              code: "E03",
              text: "診療時間が経過したため、\n対象の予約は自動的に否認されました。",
            },
            {
              //appointment time overdue prevent to approve and reject
              code: "E04",
              text: "予約された診療時間を過ぎているため、操作できません。\nこの予約は自動的に否認されます。",
            },
          ],
        },
      },
      StatusChangeDialog: {
        DialogTitle: "来院予約のステータス変更",
        buttonCancel: "戻る",
        buttonOk: "承認する",
        DialogContent: {
          table: {
            thBirthDay: "生年月日",
            thAge: "年齢",
            thGender: "性別",
            thPhonenumber: "電話番号",
          },
          messageConfirm: "こちらの内容で確定してよろしいですか？",
          containerBorder: {
            status: "ステータス",
            radioComplete: "診療完了",
            radioNoVisit: "来院なし",
            treatmentDay: "診療日",
            undertaker: "担当者",
            medicalFee: "請求額",
            medicalFeeUnit: "円",
            medicalFeeNote:
              "※患者様に請求する、診療/相談・処方に関する費用の合計を入力してください。",
            medicalFeeNoteConfirm:
              "※患者様に請求する、診療/相談・処方に関する費用の合計です。",
            medicalFeeZeroErrorMessage: "請求額が入力されていません",
            medicalFeeLengthErrorMessage:
              "入力できる金額は9,999,999円までです。入力内容を修正してください。",
            drugName: "処方薬",
            drugNameNone: "ー",
            drugNamePlaceholder:
              "処方した薬剤名を30文字以内で入力してください。",
            drugNameEmptyErrorMessage: "処方薬が入力されていません",
            drugNameMaxLengthErrorMessage:
              "入力文字数が30文字を超えています。入力内容を修正してください。",
            drugNameEWP: "処方薬名",
            drugNameEWPDefaultOption: "選択してください",
            drugNameConsultationEWP: "処方なし",
            drugNamePlaceholderEWP: "15文字以内で入力してください。",
            drugNameEmptyErrorMessageEWP:
              "処方薬の数量が「1」以上の場合は、処方薬名を入力してください。",
            drugNameMaxLengthErrorMessageEWP:
              "入力文字数が15文字を超えています。入力内容を修正してください。",
            drugQuantity: "処方薬の\n数量",
            drugQuantityPlaceholder:
              "処方した薬剤の数量を20文字以内で入力してください。",
            drugQuantityNote: "※初診の場合は、1ヶ月分のみの処方となります。",
            drugQuantityPlaceholderEWP: "選択してください",
            drugQuantityEmptyErrorMessage: "処方薬の数量が入力されていません",
            drugQuantityMaxLengthErrorMessage:
              "入力文字数が20文字を超えています。入力内容を修正してください。",
            drugQuantityValidErrorMessageEWP:
              "診療種別が「相談」の場合は、処方薬の数量は「0」以外選択できません。\n​診療種別または処方薬の数量を修正してください。",
            billingCategory: "診療種別",
            billingCategoryOption: {
              j001: "選択してください",
              [BILLING_CATEGORY_OPTION.FIRST_VISIT]: "初診",
              [BILLING_CATEGORY_OPTION.REVISIT]: "再診",
              [BILLING_CATEGORY_OPTION.CONSULTATION]: "相談",
              [BILLING_CATEGORY_OPTION.OTHER]: "その他",
              [BILLING_CATEGORY_OPTION.NO_VISIT]: "診療なし",
            },
            billingCategoryNote:
              "※「診療なし」は回線不良等で通話開始後に診療できなかった場合に選択ください。\n※「相談」「診療なし」の診療種別では薬の処方はできません。",
            memo: "メモ",
            memoErrMessage:
              "入力文字数が500文字を超えています。入力内容を修正してください。",
            memoPlaceholder:
              "500文字以内で入力してください。\n入力した内容は患者様へは共有されません。",
            offline: "なし（来院予約）",
            enterpriseMemoPlaceholder:
              "別途営業担当者から依頼した内容を500文字以内で入力してください。",
          },
        },
        DialogActions: {
          cancelButton: "キャンセル",
          confirmButton: "確認",
          confirmButtonDialogConfirm: "確定",
          confirmButtonDialogClose: "閉じる",
          backButton: "<戻る",
        },
        genderMale: "男性",
        treatmentComplete: "診療完了",
        notVisit: "来院なし",
      },
      DialogConfirmRegistration: {
        confirm: {
          j001: "指定した日時に既に他の予約枠が登録されています。",
          j002: "指定した日時に既に他の予約が登録されています。",
          j003: "他#overlappingCount件",
        },
        render: {
          Dialog: {
            div: {
              div: {
                AppDetail: {
                  appRow: {
                    j001: "名前",
                    j002: "メニュー",
                    j003: "生年月日",
                    j004: "年齢",
                    j005: "性別",
                  },
                  insuranceCard: {
                    yes: "保険適用",
                    no: "保険適用外",
                  },
                },
                ConfirmText: {
                  j001: "予約枠を作成してよろしいでしょうか？",
                  j002: "予約を作成してよろしいでしょうか？",
                },
                Button: {
                  j001: "いいえ",
                  j002: "はい",
                },
              },
            },
          },
        },
      },
    },
    Calendar: {
      EventDetail: {
        menuMethodNames: {
          j001: "オンライン",
          j002: "来院予約",
        },
        cancelEvent: {
          j001: "すでに予約が入っているため削除できません",
          j002: "内部エラーが発生しました",
          j003: "予約データを作成中です。しばらくしてから再度お試しください。",
        },
        _showRepetitionFreqency: {
          j001: "カスタム",
          j002: "毎日",
          j003: "毎週",
          j004: "毎月",
          j005: "毎年",
        },
        _showRepetitionEndDate: {
          j001: "なし",
        },
        render: {
          j001: "オンライン相談",
          j002: "来院予約",
          j003: "診療",
          j004: "産業医面談",
          div: {
            Dialog: {
              DialogTitle: {
                j001: "予約枠情報",
                j002: "予約枠取消完了",
              },
              DialogContent: {
                Grid: {
                  Grid: {
                    j001: "1枠当たりの",
                    j002: "時間",
                    j003: "分",
                    j004: "予約日付",
                    j005: "開始時間",
                    j006: "終了時間",
                    j007: "予約枠を取り消しました",
                    j008: "診療タイプ",
                  },
                  Fragment: {
                    Grid: {
                      j001: "診療タイプ",
                      j002: "枠人数",
                      j003: "人",
                      j004: "現在の予約",
                      j005: "なし",
                      j006: "現在の予約",
                      j007: "繰り返し",
                      j008: "繰り返し終了",
                      j009: "担当者",
                      j010: "予約枠の日時",
                      j011: "なし（来院予約）",
                    },
                  },
                  TableTile: {
                    j001: "現在この予約枠に入っている予約",
                    j002: "件",
                  },
                  Table: {
                    th: {
                      j001: "名前",
                      j002: "電話番号",
                      j003: "予約時間",
                      j004: "メニュー",
                      j005: "予約",
                    },
                    td: {
                      j001: "未承認",
                      j002: "完了",
                      j003: "仮予約",
                    },
                  },
                },
              },
              DialogActions: {
                Button: {
                  j001: "スケジュール管理に戻る",
                  j002: "予約枠を取り消す",
                  j003: "閉じる",
                  j004: "承認画面へ",
                  j005: "診療一覧画面へ",
                  j006: "今後の繰り返し予約枠を削除",
                  j007: "この予約枠のみを削除",
                  j008: "服薬指導一覧へ",
                },
              },
            },
          },
        },
        EnterpriseName: "法人名：",
      },
      ScheduleSelection: {
        render: {
          patientSearchPopup: {
            Dialog: {
              DialogTitle: {
                j001: "患者検索",
              },
            },
          },
          div: {
            Dialog: {
              DialogTitle: {
                j001: "スケジュールに追加する",
              },
              DialogActions: {
                Button: {
                  j001: "患者指定予約",
                  j002: "予約受付枠作成",
                },
              },
            },
          },
        },
      },
      SettingReservation: {
        componentDidUpdate: {
          j001: "病院の設定がされていません、管理者に問い合わせてください。",
        },
        handleSubmit: {
          j001: "指定日時すでに予約がありました",
        },
        _createSlots: {
          j001: "予約受付可",
        },
        _checkEnoughTime: {
          j001: "終了時間が不足しています（開始時刻と終了時刻が不足しています）",
        },
        render: {
          div: {
            MuiPickersUtilsProvider: {
              Dialog: {
                DialogTitle: {
                  j001: "予約枠設定",
                  j002: "予約設定",
                },
                DialogContent: {
                  Grid: {
                    Grid: {
                      j001: "1枠当たりの",
                      j002: "時間:",
                      j003: "分",
                      j004: "予約枠開始日:",
                      j005: "開始時刻(24ｈ)",
                      j006: "終了時刻(24ｈ)",
                    },
                  },
                  j001: "予約が完了しました。",
                  Button: {
                    j001: "スケジュール管理へ戻る",
                  },
                },
                DialogActions: {
                  Button: {
                    j001: "キャンセル",
                    j002: "保存",
                  },
                },
              },
            },
          },
        },
      },
    },
    Common: {
      DisplayText: {
        DisplayText: {
          j001: "診療",
          j002: "オンライン服薬指導",
        },
      },
    },
    Payment: {
      HospitalPaymentList: {
        render: {
          div: {
            table: {
              thead: {
                no: "No",
                dateTime: "予約日時",
                name: "名前",
                menu: "メニュー",
                reciveMedicaine: "処方せん送付",
                deliveryOpt: "配送方法",
                deliveryProc: "配送処理",
                deliveryFee: "配送料",
                deliveryFeeNote: "梱包資材料含",
                deliveryArrangementFee: "配送手配料",
                treatmentFee: {
                  hospital: "診療費/相談料",
                  pharmacy: "調剤費/薬学管理料",
                },
                treatmentFeeNote: {
                  hospital: "薬代・処方箋料含",
                  pharmacy: "薬代含む",
                },
                totalFee: "合計",
                status: "決済状況",
                undertaker: "担当者",
              },
            },
            Typography: {
              j001: "検索条件に一致する予約はありません。",
            },
            offline: "なし（来院予約）",
          },
          examinationWelfare: {
            j001: "オンライン診療",
            j002: "(福利厚生・保険適用外)",
          },
          charge: {
            j001: "コード利用",
          },
          deliveryProcButton: {
            j001: "伝票作成",
            j002: "CSV作成",
            j003: "料金入力",
          },
          deliveryLinkResult: {
            yamato: "伝票作成済",
            csv: "CSV作成済",
          },
          fee: {
            unit: { j001: "円" },
            treatment: {
              j001: "金額入力",
            },
          },
          paymentStatus: {
            j001: "確定済",
            j002: "未処理",
            j003: "変更",
            j004: "決済エラー",
            j005: "配送エラー",
          },
          MedicalExpenseButton: {
            j001: "料金入力",
            j002: "実績入力",
          },
          ResetDeliveryProcessButton: {
            j001: "変更",
          },
          medicineDeliveryButton: {
            j001: "薬局",
            j002: "自宅",
            j003: "未選択",
            j004: "-",
          },
          DialogConfirm: {
            title: "このまま終了してよろしいですか？",
            titleYamatoComplete: "配送情報送信完了",
            content: "※入力した内容は保存されません。",
            contentYamatoComplete:
              "配送1件承りました。集荷に参りますので荷物をご用意ください。",
          },
          PickUpTimeSpanCandidates: {
            j001: "～13:00（当日10:00まで受付）",
            j002: "14:00～16:00（当日14:00まで受付）",
            j003: "16:00～18:00（当日16:00まで受付）",
            j004: "17:00～18:30（当日17:00まで受付）",
            j005: "指定なし（当日17:00まで受付）",
          },
          ErrorResources: {
            buttonCancel: "閉じる",
            buttonOk: "閉じる",
            InternalError:
              "予期せぬエラーが起こりました。ページを再読み込みしてください。",
            InternalErrorTitle: "予期せぬエラーが発生しました。",
            Errors: [
              {
                code: "E01",
                text: "予期せぬエラーが起こりました。ページを再読み込みしてください。",
              },
              {
                code: "E02",
                text: "予期せぬエラーが起こりました。ページを再読み込みしてください。",
              },
              {
                code: "E03",
                text: "予期せぬエラーが起こりました。ページを再読み込みしてください。",
              },
              {
                //yamato on maintainance (every monday 03:00-05:00)
                title: "配送処理に失敗しました",
                code: "E04",
                text: "メンテナンス中(毎週月曜の3:00～5:00)の場合に設定する。",
              },
              {
                //The appointment delivery was changed by another doctor
                code: "E05",
                text: "既に配送方法が確定しています。この操作を完了することができません。\n会計一覧画面に戻り、ページを再読み込みしてください。",
              },
              {
                //Unexpected error when insert/update/delete into database
                code: "E06",
                text: "予期せぬエラーが起こりました。ページを再読み込みしてください。",
              },
              {
                //Cannot edit because it exceeds the editable period.
                code: "E07",
                text: "編集可能な期間を超えたため、編集することができません。",
              },
              {
                //Unexpected error when doctor choose delivery method but setting incorrect in database
                code: "E08",
                text: "予期せぬエラーが発生しました。他の操作は行わず、「お問い合わせ」よりご連絡ください。",
              },
              {
                //The medical expenses have already been fixed. Please reload the page.
                code: "E09",
                text: "すでに請求額が確定しています。ページを再読み込みしてください。",
              },
              {
                //An error occurred during payment processing (execTran)
                code: "E10",
                text: "決済処理中にエラーが発生しました。#error_code",
              },
              {
                //The operation cannot be completed because you are logged in with an account at another hospital. Please log in with the correct account.
                code: "E11",
                text: "ログインアカウントが正しくないため、操作を完了できません。アカウントをご確認ください。",
              },
              {
                //Yamato delivery api error (past date)
                title: "配送処理に失敗しました",
                code: "E12",
                text: "既に選択した集荷希望日時が過去の日時になっています。日時を再選択してください。",
              },
              {
                //Yamato delivery api error
                title: "配送処理に失敗しました",
                code: "E13",
                text: "患者様の配送先情報に不正なデータが登録されている、または集荷コメントに不正なデータが登録されているため配送処理を完了できませんでした。\n患者様にご連絡いただき配送先情報を更新いただく、または集荷コメントの内容をご確認ください。",
              },
              {
                //An error occurred during payment processing (execTran) and already completed delivery request
                code: "E14",
                text: "決済処理中にエラーが発生しました。#error_code\nヤマト運輸への集荷依頼が完了しております。お手数ですが、最寄りのヤマト運輸サービスセンターへ集荷依頼キャンセルのご連絡をお願いいたします。",
              },
              {
                title: "決済処理に失敗しました",
                code: "E15",
                text: "患者様のクレジットカード情報に問題があるため、決済が完了できませんでした。 ​\n患者様にも、決済エラーが発生したことをお知らせしております。\n患者様のクレジットカード情報の更新が滞っている場合は、患者様へ直接ご連絡ください。",
              },
              {
                title: "決済処理に失敗しました",
                code: "E16",
                text: "予期せぬエラーが発生し決済が完了できませんでした。お手数ですが問い合わせ担当窓口までご連絡ください。",
                approachText: "問い合わせ",
              },
              {
                title: "決済処理に失敗しました",
                code: "E17",
                text: "予期せぬエラーが発生し決済が完了できませんでした。お手数ですが問い合わせ窓口までご連絡ください。",
                approachText: "問い合わせ",
              },
              {
                title: "配送処理に失敗しました",
                code: "E18",
                text: "予期せぬエラーが発生し集荷処理が完了できませんでした。時間を空けてお試しください",
              },
              {
                title: "決済処理に失敗しました",
                code: "E19",
                text: "決済可能な金額の上限を超えているため、決済を完了できませんでした。\n10,000,000円未満の金額を入力してください。",
              },
              {
                title: "決済処理に失敗しました",
                code: "E20",
                text: "予期せぬエラーが発生し決済が完了できませんでした。\n配送処理が完了しているため、お手数ですがヤマト運輸への集荷のキャンセルをご連絡いただき、\n問い合わせ担当窓口までご連絡ください。",
                approachText: "問い合わせ",
              },
            ],
            GmoErrorCode: "（エラーコード：#error_code）",
          },
        },
        HospitalPaymentRowName: {
          EnterpriseName: "法人名：",
        }
      },
      MedicineDeliveryDialog: {
        DialogTitle: {
          j001: "処方せんの送付",
        },
        DialogContent: {
          j001: "処方せんに関する患者様のご希望を表示しています。\nご希望から変更される場合は、患者様とお話のうえ合意ください。",
          j002: "お薬の説明と受取方法",
          j003: "希望の処方せん送付先",
          j004: "オンラインでお薬の説明を聞いて、登録した住所にお薬を配送してもらう",
          j005: "登録した住所に処方せんを配送してもらい、自分で薬局にお薬を取りに行く",
          j006: "未選択",
          j007: "薬局名",
          j008: "郵便番号",
          j009: "住所",
          j010: "TEL",
          j011: "FAX",
          j012: "お名前",
          j013: "現在こちらの薬局は、処方せんを送付できません。",
        },
        DialogActions: {
          j001: "閉じる",
        },
      },
      FeeDeliveryDialog: {
        DialogTitle: {
          create: "配送料の入力",
          confirm: "配送料の確認",
          reset: "配送方法の変更 -その他-",
        },
        DialogContent: {
          j001: "月",
          j002: "日",
          description:
            "「宅急便コンパクト」・「クリックポスト」以外の方法で送りたい場合にご利用ください。\n 必ず追跡可能な方法をご利用ください。集荷依頼や送り状記入などを医療機関側にてご対応ください。",
          messageConfirm: "こちらの内容で確定してよろしいですか？",
          table: {
            thBirthDay: "生年月日",
            thAge: "年齢",
            thGender: "性別",
            thPhonenumber: "電話番号",
            genderMale: "男性",
          },
          containerBorder: {
            examDate: "実施日",
            slipNumber: "伝票番号",
            prefixImage: "〒",
            undertaker: "担当者",
            deliveryAddress: "お届け先\n住所",
            deliveryFee: "配送料",
            deliveryFeeDescription:
              "※梱包資材料を含む金額を入力してください。\n※表示金額を(株)カラダメディカから医療機関様にお返しします。",
            deliveryFeeDetailDescription:
              "※表示金額を(株)カラダメディカから医療機関様にお返しします。",
            deliveryArrangementFee: "配送手配料",
            deliveryArrangementFeeBefore: "未定",
            deliveryArrangementFeeDescription:
              "※配送手配に要した費用として(株)カラダメディカが患者様に請求する金額です。\n※次の画面で金額を確認できます。",
            deliveryArrangementFeeDetailDescription:
              "※配送手配に要した費用として(株)カラダメディカが患者様に請求する金額です。",
            deliveryFeeUnit: "円",
            deliveryFeeNote: "※送料と配送用資材代の合計を入力してください。",
            deliveryFeeNoteConfirm: "※送料と配送用資材代の合計です。",
            patientName: "お届け先\n氏名",
            collectionDate: "希望集荷日",
            dateSelectNote: "※本日を含めた3日以内の日付から選択できます。",
            timeSpan: "時間帯",
            content: "内容",
            comment: "集荷\nコメント",
            commentError:
              "入力文字数が全角100文字を超えています。入力内容を修正してください。",
            commentPlaceholder:
              "集荷担当者へのコメントがある場合は記入してください。",
            inputFeeError: "半角数字を入力してください。",
            offline: "なし（来院予約）",
            deliveryFeeLengthErrorMessage:
              "入力できる金額は9,999,999円までです。入力内容を修正してください。",
          },
        },
        DialogActions: {
          Button: {
            cancelButton: "キャンセル",
            confirmButton: "確認",
            anotherConfirmButton: "確定",
            backButton: "＜戻る",
            completeButton: "集荷依頼",
            closeButton: "閉じる",
          },
        },
        Format: {
          j001: "yyyy/MM/dd",
        },
      },
      YamatoDeliveryDialog: {
        onEnter: {
          j001: "～13:00（当日10:00まで受付）",
          j002: "14:00～16:00（当日14:00まで受付）",
          j003: "16:00～18:00（当日16:00まで受付）",
          j004: "17:00～18:30（当日17:00まで受付）",
          j005: "指定なし（当日17:00まで受付）",
        },
        DialogTitle: {
          create: "伝票作成 -宅急便コンパクト-",
          confirm: "伝票内容確認 -宅急便コンパクト-",
          reset: "配送方法の変更 -宅急便コンパクト-",
          result: "配送処理 -宅急便コンパクト-",
        },
        DialogContent: {
          j001: "月",
          j002: "日",
          j003: "年",
          description:
            "「宅急便コンパクト」はヤマト運輸が提供する配送方法です。\n伝票作成後に料金入力が確定されるまでは、ヤマト運輸への集荷依頼は完了しません。続けて料金を入力してください。",
          messageConfirm: "こちらの内容で確定してよろしいですか？",
          table: {
            thBirthDay: "生年月日",
            thAge: "年齢",
            thGender: "性別",
            thPhonenumber: "電話番号",
            genderMale: "男性",
          },
          containerBorder: {
            undertaker: "担当者",
            examDate: "診療実施日",
            slipNumber: "送り状番号",
            prefixImage: "〒",
            deliveryAddress: "お届け先\n住所",
            patientName: "お届け先\n氏名",
            deliveryFee: "配送料",
            deliveryFeeDescription:
              "※梱包資材料を含む金額です。\n※表示金額のうち、梱包資材料のみ(株)カラダメディカから医療機関様にお返しします。",
            deliveryArrangementFee: "配送手配料",
            deliveryArrangementFeeDescription:
              "※配送手配に要した費用として(株)カラダメディカが患者様に請求する金額です。",
            collectionDate: "希望集荷日",
            dateSelectNote: "※本日を含めた3日以内の日付から選択できます。",
            timeSpan: "時間帯",
            content: "内容",
            comment: "集荷\nコメント",
            commentError:
              "入力文字数が全角100文字を超えています。入力内容を修正してください。",
            commentYamatoError:
              "使用できない文字が入力されています。入力内容を修正してください。",
            commentPlaceholder:
              "集荷担当者へのコメントがある場合は記入してください。",
            yamatoError:
              "受付可能な時間が経過しています、別の時間帯を選択してください",
            offline: "なし（来院予約）",
          },
        },
        DialogActions: {
          Button: {
            cancelButton: "キャンセル",
            confirmButton: "確認",
            backButton: "＜戻る",
            completeButton: "集荷依頼",
            closeButton: "閉じる",
          },
        },
        Format: {
          j001: "yyyy/MM/dd",
          j002: "yyyy-MM-dd",
        },
      },
      YamatoInquiryAnnounce: {
        render: {
          LinkText: {
            j001: "ヤマト運輸サービスセンター一覧",
            j002: "（ヤマト運輸のサイトへ遷移します）",
          },
        },
      },
      CustomNumber: {
        NumberFormat: {
          j001: "円",
          j002: "-",
        },
      },
      NoChargeExamItem: {
        render: {
          Fragment: {
            td: {
              j001: "円",
              j002: "無し",
              Button: {
                j001: "請求なし",
              },
            },
          },
        },
      },
      DeliveryProcessDialog: {
        ConfirmTitle: {
          [DELIVERY_OPTION.yamato]: `伝票作成 -宅配便コンパクト-`,
          [DELIVERY_OPTION.csvPost]: `CSV作成 -クリックポスト-`,
          [DELIVERY_OPTION.other]: `配送料の入力`,
        },
        ResultTitle: {
          [DELIVERY_OPTION.yamato]: `伝票内容確認 -宅配便コンパクト-`,
          [DELIVERY_OPTION.csvPost]: `CSV内容確認 -クリックポスト-`,
          [DELIVERY_OPTION.other]: `配送料の確認`,
        },
        ResetTitle: {
          [DELIVERY_OPTION.yamato]: `配送方法の変更 -宅配便コンパクト-`,
          [DELIVERY_OPTION.csvPost]: `配送方法の変更 -クリックポスト-`,
          [DELIVERY_OPTION.other]: `配送方法の変更`,
          [DELIVERY_OPTION.noDelivery]: `配送方法の変更`,
        },
        ResetQuestion: "別な配送方法への変更を可能にしてよろしいですか？",
        ResetQuestionNotice: "※既に発送したものを引き戻すことはできません。",
        DialogActions: {
          cancel: "キャンセル",
          confirm: "確認",
          back: "＜戻る",
          reset: "変更",
          complete: {
            [DELIVERY_OPTION.yamato]: "伝票作成",
            [DELIVERY_OPTION.csvPost]: "ダウンロード",
            [DELIVERY_OPTION.other]: "確定",
          },
          close: "閉じる",
        },
      },
      PaymentConfirmDialog: {
        DialogTitle: "料金確認",
        BillingEnterpriseDialogTitle: "実績確認",
        buttonOk: "閉じる",
        DialogContent: {
          table: {
            thBirthDay: "生年月日",
            thAge: "年齢",
            thGender: "性別",
            thPhonenumber: "電話番号",
          },
          containerBorder: {
            medicalTotalFee: "合計金額",
            medicalDetailFee: "内訳",
            medicalFee: {
              normalMenu: "診療費/相談料",
              medicationInstruction: "調剤費/薬学管理料",
            },
            deliveryFee: "配送料",
            medicalFeeDescription: {
              normalMenu: "薬代・処方箋料を含みます。",
              medicationInstruction: "薬代を含みます。",
            },
            deliveryFeeYamatoDescription:
              "梱包資材料を含む金額です。\n表示金額のうち、梱包資材料のみ(株)カラダメディカから医療機関様にお返しします。",
            deliveryFeeDescription:
              "梱包資材料を含む金額です。\n表示金額を(株)カラダメディカから医療機関様にお返しします。",
            deliveryArrangementFee: "配送手配料",
            deliveryArrangementFeeDescription:
              "配送手配に要した費用として(株)カラダメディカが患者様に請求する金額です。",
            medicalFeeUnit: "円",
            memo: "メモ",
            drugName: "処方薬",
            drugNameEWP: "処方薬名",
            drugQuantity: "処方薬の\n数量",
            billingCategory: "診療種別",
          },
        },
        genderMale: "男性",
        DialogActions: {
          confirmButton: "閉じる",
        },
        DialogConfirm: {
          title: "モーダルを閉じてよろしいですか？",
          content: "※入力した内容は保存されません。",
          buttonOk: "確定",
          buttonCancel: "キャンセル",
        },
      },
      CSVPostDeliveryDialog: {
        DialogTitle: {
          create: "CSV作成 -クリックポスト-",
          confirm: "CSV内容確認 -クリックポスト-",
          reset: "配送方法の変更 -クリックポスト-",
          result: "配送処理 -クリックポスト-",
        },
        note: {
          symbol: "※",
          title: "「クリックポスト」は日本郵便が提供する配送方法です。",
          content: {
            j001: "ここでは、あて名ラベルを作成するためのCSVデータを作成します。CSVデータ作成後は、「クリックポスト」のサイトにデータをアップロードし、",
            j002: "あて名ラベルを作成してください。",
            j003: "この配送方法は集荷はありませんので、医療機関側でポストに投函してください。",
          },
        },
        information: {
          undertaker: "担当者",
          treatmentDay: "診療実施日",
          address: "お届け先 住所",
          addressSymbol: "〒",
          patientName: "お届け先 氏名",
          deliveryFee: "配送料",
          deliveryFeeDescription:
            "※梱包資材料を含む金額です。\n※表示金額を(株)カラダメディカから医療機関様にお返しします。",
          deliveryArrangementFee: "配送手配料",
          deliveryArrangementFeeDescription:
            "※配送手配に要した費用として(株)カラダメディカが患者様に請求する金額です。",
          contentSelect: "内容",
          offline: "なし（来院予約）",
        },
        buttonOk: { j001: "確認", j003: "ダウンロード" },
        buttonCancel: "キャンセル",
        buttonClose: "閉じる",
        backButton: "＜戻る",
        confirmQuestion: "こちらの内容で確定してよろしいですか？",
        patientInfo: {
          thBirthDay: "生年月日",
          thAge: "年齢",
          thGender: "性別",
          thPhonenumber: "電話番号",
          genderMale: "男性",
        },
        DialogConfirm: {
          title: "このまま終了してよろしいですか？",
          content: "※入力した内容は保存されません。",
        },
        CSVHeader: {
          j001: "お届け先郵便番号",
          j002: "お届け先氏名",
          j003: "お届け先敬称",
          j004: "お届け先住所1行目",
          j005: "お届け先住所2行目",
          j006: "お届け先住所3行目",
          j007: "お届け先住所4行目",
          j008: "内容品",
        },
        CSVContent: {
          j001: "様",
        },
        Format: {
          j001: "yyyy/MM/dd",
        },
      },
      NoDeliveryDialog: {
        title: "配送方法変更",
        note: {
          content: "配送方法の変更を可能にしてよろしいですか？",
        },
      },
      MedicalFeeDialog: {
        DialogTitle: "料金の入力",
        DialogBillingEnterpriseTitle: "実績の入力",
        selfFundedNote:
          "この診療は実績を確定すると修正ができません。内容に間違いがないかご確認ください。",
        QuestionConfirm: "こちらの内容で確定してよろしいですか？",
        MedicalFeeNoteConfirm: {
          normalMenu:
            "※患者様に請求する、診療/相談・処方に関する費用の合計です。",
          medicationInstruction:
            "※患者様に請求する調剤/薬学管理・薬に関する費用の合計です。",
        },
        MedicalFeeNoteInput: {
          normalMenu:
            "※患者様に請求する、診療/相談・処方に関する費用の合計を入力してください。",
          medicationInstruction:
            "※患者様に請求する調剤/薬学管理・薬に関する費用の合計を入力してください。",
        },
        MemoNote: "最大500字 まで",
        PlaceHolderDrugsName: "処方した薬剤名を30文字以内で入力してください。",
        PlaceHolderDrugsQuantity:
          "処方した薬剤の数量を20文字以内で入力してください。",
        PlaceHolderDrugsQuantityEWP: "選択してください",
        PlaceHolderMemo:
          "500文字以内で入力してください。\n入力した内容は患者様へは共有されません。",
        BillingEnterprisePlaceHolderMemo:
          "別途営業担当者から依頼した内容を500文字以内で入力してください。",
        ButtonOk: "確定",
      },
    },
    Footer: {
      render: {
        footer: {
          Typography: {
            j001: "© 株式会社カラダメディカ",
          },
        },
      },
    },
    Header: {
      render: {
        AppBar: {
          Toolbar: {
            div: {
              Link: {
                img: {
                  j001: `${service_name}`,
                },
              },
              AlertContent: "未承認の予約があります",
              Tooltip: {
                home: ["ホーム"],
                unapproved: ["未承認", "予約一覧"],
                calendar: ["スケジュール", "管理"],
                appointment: {
                  hospital: ["診療一覧"],
                  pharmacy: ["服薬指導", "一覧"],
                },
                payment: ["会計処理"],
                other: ["その他"],
                logout: "ログアウト",
                documents: "資料ダウンロード",
                contact: "お問い合わせ",
                settings: "通知設定",
              },
              Dialog: {
                DialogTitle: {
                  j001: "ログアウトしますか？",
                },
                DialogActions: {
                  Button: {
                    j001: "キャンセル",
                    j002: "ログアウト",
                  },
                },
              },
            },
          },
        },
      },
    },
    MessageBox: {
      render: {
        Snackbar: {
          Button: {
            j001: "閉じる",
          },
        },
      },
    },
    RecommendTerminal: {
      j001: "動作環境",
      j002: "パソコンの場合",
      j003: "Windows",
      j004: "・OS",
      j005: "　Windows 10",
      j006: "・ブラウザ",
      j007: "　Google Chrome 最新版",
      j008: "Mac",
      j009: "　macOS 10.14 Mojave 以降",
      j010: "タブレットの場合",
      j011: "iOS",
      j012: "　iOS12.2/iPad OS13.1以降",
      j013: "　Safari 最新版",
      j014: "※ご利用の端末にインカメラとマイクが内蔵していない場合は、別途ご用意ください。",
      j015: "Microsoft Edge 最新版",
    },
    AnnouncementPanel: {
      Root: {
        Title: "CARADA オンライン診療とは",
        Description:
          "予約から薬・処方箋の配送まで、 \nオンライン診療・服薬指導に必要な機能が充実したシステムです。",
        Footer: {
          Div1: "もっと詳しく知りたい方はこちら",
          Button1: "医療施設の方",
          Button2: "薬局の方",
        },
      },
    },
    Dialog: {
      PatientInfo: {
        EnterpriseName: "法人名：",
        Table: {
          Thead: {
            Tr: {
              Th: {
                j001: "生年月日",
                j002: "年齢",
                j003: "性別",
                j004: "電話番号",
              },
            },
          },
        },
      },
    },
  },
  Pages: {
    Appointment: {
      Appointment: {
        title: {
          hospital: "診療一覧",
          pharmacy: "服薬指導一覧",
        },
      },
      Unapproved: {
        render: {
          div: {
            j001: "未承認予約一覧",
            h1: {
              j001: "未承認予約一覧",
            },
          },
        },
      },
      Calendar: {
        textFormat: {
          dayRangeHeaderFormat: {
            j001: "月",
            j002: "日",
          },
          dayHeaderFormat: {
            j001: "月",
            j002: "日",
          },
          monthHeaderFormat: {
            j001: "年",
            j002: "月",
          },
        },
        messages: {
          j001: "週間",
          j002: "月間",
          j003: "今日",
          j004: "残りの",
          j005: "件を表示",
          j006: "患者指定予約",
          j007: "予約受付枠",
          week: {
            previous: "前週",
            next: "翌週",
          },
          month: {
            previous: "前月",
            next: "翌月",
          },
        },
        getEvents: {
          j001: "予約受付可",
        },
        render: {
          div: {
            j001: "予約カレンダー",
            h1: {
              j001: "スケジュール管理",
            },
            div: {
              Legend: {
                j001: "予約なし",
                j002: "予約あり",
              },
            },
            nativeSelectBox: {
              j001: "担当者",
            },
          },
        },
      },
    },
    Contact: {
      render: {
        Card: {
          j001: "問い合わせ",
          CardContent: {
            Typography: {
              j001: "お問い合わせ",
              j002: "利用方法などのご不明点、不具合などでお困りのことがございましたら、ページ最下部のボタンよりお問い合わせください。不具合の場合は、初めに以下の動作環境をご確認ください。",
              j003: "下記ボタンよりお問い合わせください。",
              j004: "このボタンをクリックするとメーラーが立ち上がります。",
              j005: "【受付時間】",
              j006: "　24時間受け付けております。",
              j007: "【ご回答時間】",
              j008: "　10:00～18:30（土日祝日・12月28日～1月3日を除く）",
              j009: "　全ての医療機関からのお問合せを到着順に順次回答しております。",
              j010: "　ご回答までに数日かかる場合がございますので、予めご了承ください。",
              j011: "【お問い合わせ時のお願い】",
              j012: "　不具合の場合は、以下の情報を詳細に記載ください。",
              j013: "　・発生日時",
              j014: "　・発生しているアカウント（メールアドレス）",
              j015: "　・患者名",
              j016: "　・どんな操作をしたのか",
              j017: "　・どんな問題が起きているのか",
            },
          },
          CardActions: {
            Button: {
              j001: "メールで問い合わせ",
            },
          },
        },
      },
      generateMessageResource: {
        j001: `${service_name}`,
        j002: "医療機関ID：",
        j003: "医療従事者ID：",
        j004: "メールの件名、及び、上記の情報は変更しないでください。",
        j005: "お問い合わせ内容を以下にご記入ください。",
        j006: "○発生日時",
        j007: "○ログインしたメールアドレス(本人ではない場合)",
        j008: "○患者名(患者に関連する場合)",
        j009: "○どんな操作をしたのか",
        j010: "○どんな問題が起きているのか",
      },
    },
    NotFound: {
      render: {
        section: {
          Typography: {
            j001: "404",
            j002: "お探しのページは見つかりませんでした。",
          },
        },
      },
    },
    SystemError: {
      render: {
        section: {
          Typography: {
            j001: "アクセスできません",
            j002: "申し訳ございませんが、ご利用ブラウザのキャッシュにより、",
            j003: "ページを表示することができません。",
            j004: "ブラウザのキャッシュを削除のうえ、再度アクセスください。",
            j005: "＜キャッシュの削除方法＞",
            j006: "ブラウザの「設定」から削除してください。",
          },
        },
      },
    },
    Setting: {
      render: {
        Card: {
          j001: "通知設定",
          CardContent: {
            Typography: {
              j001: "お知らせ通知設定",
              j002: "こちらのお知らせ通知設定をONにしていただくと、",
              j003: `「${service_name}」からの以下の通知を受け取ることができます。`,
              j004: "通知をONにする",
            },
            Paper: {
              Typography: {
                j001: "① 患者側で予約をキャンセルした場合",
                j002: "② ご設定の予約受付枠に予約が入った場合",
                j003: "③ 予約開始時間の３時間前になった場合",
              },
            },
            div: {
              Typography: {
                j001: "通知の設定時と別のブラウザ・PCでご利用の場合、通知が届かない場合がございます。",
                j002: "現在ご利用のブラウザ・PCで通知を受け取りたい場合は、一度通知をOFFにしたうえで、",
                j003: "再度通知をONに設定ください。",
              },
            },
          },
        },
      },
    },
    Home: {
      render: {
        Header: {
          title: "お知らせ",
        },
        Grid: {
          Grid: {
            Typography: {
              j001: "ログイン中のメールアドレス",
            },
            Button: {
              j001: "スケジュール管理",
              j002: "診療一覧",
              j003: "お問い合わせ",
            },
          },
        },
      },
    },
    Medical: {
      Medical: {
        connect: {
          j001: "予期せぬエラーが発生しました。少し時間を空けてから、再度お試しください。",
        },
        constructor: {
          j001: "エラー",
        },
        componentDidMount: {
          j001: "内部エラーが発生しました。",
        },
        disconnect: {
          j001: "エラーが発生したため通話を終了できませんでした。再度「通話終了」ボタンを押してください。",
        },
        render: {
          div: {
            div: {
              Card: {
                CardContent: {
                  Typography: {
                    j001: "医療機関",
                    j002: "患者",
                    j003: "通話可",
                    j004: "通話不可",
                    j005: "ビデオ通話時間外です。",
                  },
                },
              },
            },
            Dialog: {
              DialogContent: {
                j001: "終了してよろしいでしょうか？",
              },
              DialogActions: {
                Button: {
                  j001: "キャンセル",
                  j002: "終了",
                },
              },
            },
            Prompt: {
              j001: "終了してよろしいでしょうか？",
            },
          },
        },
      },
      StartButton: {
        getStartLabel: {
          j001: "ビデオ通話開始",
        },
        getResumeLabel: {
          j001: "ビデオ通話再開",
        },
        endVideo: {
          j001: "ビデオ通話終了",
        },
      },
      MedicalSideArea: {
        insuranceCard: {
          exist: "登録済",
          empty: "未登録",
        },
        render: {
          div: {
            Card: {
              CardContent: {
                Typography: {
                  j001: "診療日時",
                  j002: "患者名",
                  j003: "保険証",
                  j004: "表示する",
                  j005: "未登録",
                  j006: "-",
                },
              },
            },
          },
        },
        EnterpriseName: "法人名",
      },
      MainAreaVideoClose: {
        title: {
          j001: "医療機関",
          j002: "患者様",
        },
      },
      CautionNG: {
        text: {
          j001: "ビデオ通話を",
          j002: "開始できません",
        },
      },
      CautionOK: {
        text: {
          j001: "ビデオ通話を",
          j002: "開始できます",
        },
      },
      PatientContact: {
        text: {
          j001: "様",
        },
      },
      DeviceErrorDetail: {
        NotAllowedError: {
          j001: "カメラ・マイクの使用が許可されていません。患者様へ、カメラとマイクの使用を「許可」に変更するようご連絡ください。",
        },
        NotFoundError: {
          j001: "患者様へ、ご利用の端末のカメラ・マイクの設定を確認するようご連絡ください。",
        },
        NotReadableError: {
          j001: "カメラまたはマイクは他のアプリで使用されています。​患者様へ、カメラ・マイクを使用した他のアプリを閉じるようご連絡ください。",
        },
      },
      PatientSiteStatus: {
        NotOpenVideoStandbyPage: {
          j001: "患者様はビデオ通話画面にアクセスしていないようです。",
          j002: "患者様へ、ビデオ通話画面にアクセスするようご連絡ください。",
        },
        NotLoginError: {
          j001: "患者様はサイトにアクセスしていないようです。",
          j002: "患者様へ、サイトにログインし、ビデオ通話待機画面にアクセスするようご連絡ください。",
        },
      },
      InterviewArea: {
        title: "問診内容",
        Typography: {
          j001: "診療日",
          j002: "相談内容",
          j003: "企業名",
          j004: "問診の回答はありません。",
          j005: "yyyy年M月d日",
          j006: "-",
          j007: "患者情報",
        },
      },
      medicineDelivery: {
        title: "処方せん送付",
        Typography: {
          j001: "処方せんに関する患者様のご希望を表示しています。\nご希望から変更される場合は、患者様とお話のうえ合意ください。",
          j002: "お薬の説明と受取方法",
          j003: "薬局名：",
          j004: "郵便番号：",
          j005: "住所：",
          j006: "TEL：",
          j007: "FAX：",
          j008: "お名前：",
          j009: "処方せんの送付希望先の回答はありません。",
          j010: "未選択",
          j011: "オンラインでお薬の説明を聞いて、登録した住所にお薬を配送してもらう​",
          j012: "登録した住所に処方せんを配送してもらい、自分で薬局にお薬を取りに行く",
          j013: "希望の処方せん送付先",
          j014: "現在こちらの薬局は、処方せんを送付できません。",
        },
      },
      PatientCallingWarningDialog: {
        render: {
          Dialog: {
            DialogContent: {
              div: {
                div: {
                  j001: "前の通話が終了していないため、通話を開始できません。少し時間をあけて、再度お試しください。",
                },
              },
            },
            DialogActions: {
              div: {
                Button: {
                  j001: "OK",
                },
              },
            },
          },
        },
      },
      AppointmentStatusChangedWarningDialog: {
        render: {
          Dialog: {
            DialogContent: {
              div: {
                j001: "この診療は決済が確定しているため、通話を開始できません。",
                j002: "この服薬指導は決済が確定しているため、通話を開始できません。",
              },
            },
            DialogActions: {
              Button: {
                j001: "OK",
              },
            },
          },
        },
      },
      NetworkQualityWarning: {
        Doctor: {
          j001: "医療機関側のインターネット接続が不安定です。有線LAN・Wi-Fi環境に切り替えるか、電波の良い場所に移動してください。",
        },
        Patient: {
          j001: "患者様側のインターネット接続が不安定です。",
          j002: "患者様が通話画面から離れました。この状態が続く場合は、一度通話を終了してから通話を再開してください。",
          j003: "患者様がサイトにアクセスしていないようです。そのままお待ちいただくか、通話を終了し、患者様へサイトにアクセスするようご連絡ください。",
        },
      },
      AudioLowWarning: {
        Doctor: {
          j001: "マイクの入力音量が小さい、または、ミュートになっています。",
        },
      },
      DoctorDeviceStatusDialog: {
        title: "ビデオ通話を開始できません",
        NotFoundError: {
          j001: "カメラまたはマイクを起動することができませんでした。",
          j002: "ご利用の端末のカメラ・マイクの設定を確認してください。",
        },
        NotAllowError: {
          WinChrome: {
            j001: "カメラ・マイクの使用が許可されていません。",
            j002: "画面左上の「鍵マーク」を押し、カメラとマイクを「許可」に設定したうえで、このページを",
            link: "再読み込み",
            j003: "してください。",
          },
          MacChrome: {
            j001: "カメラ・マイクの使用が許可されていません。",
            j002: "画面左上の「鍵マーク」を押し、カメラとマイクを「許可」に設定したうえで、このページを",
            link: "再読み込み",
            j003: "してください。",
          },
          WinEdge: {
            j001: "カメラ・マイクの使用が許可されていません。",
            j002: "画面左上の「鍵マーク」を押し、カメラとマイクを「許可」に設定したうえで、このページを",
            link: "再読み込み",
            j003: "してください。",
          },
          IpadSafari: {
            j001: "カメラ・マイクの使用が許可されていません。",
            j002: "画面左上の「ぁあ」を押し、「Webサイトの設定」からカメラとマイクを「許可」に設定したうえで、このページを",
            link: "再読み込み",
            j003: "してください。",
          },
        },
        NotReadableError: {
          j001: "カメラまたはマイクが他のアプリで使用されています。",
          j002: "​カメラ・マイクを使用した他のアプリを閉じてください。​",
          j003: "該当のアプリを閉じてからしばらくたっても画面が切り替わらない場合は、このページを​",
          link: "再読み込み",
          j004: "してください。",
        },
        OtherError: {
          j001: "カメラまたはマイクを起動することができませんでした。",
          j002: "ご利用の端末のカメラ・マイクの設定を確認してください。",
        },
      },
    },
    Payment: {
      Payment: {
        render: {
          div: {
            j001: "請求",
            h1: {
              j001: "会計処理",
            },
          },
        },
      },
    },
    UnsupportedDevice: {
      render: {
        Card: {
          CardContent: {
            Typography: {
              j001: "動作環境に関するご不明点がありましたら、以下のボタンよりお問い合わせください",
              j002: "このボタンをクリックするとメーラーが立ち上がります。",
              j003: "【受付時間】",
              j004: "　24時間受け付けております。",
              j005: "【ご回答時間】",
              j006: "　10:00～18:30（土日祝日・12月28日～1月3日を除く）",
              j007: "　全ての医療機関からのお問合せを到着順に順次回答しております。",
              j008: "　ご回答までに数日かかる場合がございますので、予めご了承ください。",
              j009: "【お問い合わせ時のお願い】",
              j010: "　以下の情報をメールに記載ください",
              j011: "　・医療機関名",
              j012: "　・お名前",
              j013: "　・ログインID（ご登録のメールアドレス）",
              j014: "　・お問い合わせ内容",
            },
            Heading: {
              j001: "動作環境",
              j002: "お問い合わせ",
            },
            Warning: {
              j001: "お使いの端末は動作環境外です。",
              j002: "申し訳ございませんが、お使いの端末は、OSバージョンまたはブラウザが動作環境外のため、当システムをご利用いただくことができません。以下の動作環境を満たした端末で再度アクセスしてください。",
            },
          },
          CardActions: {
            Button: {
              j001: "メールでお問い合わせ",
            },
          },
        },
      },
      generateMessageResource: {
        j001: `${service_name}`,
        j002: "メールの件名、及び、上記の情報は変更しないでください。",
        j003: "お問い合わせ内容を以下にご記入ください。",
        j004: "○医療機関名",
        j005: "○お名前",
        j006: "○ログインID（ご登録のメールアドレス）",
        j007: "○問い合わせ内容",
      },
    },
    Patient: {
      InfoBar: {
        j001: "生年月日",
        j002: "年齢",
        j003: "性別",
        j004: "yyyy/MM/dd",
        Button: {
          j001: "基本情報",
        },
      },
      InsuranceCardArea: {
        j001: "保険証",
        j002: "患者様より保険証画像がアップロードされておりません。 保険証の確認をお急ぎの場合は、電話にて直接ご連絡をお願いいたします。",
      },
      ConsentStatusArea: {
        j001: "オンライン資格確認",
        j002: "同意可否：",
        j003: "まで有効",
        consentStatus: {
          unregistered: "未登録",
          registering: "登録中",
          registered: "登録済",
        },
      },
      MedicalHistory: {
        j003: "診療履歴はありません。",
        j004: "服薬指導履歴はありません。",
        Title: {
          j001: "診療履歴",
          j002: "服薬指導履歴",
        },
        Status: {
          rejected: {
            j001: "予約否認",
          },
          unapproved: {
            j001: "予約未承認",
          },
          beforeExam: {
            j001: "ビデオ通話前",
            j002: "診療前",
          },
          cancel: {
            j001: "キャンセル",
          },
          payment: {
            j001: "会計確定済",
            j002: "会計未処理",
          },
          deliveryError: {
            j001: "配送エラー",
          },
          settlementError: {
            j001: "決済エラー",
          },
          examCompleted: {
            j001: "診療完了",
          },
        },
      },
      MedicalDetailArea: {
        j001: "問診内容",
        j005: "　",
        j006: "問診票印刷",
        j007: "操作履歴",
        j008: "保険適用",
        j009: "保険適用外",
        j010: "日時",
        j011: "アカウント名",
        j012: "操作内容",
        j013: "操作の履歴はありません。",
      },
      AppointmentInfo: {
        j001: "保険適用",
        j002: "保険適用外",
        j003: "担当者：",
        j004: "なし（来院予約）",
      },
      InterviewSheetAnswerPrint: {
        j001: "、",
        j002: "/",
        j003: "-",
      },
      PrintInterviewSheetAnswerHeader: {
        j001: "ルナルナ/CARADA オンライン診療",
        j002: "問診票",
        j003: "予約日時：",
        j004: "氏名",
        j005: "生年月日",
        j006: "年齢",
        j007: "性別",
        j008: "電話番号",
        j009: "yyyy/MM/dd",
      },
      PrintInterviewSheetAnswerRow: {
        j001: ".",
      },
    },
  },
  Utils: {
    Enum: {
      AppointmentStatus: {
        rejected: {
          j001: "予約否認",
        },
        canceledBeforeApproving: {
          j001: "承認前キャンセル",
          j002: "患者側",
        },
        unapproved: {
          j001: "未承認",
        },
        beforeExam: {
          j001: "診療前",
          j002: "ビデオ通話前",
        },
        examCompleted: {
          j001: "診療完了",
          j002: "ビデオ通話終了",
        },
        paymentCompleted: {
          j001: "会計完了",
        },
        canceledDoctor: {
          j001: "キャンセル",
          j002: "医療機関側",
        },
        canceledPatientPreviousDay: {
          j001: "キャンセル",
          j002: "患者側:前日まで",
        },
        canceledPatientFromToday: {
          j001: "キャンセル",
          j002: "患者側:当日以降",
        },
      },
      ExamTypes: {
        j001: "対面診療",
        j002: "オンライン相談",
        j003: "オンライン診療",
        j004: "産業医面談",
      },
    },
    // Errors: {
    //   j001: "セッションがタイムアウトしました。再設定からやり直してください。",
    //   j002: "※大文字と小文字の両方を含めて8文字以上で入力してください。",
    // },
    PrefectureCode: {
      j001: "北海道",
      j002: "青森県",
      j003: "岩手県",
      j004: "宮城県",
      j005: "秋田県",
      j006: "山形県",
      j007: "福島県",
      j008: "茨城県",
      j009: "栃木県",
      j010: "群馬県",
      j011: "埼玉県",
      j012: "千葉県",
      j013: "東京都",
      j014: "神奈川県",
      j015: "新潟県",
      j016: "富山県",
      j017: "石川県",
      j018: "福井県",
      j019: "山梨県",
      j020: "長野県",
      j021: "岐阜県",
      j022: "静岡県",
      j023: "愛知県",
      j024: "三重県",
      j025: "滋賀県",
      j026: "京都府",
      j027: "大阪府",
      j028: "兵庫県",
      j029: "奈良県",
      j030: "和歌山県",
      j031: "鳥取県",
      j032: "島根県",
      j033: "岡山県",
      j034: "広島県",
      j035: "山口県",
      j036: "徳島県",
      j037: "香川県",
      j038: "愛媛県",
      j039: "高知県",
      j040: "福岡県",
      j041: "佐賀県",
      j042: "長崎県",
      j043: "熊本県",
      j044: "大分県",
      j045: "宮崎県",
      j046: "鹿児島県",
      j047: "沖縄県",
    },
    DisplayLogic: {
      getAgeText: {
        j001: "歳",
      },
      getPhoneText: {
        j001: "電話番号",
      },
      getCombinedAddress: {
        j001: "〒",
      },
    },
    GoogleTagManager: {
      logCustomDimensions: {
        j001: "ログイン",
        j002: "未ログイン",
        j003: "会員",
        j004: "非会員",
        j005: "非課金",
      },
    },
    DateUtil: {
      showCustomWeek: {
        j001: "毎日",
        j002: "（毎週月曜〜金曜）",
        j003: "月曜日",
        j004: "火曜日",
        j005: "水曜日",
        j006: "木曜日",
        j007: "金曜日",
        j008: "土曜日",
        j009: "日曜日",
        j010: "（毎週：",
        j011: "）",
        j012: "、",
      },
      dateFormat: {
        day: "yyyy/MM/dd(iii)",
        time: "HH:mm",
      },
    },
    UnsupportedDeviceAlert: {
      j001: "動作環境について",
      j002: "お使いの端末のブラウザのバージョンは、当サービスの動作環境外のため、ビデオ通話が正常に行えない可能性がございます。OSまたはブラウザのバージョンをご確認のうえ、動作環境内でご利用ください。",
      j003: "動作環境を確認する",
    },
    UnsupportedDeviceAlertContent: {
      j001: "お使いの端末は当サービスの動作環境外のため、ビデオ通話が正常に行えない可能性がございます。OSまたはブラウザのバージョンをご確認のうえ、動作環境内でご利用ください。",
      j002: "yyyy年MM月dd日",
      j003: "からご利用いただけなくなります。",
    },
    MediaDevicePermissionAlert: {
      j001: "マイク／カメラの設定について",
      j002: "お使いの端末カメラとマイクが有効ではありません。端末またはブラウザの設定を確認してください。",
    },
    ConnectingAlertMessage: {
      j001: "注意",
      j002: "別のタブでビデオ通話が継続中です。ビデオ通話画面を開いているタブに戻り、ビデオ通話を終了してください。",
      j003: "※「ビデオ通話終了」ボタンを押してもこちらが表示されている場合は、5分ほど時間を空けてから、この画面を再度読み込んでください。",
    },
  },
  importantNotice: {
    j001: "重要なお知らせ",
    j002: "システムメンテナンス予定日時に予約があります。",
    j003: "該当日の診療一覧へ＞",
    j004: "システムメンテナンス予定日時に未承認予約があります。",
    j005: "未承認予約一覧へ＞",
  },
};
