import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { format, parseISO, endOfMonth } from "date-fns";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import querystring from "querystring";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Tooltip,
} from "@material-ui/core/";
import LoadingScreen from "../../molecules/others/LoadingScreen";
import insuranceCard from "../../../images/icon/icon-insurance-card-24px.svg";
import VideocamIcon from "@material-ui/icons/Videocam";
import IconInsurance from "../../atoms/icons/IconInsurance";
import IconNotInsurance from "../../atoms/icons/IconNotInsurance";
import { getDayTextJaFormatShort } from "../../../Utils/DisplayLogic";
import { FileUploadUtility } from "../../../Utils/FileUpload";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import DescriptionRoundedIcon from "@material-ui/icons/DescriptionRounded";
import { getBillingCategorySettings } from "../../../Utils/BillingCategorySettings";
import {
  PAYMENT_METHOD,
  TREATMENT_COMPLETED_SELECTED_OPTION,
  MEDICAL_METHOD,
  MEDICAL_MENU,
  PRESCRIPTION_DRUG_INPUT_METHOD,
  CONSENT_STATUS,
} from "../../../Utils/const";
import {
  client,
  GenerateUploadPresignedUrl,
  InsertAttachment,
  GetAppointment,
  GetAppointmentRequiredInputFields,
  GenerateGetInsuranceCardPresignedUrl,
  DeleteAttachment,
  UpdateMedicalTreatment,
  GetDoctor,
  GetMultipleConsentStatus,
  AttachmentPresignedUrlForGetObject,
} from "../../../graphql";

import axios from "axios";
import FileuploadConfirmDialog from "./fileupload/FileuploadConfirmDialog";
import FileuploadDialog from "./fileupload/FileuploadDialog";
import DialogInsuranceCardConfirm from "../../molecules/dialogs/DialogInsuranceCardConfirm";
import DialogInsuranceCardCaution from "../../molecules/dialogs/DialogInsuranceCardCaution";
import FileuploadCompleteDialog from "./fileupload/FileuploadCompleteDialog";
import ChangeStatusDialogInput from "./changeStatus/ChangeStatusDialogInput";
import ChangeStatusDialogConfirm from "./changeStatus/ChangeStatusDialogConfirm";
import DialogConfirm from "../../molecules/dialogs/DialogConfirm";
import DialogError from "../../molecules/dialogs/DialogError";
import DialogAccessFileError from "../../molecules/dialogs/DialogFileAccessError";
import DialogConsentRegistration from "../../molecules/dialogs/DialogConsentRegistration";
import AppointmentStatus from "./AppointmentStatus";

import { japaneseList } from "../../../Resources/japaneseList";
import { patientDetail } from "../../../lib/window/PatientDetail";

const fns = require("date-fns");
const MAX_FILE_NAME_LENGTH = 20;
const MAX_FILE_SIZE = 2097152;
const VALID_EXTENSIONS = [
  "application/pdf",
  "image/png",
  "image/jpg",
  "image/jpeg",
];
const NUMBER_MONTHS_ADD = 1;

const AppointmentListResource =
  japaneseList.Components.Appointment.AppointmentList;
const ApprovedErrorResources =
  japaneseList.Components.Appointment.AppointmentList.ErrorResources;

const AppointmentStatusesHideActionButtons = [
  "canceledDoctor",
  "canceledPatientFromToday",
  "canceledPatientPreviousDay",
  "rejected",
  "canceledBeforeApproving",
];
const _ = require("lodash");
const defaultFileList = [];
const file = {
  file: {},
  niceName: "",
  fileData: "",
};

for (let i = 0; i < 5; i++) {
  defaultFileList.push(file);
}

const styles = (theme) => ({
  root: {
    margin: "auto",
    overflowX: "auto",
    fontSize: "12px",
    height: "calc(100vh - 262px)",
  },
  buttonMedial: {
    fontSize: "14px",
    color: theme.button.ok.color,
    backgroundColor: theme.button.ok.backgroundColor,
    minWidth: "38px",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    fontWeight: "bold",
    boxShadow: "0px 1px 0px " + theme.button.ok.shadowColor,
  },
  buttonCancel: {
    marginRight: "10px",
    backgroundColor: "#999",
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
    boxShadow: "0px 1px 0px " + theme.button.cancel.shadowColor,
  },
  buttonIconOnly: {
    minWidth: "0px",
    minHeight: "0px",
    maxWidth: "35px !important",
    maxHeight: "35px !important",
    padding: "5px",
  },
  buttonIconOnlyInsurance: {
    minWidth: "0px",
    minHeight: "0px",
    maxWidth: "35px !important",
    maxHeight: "35px !important",
    padding: "2px",
  },
  tbl: {
    fontSize: "14px",
    width: "100%",
    tableLayout: "auto",
    borderCollapse: "collapse",
    "& tbody tr:nth-child(odd)": {
      backgroundColor: "#fff",
      height: "87px",
    },
    "& tbody tr:nth-child(even)": {
      backgroundColor: "#FAFAFA",
      height: "87px",
    },
    borderRight: "solid 1px #CCCCCC",
    borderBottom: "1px solid #CCCCCC",
  },
  head: {
    backgroundColor: "#999999",
    color: "#FFFFFF",
    textAlign: "center",
    whiteSpace: "nowrap",
    paddingRight: 0,
    fontSize: "12px",
    fontWeight: "bold",
    "& tr": {
      height: "40px",
    },
  },
  body: {
    textAlign: "center",
  },
  td: {
    borderLeft: "1px solid #CCCCCC",
  },
  row: {
    marginBottom: "50px",
  },
  doctor: {
    minWidth: "150px",
    width: "150px",
  },
  noHead: {
    height: "22px",
  },
  doctorContent: {
    textAlign: "left",
    paddingLeft: "10px",
  },
  date: {
    minWidth: "160px",
    width: "160px",
  },
  name: {
    minWidth: "160px",
    width: "160px",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    padding: "10px",
  },
  insuranceCard: {
    minWidth: "54px",
    textAlign: "center",
    "& > button:first-child": {
      margin: "auto",
    },
  },
  consentRegistrations: {
    minWidth: "84px",
    textAlign: "center",
  },
  op: {
    minWidth: "54px",
    textAlign: "center",
  },
  menu: {
    minWidth: "200px",
    padding: " 10px",
  },
  status: {
    minWidth: "180px",
    whiteSpace: "pre-wrap",
    padding: "10px",
  },
  statusContent: {
    display: "flex",
    justifyContent: "space-between",
  },
  statusContentLeft: {
    lineHeight: "32px",
    textAlign: "left",
  },
  upload: {
    minWidth: "74px",
    textAlign: "center",
  },
  cancel: {
    minWidth: "54px",
    textAlign: "center",
    "& > button:first-child": {
      marginLeft: "10px",
    },
  },
  rejected: {
    color: `${theme.color.tableHead}`,
  },
  left: {
    float: "left",
    textAlign: "left",
    margin: "auto 0",
    width: "50%",
    paddingLeft: "10px",
  },
  right: {
    float: "right",
    textAlign: "center",
    margin: "auto 0",
  },
  iconInsurance: {
    fontSize: "10px",
    border: `1px solid ${theme.color.primary1}`,
  },
  iconNotInsurance: {
    border: `1px solid ${theme.color.notInsurance}`,
    fontSize: "10px",
  },
  divName: {
    textAlign: "left",
  },
  divNameKana: {
    fontSize: "10px",
    textAlign: "left",
  },
  divMenu: {
    textAlign: "left",
    "& span": {
      whiteSpace: "nowrap",
    },
  },
  divTime: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  divTimeContent: {
    textAlign: "left",
    verticalAlign: "middle",
    width: "calc(100% - 48px)",
  },
  divNoData: {
    fontSize: "14px",
    //テーブル要素と重なっているため、padding-topを50pxに設定。テーブルからの余白は10px
    paddingTop: "50px",
    paddingLeft: "5px",
  },
  stick: {
    position: "sticky",
    zIndex: "1",
  },
  spanFileNameUpload: {
    paddingTop: "5px",
  },
  clickArea: {
    display: "inline-block",
    cursor: "pointer",
    float: "left",
    color: "#2563c0",
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "none",
    },
  },
  clickAreaCenter: {
    display: "inline-block",
    cursor: "pointer",
    float: "none",
    color: "#2563c0",
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "none",
    },
  },
  tip: {
    width: "301px",
    height: "64px",
    fontSize: "12px",
    marginLeft: "-260px",
    marginTop: "5px",
    whiteSpace: "pre",
    padding: "15px 10px 12px 14px",
    backgroundColor: "#ef5e5e",
  },
  dialogTitle: {
    width: "500px",
    height: "93px",
    margin: "0",
    padding: "25px 0 30px 25px",
    borderBottom: "1px solid #cccccc",
  },
  titleText: {
    marginTop: "10px",
  },
  dialogActions: {
    width: "500px",
    height: "70px",
    margin: "0",
    padding: "15px 27px 15px 0",
    "& button": {
      height: "40px",
    },
    "& span": {
      fontSize: "14px",
      fontWeight: "bold",
      color: "#ffffff",
    },
  },
  buttonClose: {
    backgroundColor: "#808080",
    boxShadow: `0px 2px 0px ${theme.button.cancel.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
  },
  dialogActionsButtonClose: {
    width: "120px",
    margin: "0",
    padding: "13px 25px",
    borderRadius: "5px",
  },

  fixedTable: {
    position: "relative",
    width: "470px",
    float: "left",
    zIndex: 1,
  },

  fixedTableContainer: {
    width: "100%",
  },
  scrollTable: {
    position: "relative",
    float: "left",
    width: "calc(100% - 470px)",
  },
  scrollTableContainer: {
    float: "left",
    width: "100%",
    overflow: "auto",
  },
  enterpriseName: {
    fontSize: "10px",
    color: "#393939",
    wordBreak: "break-word",
    float: "left",
    textAlign: "left",
  },
});

const ButtonCustom = withStyles((theme) => ({
  root: {
    "&$disabled": {
      color: "white",
    },
    padding: "5px",
  },
  disabled: {},
}))(Button);

const DialogTitleText = styled.div`
  height: 14px;
  line-height: 14px;
  font-size: 14px;
`;

const TipTextArea = styled.div``;

const TipText = styled.div``;
class AppointmentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenFileouploadDialog: false,
      isOpenFileouploadConfirmDialog: false,
      isOpenInsuranceCardConfirmDialog: false,
      isOpenInsuranceCardCautionDialog: false,
      isShowUploadDialogAlert: false,
      isOpenErrorDialog: false,
      isOpenConsentRegistrationDialog: false,
      alertMessage: "",
      insuranceCardFilePath: "",
      insuranceCardFileName: "",
      fileList: JSON.parse(JSON.stringify(defaultFileList)),
      appointmentSelect: {},
      getS3List: [],
      errorCode: [],
      validateInput: [],
      alreadyConfirm: false,
      showIconAttachment: [],
      treatmentInfor: {
        completeSelectOption: "",
        treatmentDate: "",
        memo: "",
        medicalFee: "",
        isShowDrug: false,
        drugName: "",
        drugQuantity: "",
        isShowBillingCategoryNote: false,
        drugQuantityOptionList: [],
      },
      isStaff: false,
      showDivision: false,
      doctor: null,
      isShowVideoTooltip: false,
      thumbnailPatientFiles: [],
      patientFileExpiredDay: "",
      isOpenAccessFileErrorDialog: false,
      consentRegistrations: [],
      selectedConsentRegistration: {},
    };
  }

  ErrorMessage = null;

  componentDidMount() {
    this.handleLoginDoctor();
    this.getPatientConsentRegistrations();
    this.resizeObserver = new ResizeObserver(() => {
      this.syncRowHeights();
    });

    // テーブルコンテナを監視
    const tableContainer = document.querySelector(
      `.${this.props.classes.tableContainer}`
    );
    if (tableContainer) {
      this.resizeObserver.observe(tableContainer);
    }
  }

  syncRowHeights = () => {
    const fixedRows = document.querySelectorAll(
      `.${this.props.classes.fixedTable} tbody tr`
    );
    const scrollRows = document.querySelectorAll(
      `.${this.props.classes.scrollTable} tbody tr`
    );

    fixedRows.forEach((fixedRow, index) => {
      const scrollRow = scrollRows[index];
      if (scrollRow) {
        const fixedHeight = fixedRow.clientHeight;
        const scrollHeight = scrollRow.clientHeight;
        const maxHeight = Math.max(fixedHeight, scrollHeight);
        fixedRow.style.height = `${maxHeight}px`;
        scrollRow.style.height = `${maxHeight}px`;
      }
    });
  };

  getPatientConsentRegistrations = () => {
    if (!this.props.isSupportOnlineQualificationSystem) return;
    client
      .query({
        query: GetMultipleConsentStatus,
      })
      .then((res) => {
        this.setState({
          consentRegistrations: res.data.multipleConsentStatus,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  canRegisterConsent = (appointment) => {
    const isOnline = appointment?.menu?.medicalMethod === MEDICAL_METHOD.ONLINE;
    return isOnline;
  };
  extractConsentRegistration = (appointment) => {
    if (!this.canRegisterConsent(appointment))
      return {
        consentStatus: CONSENT_STATUS.UNREGISTRABLE,
      };
    const consentRegistration = this.state.consentRegistrations.find(
      (consentRegistration) => {
        return (
          consentRegistration?.patientId === appointment?.patient?.patientId
        );
      }
    );
    if (!consentRegistration)
      return {
        consentStatus: CONSENT_STATUS.UNREGISTERED,
      };
    return consentRegistration;
  };

  openConsentDialog = (consentRegistration) => {
    this.setState({
      isOpenConsentRegistrationDialog: true,
      selectedConsentRegistration: consentRegistration,
    });
  };
  closeConsentDialog = () => {
    this.setState({
      isOpenConsentRegistrationDialog: false,
    });
  };

  handleClickDetail = (appointment) => {
    const q = querystring.stringify({
      patientId: appointment.patient.patientId,
      createTime: appointment.createTime,
    });
    this.props.history.push(`/medical?${q}`);
  };

  handleClickUpload = async (appointment) => {
    try {
      this.setState({ isLoading: true });
      let fileList = [];
      // Get list file uploaded
      const variables =
        appointment.menu &&
        appointment.menu.medicalMethod === MEDICAL_METHOD.OFFLINE
          ? {
              patientId: appointment.patient.patientId,
              createTime: appointment.createTime,
            }
          : {
              patientId: appointment.patient.patientId,
              createTime: appointment.createTime,
              medicalDoctorId: appointment.doctor.doctorId,
            };
      const { data } = await client.query({
        query: GetAppointment,
        variables: variables,
      });
      if (data) {
        let attachments = data.appointment.attachment || [];
        fileList = attachments.map((item) => {
          return { file: item };
        });

        const patientAttachments = data.appointment.patientAttachments;
        const thumbnailPatientFiles = await this.fetchPatientAttachments(
          patientAttachments
        );

        let reservationDay = new Date(data.appointment.from);
        const nexMonth = reservationDay.getMonth() + 1;
        reservationDay.setMonth(nexMonth);
        const lastDayofMonth = endOfMonth(reservationDay);
        const patientFileExpiredDay = format(lastDayofMonth, "yyy/MM/dd");

        this.setState({
          isOpenFileouploadDialog: true,
          appointmentSelect: appointment,
          fileList,
          newFileList: [],
          selectSomeFiles: false,
          thumbnailPatientFiles,
          patientFileExpiredDay,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
    return false;
  };

  resetList = () => {
    const newList = [];
    for (let i = 0; i < 5; i++) {
      newList.push({
        file: {},
        niceName: "",
      });
    }
    return newList;
  };

  // dialog event
  // --------------------------------------------------
  handleFileouploadDialog = (e) => {
    this.setState({
      errorCode: [],
      isOpenFileouploadDialog: false,
      selectSomeFiles: false,
    });
  };

  getFileName = (item) => {
    let file = {
      name: "",
      extension: "",
    };
    if (!_.isUndefined(item.file.name)) {
      // item.file.name.split(".");
      file.extension = _.last(item.file.name.split("."));
      file.name = item.file.name.slice(
        0,
        item.file.name.length - (file.extension.length + 1)
      );
      if (!_.isEmpty(item.niceName.trim())) {
        file.name = item.niceName.trim();
      }
    }
    return file;
  };

  handleFileouploadDialogOk = async () => {
    const fileList = this.state.fileList || [];
    const newFileList = this.state.newFileList || [];
    let isValid = true;

    if (newFileList.length > 0) {
      let objectNameFile = {};
      fileList.forEach((item) => {
        if (
          !item ||
          !item.file ||
          item.file.status === "deleted" ||
          item.justUpdatedStatus === "deleted" ||
          (item.file.expiredTime &&
            fns.isBefore(fns.parseISO(item.file.expiredTime), new Date()))
        )
          return;

        objectNameFile[FileUploadUtility.getLowerCaseFileName(item)] = {};
      });

      // validate file
      let key = 0;
      for (const item of newFileList) {
        if (item && item.file) {
          let validateInput = [];

          if (_.isEmpty(item.niceName.trim())) {
            const fileName = FileUploadUtility.getFileName(item);
            item.niceName = fileName.name;
          }

          // Check file name too long
          FileUploadUtility.isFileNameTooLong(item, MAX_FILE_NAME_LENGTH) &&
            validateInput.push("tooLongFileName");

          // reg exp validate text input
          FileUploadUtility.isUnusableFileName(item) &&
            validateInput.push("unusableFileName");

          // Check file type. Valid values are pdf, jpg, jpeg, png
          FileUploadUtility.isInvalidFileExtension(
            item.file,
            VALID_EXTENSIONS
          ) && validateInput.push("invalidExtension");

          // Check file size > 2MB
          item.file.size &&
            FileUploadUtility.isInvalidFileSize(item.file, MAX_FILE_SIZE) &&
            validateInput.push("tooBigFileSize");

          // Check if file was modified after being selected
          const checkExistFile = await FileUploadUtility.checkExistFile(
            item.file
          );
          if (!checkExistFile) {
            validateInput.push("localFileModified");
            isValid = false;
          }

          //get lower case file name befor check dulicate
          let nameFileLowerCase = FileUploadUtility.getLowerCaseFileName(item);

          if (!_.isUndefined(nameFileLowerCase)) {
            if (objectNameFile[nameFileLowerCase]) {
              //name file really exist on list
              if (
                !_.isUndefined(objectNameFile[nameFileLowerCase].key) &&
                _.isArray(
                  newFileList[objectNameFile[nameFileLowerCase].key].errors
                ) &&
                !_.includes(
                  newFileList[objectNameFile[nameFileLowerCase].key].errors,
                  "errSameName"
                )
              ) {
                newFileList[objectNameFile[nameFileLowerCase].key].errors.push(
                  "errSameName"
                );
              }
              validateInput.push("errSameName");
            } else {
              objectNameFile[nameFileLowerCase] = { key };
            }
          }

          validateInput = _.reject(validateInput, _.isEmpty);

          if (validateInput.length > 0) {
            isValid = false;
          }

          newFileList[key].errors = _.uniqBy(validateInput);
        }

        key++;
      }
    }

    this.setState({
      newFileList,
    });

    if (isValid) {
      this.setState({
        isOpenFileouploadDialog: false,
        isOpenFileouploadConfirmDialog: true,
      });
    }
  };

  handleFileouploadDialogCancel = (e) => {
    this.setState({
      fileList: JSON.parse(JSON.stringify(defaultFileList)),
      errorCode: [],
      isOpenFileouploadDialog: false,
      selectSomeFiles: false,
    });
  };

  // dialog file selection event
  // --------------------------------------------------
  handleChooseFileChange = (file, fileData, index) => {
    const newFileList = [...this.state.newFileList];
    newFileList[index] = {
      ...(newFileList[index] || {}),
      file,
      fileData,
      lastModified: file.lastModified,
      size: file.size,
      niceName: file && file.name && file.name.replace(/\.[a-zA-Z]*$/, ""),
    };

    this.setState({
      newFileList,
      selectSomeFiles: true,
    });
  };

  handleNiceNameChange = (name, index) => {
    const newFileList = this.state.newFileList || [];
    newFileList[index] = { ...newFileList[index], niceName: name };
    this.setState({
      newFileList,
    });
  };

  handleDeleteFile = async (
    deletedFile,
    existFileList,
    patientId,
    createTime
  ) => {
    const { filePath } = deletedFile;
    try {
      this.setState({ isLoading: true });
      const res = await client.mutate({
        mutation: DeleteAttachment,
        variables: {
          patientId,
          createTime,
          filePath,
        },
      });
      if (res && res.data && res.data.deleteAttachment) {
        const fileList = this.state.fileList.map((item) =>
          item.file.filePath === filePath
            ? { ...item, justUpdatedStatus: "deleted" }
            : item
        );
        let showIconAttachment = this.state.showIconAttachment;
        if (
          existFileList &&
          existFileList.some(
            (file) =>
              file.file.filePath !== filePath &&
              file.file.status === "available" &&
              file.justUpdatedStatus !== "deleted"
          )
        ) {
          showIconAttachment.push(this.state.appointmentSelect.createTime);
        } else {
          showIconAttachment = showIconAttachment.filter(
            (x) => x !== this.state.appointmentSelect.createTime
          );
        }
        const alreadyUploadOrDeleteAttachmentAppointment = [
          ...(this.state.alreadyUploadOrDeleteAttachmentAppointment || []),
          createTime,
        ];
        this.setState({
          fileList,
          showIconAttachment,
          isLoading: false,
          alreadyUploadOrDeleteAttachmentAppointment,
        });
      }
    } catch (error) {
      window.location.reload();
    }
  };

  // dialog confirm event
  // --------------------------------------------------
  handleFileouploadConfirmDialog = (e) => {
    this.setState({
      errorCode: [],
      isOpenFileouploadDialog: true,
      isOpenFileouploadConfirmDialog: false,
    });
  };

  //upload S3
  uploadToS3 = async (file, signedRequest) => {
    const options = {
      headers: {
        "Content-Type": file.type,
      },
    };
    await axios.put(signedRequest, file, options);
  };

  asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  // Check if file was modified after being selected
  checkLocalFileModified = async (items) => {
    const selectedFiles = items.filter(
      (selectedFile) =>
        selectedFile && selectedFile.file && selectedFile.file instanceof File
    );
    for (const item of selectedFiles) {
      const checkItemExistFile = await FileUploadUtility.checkExistFile(
        item.file
      );
      if (!checkItemExistFile) {
        this.setState({
          isOpenAccessFileErrorDialog: true,
        });
        return false;
      }
    }
    return true;
  };

  handleFileouploadConfirmDialogOk = async (e) => {
    let fileUpload = [...this.state.fileList, ...this.state.newFileList];
    let fileName = "";
    let fileType = "";
    let getPatientId = this.state.appointmentSelect.patient.patientId;
    let getCreateTime = this.state.appointmentSelect.createTime;
    let s3files = [];
    let fileExtension = "";

    // Check if file was modified after being selected
    const isLocalFileModified = await this.checkLocalFileModified(fileUpload);
    if (!isLocalFileModified) {
      return;
    }

    Auth.currentUserPoolUser()
      .then(async () => {
        try {
          this.setState({ isLoading: true });

          await this.asyncForEach(fileUpload, async (item) => {
            if (
              !_.isUndefined(item) &&
              !_.isUndefined(item.file) &&
              !_.isUndefined(item.file.name) &&
              item.justUpdatedStatus !== "deleted"
            ) {
              fileName = item.file.name.trim();
              fileType = item.file.type;
              const extension = fileName.split(".");
              fileExtension = `.${extension[extension.length - 1]}`;
              if (!_.isEmpty(item.niceName)) {
                fileName = item.niceName.trim() + fileExtension;
              }
              const response = await client.mutate({
                mutation: GenerateUploadPresignedUrl,
                variables: {
                  patientId: getPatientId,
                  fileName: fileName,
                  filetype: fileType,
                  medicalDoctorId: this.state.appointmentSelect.doctor.doctorId,
                },
              });
              const { putSignedRequest, filePath } =
                response.data.generateUploadPresignedUrl;

              await this.uploadToS3(item.file, putSignedRequest);

              const originalFilePath = `origin/${filePath}`;

              const thumbnailFilePath = `thumbnail/${filePath}`.replace(
                /\.pdf$/i,
                ".png"
              );
              s3files.push({
                fileName: fileName,
                fileType: fileType,
                filePath: originalFilePath,
                thumbnailFilePath,
              });
            } else {
              s3files.push(null);
            }
          });

          client
            .mutate({
              mutation: InsertAttachment,
              variables: {
                patientId: getPatientId,
                createTime: getCreateTime,
                s3files,
              },
            })
            .then(() => {
              const resetFileList = this.resetList();
              const indexOfAppointments = this.state.showIconAttachment;
              indexOfAppointments.push(getCreateTime);
              const alreadyUploadOrDeleteAttachmentAppointment = [
                ...(this.state.alreadyUploadOrDeleteAttachmentAppointment ||
                  []),
                getCreateTime,
              ];
              this.setState({
                isOpenFileouploadDialog: false,
                isOpenFileouploadConfirmDialog: false,
                fileList: resetFileList,
                errorCode: [],
                appointmentSelect: {},
                alreadyConfirm: false,
                showIconAttachment: _.uniq(indexOfAppointments),
                alreadyUploadOrDeleteAttachmentAppointment,
              });

              this.setState({
                isLoading: false,
                isShowUploadDialogAlert: true,
                alertMessage:
                  japaneseList.molecules.dialogs.UploadAlert.success,
              });
            })
            .catch(() => {
              this.setState({
                isLoading: false,
                isShowUploadDialogAlert: true,
                alertMessage: "",
              });
            });
        } catch (error) {
          this.setState({
            isLoading: false,
            isShowUploadDialogAlert: true,
            alertMessage: "",
          });
        }
      })
      .catch(() => {
        window.location.reload();
      });
  };

  handleFileouploadConfirmDialogCancel = (e) => {
    this.setState({
      errorCode: [],
      isOpenFileouploadDialog: true,
      isOpenFileouploadConfirmDialog: false,
    });
  };

  // insurance card caution
  // --------------------------------------------------
  handleClickInsuranceCardCaution = () => {
    //DialogInsuranceCardConfirm
    this.setState({
      isOpenInsuranceCardCautionDialog: true,
    });
  };
  handleInsuranceCardCautionDialog = (e) => {
    this.setState({
      isOpenInsuranceCardCautionDialog: false,
    });
  };
  handleInsuranceCardCautionDialogButton = (e) => {
    this.setState({
      isOpenInsuranceCardCautionDialog: false,
    });
  };

  // insurance card
  // --------------------------------------------------
  getInsuranceCardButton = (appo) => {
    const { classes } = this.props;
    return Boolean(appo.menu) && appo.menu.medicalMethod === "offline" ? (
      "-"
    ) : appo.existedInsuranceCard ? (
      <Button
        key="btn-insurance-card"
        disabled={AppointmentStatusesHideActionButtons.includes(appo.status)}
        className={`${classes.buttonMedial} ${classes.buttonIconOnlyInsurance}`}
        variant="raised"
        color="primary"
        onClick={(e) => this.handleClickInsuranceCard(appo)}
      >
        <img src={insuranceCard} alt={""} />
      </Button>
    ) : (
      <span
        style={{
          color: "#2563C0",
          cursor: "pointer",
          textDecoration: "underline",
        }}
        onClick={this.handleClickInsuranceCardCaution}
      >
        {AppointmentListResource.render.div.div.table.tbody.tr.td.button.j005}
      </span>
    );
  };

  handleShowInsuranceCardPopUp = async (filePath) => {
    this.setState({
      isOpenInsuranceCardConfirmDialog: true,
    });
    await client
      .mutate({
        mutation: GenerateGetInsuranceCardPresignedUrl,
        variables: {
          filePath,
        },
      })
      .then((res) => {
        if (res.data.generateGetInsuranceCardPresignedUrl.getSignedUrl) {
          this.setState({
            insuranceCardFileName: filePath,
            insuranceCardFilePath:
              res.data.generateGetInsuranceCardPresignedUrl.getSignedUrl,
          });
        }
      });
  };

  handleClickInsuranceCard = async (appointment) => {
    this.setState({
      insuranceCardFilePath: "",
    });
    await client
      .query({
        query: GetAppointment,
        variables: {
          patientId: appointment.patient.patientId,
          createTime: appointment.createTime,
          medicalDoctorId: appointment.doctor.doctorId,
        },
      })
      .then((res) => {
        if (res.data.appointment.patient.insuranceCard) {
          this.handleShowInsuranceCardPopUp(
            res.data.appointment.patient.insuranceCard.filePath
          );
        }
      });
  };

  handleInsuranceCardConfirmDialog = (e) => {
    this.setState({
      isOpenInsuranceCardConfirmDialog: false,
    });
  };

  handleInsuranceCardConfirmDialogButton = (e) => {
    this.setState({
      isOpenInsuranceCardConfirmDialog: false,
    });
  };

  handleOpenStatusChangeDialog = async (appo) => {
    const { data } = await client.query({
      query: GetAppointmentRequiredInputFields,
      variables: {
        patientId: appo.patient.patientId,
        createTime: appo.createTime,
      },
    });
    const { requireMedicalExpense, requireDrugName, requireDrugQuantity } =
      (data && data.appointment) || {};

    const treatmentInfor = {
      completeSelectOption: "",
      treatmentDate: appo.from ? getDayTextJaFormatShort(appo.from) : "",
      memo: "",
      drugName: "",
      drugQuantity: "",
      isDrugNameInputDisabled: false,
      isDrugQuantitySelectDisabled: false,
      billingCategory: "",
      requireMedicalExpense,
      requireDrugName,
      requireDrugQuantity,
      isShowBillingCategoryNote: false,
      drugQuantityOptionList: [],
      isShowDrug: false,
    };
    const prescriptionDrug = appo.contractPlan?.plan?.prescriptionDrug;
    if (
      prescriptionDrug &&
      prescriptionDrug.inputMethod === PRESCRIPTION_DRUG_INPUT_METHOD.FIXED
    ) {
      treatmentInfor.drugName =
        appo.contractPlan.plan.prescriptionDrug.fixedDrugsName;
    }
    const isBillingEnterprise =
      appo.menu &&
      appo.menu.paymentMethod === PAYMENT_METHOD.BILLING_ENTERPRISE;
    if (isBillingEnterprise) {
      treatmentInfor.isShowDrug = true;
      treatmentInfor.isShowBillingCategoryNote = true;
    }
    this.setState({
      isOpenStatusChangeDialog: true,
      appointmentSelect: appo,
      treatmentInfor: { ...treatmentInfor },
    });
  };

  handleOpenStatusChangeDialogConfirm = (appo, treatmentInfor) => {
    const treatmentInforTrimInput = {
      ...treatmentInfor,
      drugName: treatmentInfor.drugName,
      drugQuantity: treatmentInfor.drugQuantity,
      billingCategory: treatmentInfor.billingCategory,
      memo: treatmentInfor.memo,
    };
    this.setState({
      isOpenStatusChangeDialogConfirm: true,
      isOpenStatusChangeDialog: false,
      treatmentInfor: treatmentInforTrimInput,
      appointmentSelect: appo,
    });
  };

  // Handle to show pop-up cancel confirm
  handleOpenCancelStatusChangeDialogConfirm = (e) => {
    this.setState({
      isOpenCancelStatusChangeDialogConfirm: true,
    });
  };

  handleChangeMemo = (e, handleEnableSubmitButton) => {
    const treatmentInfor = {
      ...this.state.treatmentInfor,
      memo: e.target.value,
    };
    this.setState(
      {
        treatmentInfor,
      },
      () => handleEnableSubmitButton()
    );
  };

  handleBackButton = (e) => {
    this.setState({
      isOpenStatusChangeDialog: true,
      isOpenStatusChangeDialogConfirm: false,
    });
  };

  handleSelectCompleteOption = (e) => {
    const treatmentInfor = { ...this.state.treatmentInfor };
    const isBillingEnterprise =
      this.state.appointmentSelect.menu &&
      this.state.appointmentSelect.menu.paymentMethod ===
        PAYMENT_METHOD.BILLING_ENTERPRISE;
    if (Number(e.target.value) === -1) {
      this.setState({
        treatmentInfor: {
          ...treatmentInfor,
          memo: "",
          medicalFee: "",
          medicalFeeFormated: "0",
          drugName: "",
          drugQuantity: "",
          billingCategory: "",
          isShowBillingCategoryNote: isBillingEnterprise ? true : false,
          isShowDrug: isBillingEnterprise ? true : false,
          completeSelectOption: Number(e.target.value),
        },
      });
      return;
    }

    if (this.state.appointmentSelect.contractPlan) {
      const { inputMethod, fixedDrugsName } =
        this.state.appointmentSelect.contractPlan.plan.prescriptionDrug;

      switch (inputMethod) {
        case PRESCRIPTION_DRUG_INPUT_METHOD.FIXED:
          if (isBillingEnterprise) {
            treatmentInfor.drugName = fixedDrugsName;
          }
          treatmentInfor.isDrugNameInputDisabled = true;
          break;
        case PRESCRIPTION_DRUG_INPUT_METHOD.NONE:
          treatmentInfor.drugQuantity = "0";
          treatmentInfor.isDrugNameInputDisabled = true;
          treatmentInfor.isDrugQuantitySelectDisabled = true;
          break;
        default:
          break;
      }
    }

    this.setState({
      treatmentInfor: {
        ...treatmentInfor,
        completeSelectOption: Number(e.target.value),
      },
    });
  };

  handleChangeMedicalFee = (values, handleEnableSubmitButton) => {
    this.setState(
      {
        treatmentInfor: {
          ...this.state.treatmentInfor,
          medicalFee: values.target.value,
        },
      },
      () => handleEnableSubmitButton()
    );
  };

  handleBlurMedicalFee = (value) => {
    const treatmentInfor = {
      ...this.state.treatmentInfor,
      medicalFee: value,
    };
    this.setState({
      treatmentInfor,
    });
  };

  handleChangeDrugName = (e, handleEnableSubmitButton) => {
    const treatmentInfor = {
      ...this.state.treatmentInfor,
      drugName: e.target.value,
    };
    this.setState({ treatmentInfor }, () => handleEnableSubmitButton());
  };

  handleChangeDrugQuantity = (e, handleEnableSubmitButton) => {
    const treatmentInfor = {
      ...this.state.treatmentInfor,
      drugQuantity: e.target.value,
    };
    this.setState({ treatmentInfor }, () => handleEnableSubmitButton());
  };

  handleChangeBillingCategory = (e, handleEnableSubmitButton) => {
    const treatmentInfor = {
      ...this.state.treatmentInfor,
      billingCategory: e.target.value,
    };
    const prescriptionDrug =
      this.state.appointmentSelect.contractPlan?.plan?.prescriptionDrug;
    treatmentInfor.drugName =
      prescriptionDrug.inputMethod === PRESCRIPTION_DRUG_INPUT_METHOD.FIXED
        ? prescriptionDrug.fixedDrugsName
        : "";
    treatmentInfor.drugQuantity = "";
    const settings = getBillingCategorySettings(e.target.value);
    Object.assign(treatmentInfor, settings);
    this.setState({ treatmentInfor }, () => handleEnableSubmitButton());
  };

  handleChangeStatusDialogConfirmButton = async () => {
    this.setState({
      isOpenStatusChangeDialogConfirm: false,
      isLoading: true,
    });
    const isBillingEnterprise =
      this.state.appointmentSelect.menu &&
      this.state.appointmentSelect.menu.paymentMethod ===
        PAYMENT_METHOD.BILLING_ENTERPRISE;
    try {
      const variables = {
        patientId: this.state.appointmentSelect.patient.patientId,
        createTime: this.state.appointmentSelect.createTime,
        treatmentCompletedSelectedOption:
          this.state.treatmentInfor.completeSelectOption,
        memo: this.state.treatmentInfor.memo,
      };
      const { data, errors } = await client.mutate({
        mutation: UpdateMedicalTreatment,
        variables: isBillingEnterprise
          ? {
              ...variables,
              drugsQuantity:
                this.state.treatmentInfor.completeSelectOption ===
                TREATMENT_COMPLETED_SELECTED_OPTION.VISITED
                  ? this.state.treatmentInfor.drugQuantity
                  : undefined,
              drugsName:
                this.state.treatmentInfor.completeSelectOption ===
                TREATMENT_COMPLETED_SELECTED_OPTION.VISITED
                  ? this.state.treatmentInfor.drugName
                  : undefined,
              billingCategory:
                this.state.treatmentInfor.completeSelectOption ===
                TREATMENT_COMPLETED_SELECTED_OPTION.VISITED
                  ? this.state.treatmentInfor.billingCategory
                  : undefined,
              medicalExpense: 0,
            }
          : {
              ...variables,
              medicalExpense:
                Boolean(this.state.appointmentSelect.patient.organizationId) &&
                this.state.treatmentInfor.completeSelectOption ===
                  TREATMENT_COMPLETED_SELECTED_OPTION.VISITED
                  ? this.state.treatmentInfor.medicalFee
                  : undefined,
              drugsName:
                this.state.treatmentInfor.requireDrugName === true &&
                this.state.treatmentInfor.completeSelectOption ===
                  TREATMENT_COMPLETED_SELECTED_OPTION.VISITED
                  ? this.state.treatmentInfor.drugName
                  : undefined,
              drugsQuantity:
                this.state.treatmentInfor.requireDrugQuantity === true &&
                this.state.treatmentInfor.completeSelectOption ===
                  TREATMENT_COMPLETED_SELECTED_OPTION.VISITED
                  ? this.state.treatmentInfor.drugQuantity
                  : undefined,
            },
      });
      if (errors) {
        errors.forEach((error) => {
          const errorResource = ApprovedErrorResources.Errors.find(
            (item) => item.code === error.errorType
          );
          this.ErrorMessage = errorResource && errorResource.text;
        });
        this.setState({
          isLoading: false,
          isOpenErrorDialog: true,
        });
        return;
      }
      if (data) {
        this.setState({
          isLoading: false,
        });
        this.props.refetch();
      }
    } catch (errors) {
      console.log("errors", errors);
      if (errors instanceof Error) {
        const errorResource = ApprovedErrorResources.Errors.find(
          (item) => item.code === "E06"
        );
        this.ErrorMessage = errorResource && errorResource.text;
        this.setState({
          isLoading: false,
          isOpenErrorDialog: true,
        });
        return;
      }
      errors.forEach((error) => {
        const errorResource = ApprovedErrorResources.Errors.find(
          (item) => item.code === error.errorType
        );
        this.ErrorMessage = errorResource && errorResource.text;
      });
      this.setState({
        isLoading: false,
        isOpenErrorDialog: true,
      });
    }
  };

  handleCloseDialogConfirm = () => {
    const treatmentInfor = {
      completeSelectOption: "",
      treatmentDate: "",
      memo: "",
    };
    this.setState({
      isOpenStatusChangeDialogConfirm: false,
      isOpenStatusChangeDialog: false,
      isOpenCancelStatusChangeDialogConfirm: false,
      isOpenFileouploadConfirmDialog: false,
      treatmentInfor,
    });
  };

  handleCancelDialogConfirm = () => {
    this.setState({
      isOpenCancelStatusChangeDialogConfirm: false,
    });
  };

  handleStaffClick = () => {
    this.setState({ isStaff: true });
  };

  handleCloseDilog = () => {
    this.setState({ isStaff: false, isShowVideoTooltip: true });
  };

  handleLoginDoctor = async () => {
    const doctor = await client
      .query({
        query: GetDoctor,
      })
      .then((res) => {
        if (res && res.data && res.data.doctor) {
          return res.data.doctor;
        }
      })
      .catch((err) => {
        console.error(err);
      });
    this.setState({
      doctor: doctor,
    });
  };

  handleTooltipDisplay = () => {
    this.setState({
      isShowVideoTooltip: false,
    });
  };

  handleTooltipCancelDisplay = () => {
    this.setState({
      isShowVideoTooltip: true,
    });
  };
  fetchPatientAttachments = async (patientFiles) => {
    if (!patientFiles) return [];
    const preSignedURLs = await Promise.all(
      patientFiles.map((thumbnailFile) => {
        return client.query({
          query: AttachmentPresignedUrlForGetObject,
          variables: {
            filePath: thumbnailFile.thumbnailFilePath,
            fileName: thumbnailFile.fileName,
          },
        });
      })
    );

    const fileBuffers = await Promise.all(
      preSignedURLs.map((preSignUrl) => {
        return axios.get(
          preSignUrl.data.attachmentPresignedUrlForGetObject.url,
          {
            responseType: "arraybuffer",
          }
        );
      })
    );

    const files = patientFiles.map((thumbnailFile, index) => {
      const filePathDevides =
        thumbnailFile.thumbnailFilePath &&
        thumbnailFile.thumbnailFilePath.split("/");
      const fileIdName = filePathDevides[filePathDevides.length - 1];
      const fileId = fileIdName.split(".").slice(0, -1).join("");
      const extensionFile = fileIdName.split(".").slice(-1)[0];
      const newFile = {
        fileName: thumbnailFile.fileName,
        fileId,
        extensionFile,
        fileType: thumbnailFile.fileType,
        thumbnailFilePath: thumbnailFile.thumbnailFilePath,
        filePath: thumbnailFile.filePath,
        uploadedTime: thumbnailFile.uploadedTime,
        updatedTime: thumbnailFile.updatedTime,
      };

      return {
        ...newFile,
        thumbnailFile: new Blob([fileBuffers[index].data]),
      };
    });
    return files;
  };

  handleBackFromUploadFileConfirm = () => {
    this.setState({
      isOpenFileouploadDialog: true,
      isOpenFileouploadConfirmDialog: false,
    });
  };

  handleCloseErrorPopUp = () => {
    this.setState({
      isOpenAccessFileErrorDialog: false,
    });
  };

  renderConsentRegistration = (appointment) => {
    const { classes } = this.props;
    const consentRegistration = this.extractConsentRegistration(appointment);
    switch (consentRegistration.consentStatus) {
      case CONSENT_STATUS.UNREGISTRABLE:
        return (
          <div>{AppointmentListResource.consentRegistration.unregistrable}</div>
        );
      case CONSENT_STATUS.UNREGISTERED:
      case CONSENT_STATUS.REGISTERING:
      case CONSENT_STATUS.REGISTERED:
      default:
        return (
          <div
            className={classes.clickAreaCenter}
            onClick={() => this.openConsentDialog(consentRegistration)}
          >
            {
              AppointmentListResource.consentRegistration[
                consentRegistration.consentStatus
              ]
            }
          </div>
        );
    }
  };

  render() {
    const { classes } = this.props;
    const appointments = this.props.appointments;
    const {
      isOpenFileouploadDialog,
      isOpenFileouploadConfirmDialog,
      isOpenInsuranceCardConfirmDialog,
      isOpenInsuranceCardCautionDialog,
      insuranceCardFilePath,
      insuranceCardFileName,
      isShowUploadDialogAlert,
      alertMessage,
      isStaff,
      isShowVideoTooltip,
    } = this.state;
    return (
      <div className={classes.root}>
        <React.Fragment>
          <div>
            <div className={classes.fixedTable}>
              <div className={classes.fixedTableContainer}>
                <table className={classes.tbl}>
                  <thead className={classes.head}>
                    <tr>
                      <td
                        className={`${classes.doctor} ${classes.td} ${classes.noHead} ${classes.stick}`}
                      >
                        {
                          AppointmentListResource.render.div.table.thead.tr.td
                            .j011
                        }
                      </td>
                      <td
                        className={`${classes.td} ${classes.date} ${classes.stick}`}
                      >
                        {
                          AppointmentListResource.render.div.table.thead.tr.td
                            .j002
                        }
                      </td>
                      <td
                        className={`${classes.td} ${classes.name} ${classes.stick}`}
                      >
                        {
                          AppointmentListResource.render.div.table.thead.tr.td
                            .j003
                        }
                      </td>
                    </tr>
                  </thead>
                  {appointments.length > 0 && (
                    <tbody className={classes.body}>
                      {appointments.map((appo, i) => {
                        return (
                          <tr
                            className={`${classes.row} ${
                              appo.status === "rejected" ? classes.rejected : ""
                            }`}
                            key={`appointment-${i}`}
                          >
                            <td
                              className={`${classes.td} ${classes.doctor} ${classes.doctorContent}`}
                            >
                              {appo.doctor
                                ? `${appo.doctor.familyName} ${appo.doctor.givenName}`
                                : AppointmentListResource.render.div.div.table
                                    .tbody.tr.td.type.j001}
                            </td>
                            <td className={`${classes.td} ${classes.date}`}>
                              <div className={classes.divTime}>
                                <div className={classes.divTimeContent}>
                                  <p>
                                    {format(parseISO(appo.from), "HH:mm")}～
                                    {format(parseISO(appo.to), "HH:mm")}
                                  </p>
                                </div>
                                <div className={classes.right}>
                                  <ButtonCustom
                                    disabled={appo.status !== "beforeExam"}
                                    key="btn-edit"
                                    className={classes.buttonMedial}
                                    variant="raised"
                                    size="small"
                                    color="primary"
                                    onClick={(e) =>
                                      this.props.onAppointmentEdit(appo, "edit")
                                    }
                                  >
                                    {
                                      AppointmentListResource.render.div.div
                                        .table.tbody.tr.td.button.j007
                                    }
                                  </ButtonCustom>
                                </div>
                              </div>
                            </td>
                            <td className={`${classes.td} ${classes.name}`}>
                              <div
                                className={classes.clickArea}
                                onClick={() =>
                                  patientDetail.openPatientDetailWindow(
                                    appo.patient.patientId
                                  )
                                }
                              >
                                <div className={classes.divName}>
                                  {`${appo.patient.familyName} ${appo.patient.givenName}`}
                                </div>
                                <div className={classes.divNameKana}>
                                  {`${appo.patient.familyNameKana} ${appo.patient.givenNameKana}`}
                                </div>
                              </div>
                              {appo.enterpriseName && (
                                <div className={classes.enterpriseName}>
                                  {`(${AppointmentListResource.EnterpriseName}${appo.enterpriseName})`}
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>

            <div className={classes.scrollTable}>
              <div className={classes.scrollTableContainer}>
                <table className={classes.tbl}>
                  <thead className={classes.head}>
                    <tr>
                      <td
                        className={`${classes.td} ${classes.insuranceCard} ${classes.stick}`}
                      >
                        {
                          AppointmentListResource.render.div.table.thead.tr.td
                            .j003_2
                        }
                      </td>
                      {this.props.isSupportOnlineQualificationSystem && (
                        <td
                          className={`${classes.td} ${classes.consentRegistrations} ${classes.stick}`}
                        >
                          {
                            AppointmentListResource.render.div.table.thead.tr.td
                              .j012
                          }
                        </td>
                      )}
                      <td
                        className={`${classes.td} ${classes.menu} ${classes.stick}`}
                      >
                        {
                          AppointmentListResource.render.div.table.thead.tr.td
                            .j006
                        }
                      </td>
                      <td
                        className={`${classes.td} ${classes.status} ${classes.stick}`}
                      >
                        {
                          AppointmentListResource.render.div.table.thead.tr.td
                            .j007
                        }
                      </td>
                      <td
                        className={`${classes.td} ${classes.op} ${classes.stick}`}
                        style={{ textAlign: "center" }}
                      >
                        {
                          AppointmentListResource.render.div.table.thead.tr.td
                            .j008
                        }
                      </td>
                      <td
                        className={`${classes.td} ${classes.upload} ${classes.stick}`}
                      >
                        {
                          AppointmentListResource.render.div.table.thead.tr.td
                            .j009
                        }
                      </td>
                      <td
                        className={`${classes.td} ${classes.cancel} ${classes.stick}`}
                      >
                        {
                          AppointmentListResource.render.div.table.thead.tr.td
                            .j010
                        }
                      </td>
                    </tr>
                  </thead>
                  {appointments.length > 0 && (
                    <tbody className={classes.body}>
                      {appointments.map((appo, i) => {
                        const fileUploadedIsAvailable =
                          ((!this.state
                            .alreadyUploadOrDeleteAttachmentAppointment ||
                            !this.state.alreadyUploadOrDeleteAttachmentAppointment.includes(
                              appo.createTime
                            )) &&
                            appo.attachment &&
                            appo.attachment.some(
                              (file) =>
                                !_.isUndefined(file.expiredTime) &&
                                fns.isAfter(
                                  fns.parseISO(file.expiredTime),
                                  new Date()
                                ) &&
                                file.status === "available"
                            )) ||
                          this.state.showIconAttachment.includes(
                            appo.createTime
                          );
                        // Check if exist patient attachments && it is still before attachments's expire date
                        const patientAttachmentDueDate = fns.endOfMonth(
                          fns.addMonths(
                            fns.parseISO(appo.from),
                            NUMBER_MONTHS_ADD
                          )
                        );

                        const existPatientAttachments =
                          appo.patientAttachments &&
                          Date.now() <= patientAttachmentDueDate.getTime();

                        const disabledButtonUploadFile =
                          !existPatientAttachments &&
                          (_.concat(
                            AppointmentStatusesHideActionButtons,
                            "beforeExam"
                          ).includes(appo.status) ||
                            appo.menu.medicalMethod !== "online");
                        return (
                          <tr
                            className={`${classes.row} ${
                              appo.status === "rejected" ? classes.rejected : ""
                            }`}
                            key={`appointment-${i}`}
                          >
                            <td
                              className={`${classes.td} ${classes.insuranceCard}`}
                            >
                              {AppointmentStatusesHideActionButtons.includes(
                                appo.status
                              )
                                ? "-"
                                : this.getInsuranceCardButton(appo)}
                            </td>
                            {this.props.isSupportOnlineQualificationSystem && (
                              <td
                                className={`${classes.td} ${classes.consentRegistrations}`}
                              >
                                {this.renderConsentRegistration(appo)}
                              </td>
                            )}
                            <td className={`${classes.td} ${classes.menu}`}>
                              <div className={classes.divMenu}>
                                {appo.menu.medicalMenu &&
                                appo.menu.medicalMenu ===
                                  MEDICAL_MENU.EXAMINATIONWELFARE ? (
                                  <span>
                                    {
                                      AppointmentListResource.examinationWelfare
                                        .j001
                                    }
                                    <br />
                                    {
                                      AppointmentListResource.examinationWelfare
                                        .j002
                                    }
                                  </span>
                                ) : (
                                  <span>{appo.menu.displayName}</span>
                                )}
                                <br />
                                {Boolean(
                                  appo.menu.supportsInsuranceTreatment
                                ) ? (
                                  <IconInsurance
                                    className={classes.iconInsurance}
                                  >
                                    {japaneseList.insurance.ok}
                                  </IconInsurance>
                                ) : (
                                  <IconNotInsurance
                                    className={classes.iconNotInsurance}
                                  >
                                    {japaneseList.insurance.no}
                                  </IconNotInsurance>
                                )}
                              </div>
                            </td>
                            <td className={`${classes.td} ${classes.status}`}>
                              <div className={classes.statusContent}>
                                <div className={classes.statusContentLeft}>
                                  <AppointmentStatus
                                    appointment={appo}
                                    menu={appo.menu}
                                  />
                                </div>
                                <div className={classes.right}>
                                  <ButtonCustom
                                    key="btn-edit"
                                    className={classes.buttonMedial}
                                    variant="raised"
                                    size="small"
                                    color="primary"
                                    disabled={
                                      appo.status !== "beforeExam" ||
                                      appo.menu.medicalMethod !== "offline"
                                    }
                                    classes={{
                                      disabled: classes.disabled,
                                    }}
                                    onClick={() =>
                                      this.handleOpenStatusChangeDialog(appo)
                                    }
                                  >
                                    {
                                      AppointmentListResource.render.div.div
                                        .table.tbody.tr.td.button.j002
                                    }
                                  </ButtonCustom>
                                </div>
                              </div>
                            </td>
                            <td className={`${classes.td} ${classes.op}`}>
                              {AppointmentStatusesHideActionButtons.includes(
                                appo.status
                              ) || appo.menu.medicalMethod === "offline" ? (
                                <Button
                                  disabled={
                                    AppointmentStatusesHideActionButtons.includes(
                                      appo.status
                                    ) || appo.menu.medicalMethod === "offline"
                                  }
                                  className={`${classes.buttonMedial} ${classes.buttonIconOnly}`}
                                  variant="raised"
                                  color="primary"
                                >
                                  <VideocamIcon color="secondary" />
                                </Button>
                              ) : this.state.doctor &&
                                this.state.doctor.doctorId ===
                                  appo.doctor.doctorId ? (
                                <Button
                                  key="btn-medical-detail"
                                  className={`${classes.buttonMedial} ${classes.buttonIconOnly}`}
                                  variant="raised"
                                  color="primary"
                                  onClick={(e) => this.handleClickDetail(appo)}
                                >
                                  <VideocamIcon color="secondary" />
                                </Button>
                              ) : (
                                <Tooltip
                                  classes={{ tooltip: classes.tip }}
                                  title={
                                    <TipTextArea>
                                      <TipText>
                                        {
                                          AppointmentListResource.StaffDialog
                                            .text.j001
                                        }
                                      </TipText>
                                      <TipText>
                                        {
                                          AppointmentListResource.StaffDialog
                                            .text.j002
                                        }
                                      </TipText>
                                    </TipTextArea>
                                  }
                                  disableFocusListener={isShowVideoTooltip}
                                >
                                  <Button
                                    className={`${classes.buttonMedial} ${classes.buttonIconOnly}`}
                                    onClick={() => this.handleStaffClick()}
                                    onFocus={() => this.handleTooltipDisplay()}
                                    onBlur={() =>
                                      this.handleTooltipCancelDisplay()
                                    }
                                  >
                                    <VideocamIcon color="secondary" />
                                  </Button>
                                </Tooltip>
                              )}
                            </td>
                            <td className={`${classes.td} ${classes.upload}`}>
                              <div
                                style={
                                  fileUploadedIsAvailable ||
                                  existPatientAttachments
                                    ? {
                                        display: "inline-grid",
                                        justifyItems: "center",
                                        alignItems: "center",
                                        textAlign: "center",
                                        marginTop: "10px",
                                      }
                                    : {}
                                }
                              >
                                <Button
                                  disabled={disabledButtonUploadFile}
                                  key="btn-update-files"
                                  className={`${classes.buttonMedial} ${classes.buttonIconOnly}`}
                                  variant="raised"
                                  color="primary"
                                  onClick={(e) => this.handleClickUpload(appo)}
                                >
                                  <DescriptionRoundedIcon color="secondary" />
                                </Button>
                                <span className={classes.spanFileNameUpload}>
                                  {(fileUploadedIsAvailable ||
                                    existPatientAttachments) &&
                                    AppointmentListResource.render.div.div.table
                                      .tbody.tr.td.button.j008}
                                </span>
                              </div>
                            </td>
                            <td className={`${classes.td} ${classes.cancel}`}>
                              <Button
                                disabled={appo.status !== "beforeExam"}
                                key="btn-cancel"
                                className={`${classes.buttonMedial} ${classes.buttonIconOnly} ${classes.buttonCancel}`}
                                variant="raised"
                                size="small"
                                onClick={(e) =>
                                  this.props.onAppointmentEdit(appo, "cancel")
                                }
                              >
                                <DeleteForeverRoundedIcon color="secondary" />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>

          {appointments.length === 0 && (
            <div className={classes.divNoData}>
              {AppointmentListResource.render.div.Typography.j001}
            </div>
          )}
          <FileuploadDialog
            appointmentSelected={this.state.appointmentSelect}
            thumbnailPatientFiles={this.state.thumbnailPatientFiles}
            patientFileExpiredDay={this.state.patientFileExpiredDay}
            fileList={this.state.fileList}
            newFileList={this.state.newFileList}
            selectSomeFiles={this.state.selectSomeFiles}
            errorCode={this.state.errorCode}
            open={isOpenFileouploadDialog}
            onClose={this.handleFileouploadDialog}
            handleClickOk={this.handleFileouploadDialogOk}
            handleClickCancel={this.handleFileouploadDialogCancel}
            handleChooseFileChange={this.handleChooseFileChange}
            handleNiceNameChange={this.handleNiceNameChange}
            patient={this.state.appointmentSelect.patient}
            doctor={this.state.appointmentSelect.doctor}
            handleDeleteFile={(updatedTime, existFileList) =>
              this.handleDeleteFile(
                updatedTime,
                existFileList,
                this.state.appointmentSelect.patient.patientId,
                this.state.appointmentSelect.createTime
              )
            }
          />
          <FileuploadConfirmDialog
            open={isOpenFileouploadConfirmDialog}
            onClose={this.handleFileouploadConfirmDialog}
            alreadyConfirm={this.state.alreadyConfirm}
            handleClickOk={this.handleFileouploadConfirmDialogOk}
            handleClickCancel={this.handleOpenCancelStatusChangeDialogConfirm}
            fileList={this.state.fileList}
            newFileList={this.state.newFileList}
            errorCode={this.state.errorCode}
            handleBack={this.handleBackFromUploadFileConfirm}
          />
          <DialogInsuranceCardConfirm
            open={isOpenInsuranceCardConfirmDialog}
            filePath={insuranceCardFilePath}
            fileName={insuranceCardFileName}
            onClose={this.handleInsuranceCardConfirmDialog}
            handleClickButton={this.handleInsuranceCardConfirmDialogButton}
          />

          <FileuploadCompleteDialog
            open={isShowUploadDialogAlert}
            onClose={() => {
              this.setState({
                isShowUploadDialogAlert: false,
              });
            }}
            alertMessage={alertMessage}
            handleClickOk={() => {
              this.setState({
                isShowUploadDialogAlert: false,
              });
            }}
          />
          <DialogInsuranceCardCaution
            open={isOpenInsuranceCardCautionDialog}
            onClose={this.handleInsuranceCardCautionDialog}
            handleClickButton={this.handleInsuranceCardCautionDialogButton}
          />
          <Dialog
            open={Boolean(this.state.isLoading)}
            fullWidth={true}
            PaperProps={{
              style: {
                willChange: "initial !important",
              },
            }}
          >
            <LoadingScreen isLoading={Boolean(this.state.isLoading)} />
          </Dialog>
          <ChangeStatusDialogInput
            open={this.state.isOpenStatusChangeDialog}
            handleOpenStatusChangeDialogConfirm={
              this.handleOpenStatusChangeDialogConfirm
            }
            onClose={() => {
              this.setState({
                isOpenStatusChangeDialog: false,
              });
            }}
            handleSelectCompleteOption={this.handleSelectCompleteOption}
            handleChangeMemo={this.handleChangeMemo}
            handleOpenCancelStatusChangeDialogConfirm={
              this.handleOpenCancelStatusChangeDialogConfirm
            }
            treatmentInfor={this.state.treatmentInfor}
            handleChangeMedicalFee={this.handleChangeMedicalFee}
            handleBlurMedicalFee={this.handleBlurMedicalFee}
            handleChangeDrugName={this.handleChangeDrugName}
            handleChangeDrugQuantity={this.handleChangeDrugQuantity}
            handleChangeBillingCategory={this.handleChangeBillingCategory}
            appointment={this.state.appointmentSelect}
          />
          <ChangeStatusDialogConfirm
            open={this.state.isOpenStatusChangeDialogConfirm}
            onClose={() => {
              this.setState({
                isOpenStatusChangeDialogConfirm: true,
                isOpenCancelStatusChangeDialogConfirm: true,
              });
            }}
            handleClickButton={this.handleChangeStatusDialogConfirmButton}
            treatmentInfor={this.state.treatmentInfor}
            appointment={this.state.appointmentSelect}
            handleBackButton={this.handleBackButton}
          />
          <DialogConfirm
            open={this.state.isOpenCancelStatusChangeDialogConfirm}
            handleCancelDialogConfirm={this.handleCancelDialogConfirm}
            handleCloseDialogConfirm={this.handleCloseDialogConfirm}
            title={AppointmentListResource.DialogConfirm.title}
          >
            {AppointmentListResource.DialogConfirm.content}
          </DialogConfirm>
          {this.state.isOpenErrorDialog && (
            <DialogError
              open={this.state.isOpenErrorDialog}
              handleCloseDialogError={() => {
                window.location.reload();
              }}
              children={(this.ErrorMessage ? this.ErrorMessage : " ")
                .split("\n")
                .map((item, index) => (
                  <span key={index}>
                    {index > 0 ? <br /> : ""}
                    {item}
                  </span>
                ))}
            />
          )}
          <Dialog disableBackdropClick open={isStaff}>
            <DialogTitle className={classes.dialogTitle}>
              <DialogTitleText>
                {AppointmentListResource.StaffDialog.text.j001}
              </DialogTitleText>
              <DialogTitleText className={classes.titleText}>
                {AppointmentListResource.StaffDialog.text.j002}
              </DialogTitleText>
            </DialogTitle>
            <DialogActions className={classes.dialogActions}>
              <Button
                className={`${classes.buttonClose} ${classes.dialogActionsButtonClose}`}
                onClick={() => this.handleCloseDilog()}
              >
                {AppointmentListResource.StaffDialog.button.j001}
              </Button>
            </DialogActions>
          </Dialog>

          <DialogConsentRegistration
            isOpen={this.state.isOpenConsentRegistrationDialog}
            handleClose={this.closeConsentDialog}
            consentRegistration={this.state.selectedConsentRegistration}
          />
          <DialogAccessFileError
            isOpen={this.state.isOpenAccessFileErrorDialog}
            title={ApprovedErrorResources.accessFileError}
            handleClose={this.handleCloseErrorPopUp}
            cancelText={ApprovedErrorResources.buttonOk}
          />
        </React.Fragment>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(AppointmentList));
