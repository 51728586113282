// required　modules
// --------------------------------------------------
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, DialogContentText } from "@material-ui/core";
import DialogMedical from "../../molecules/dialogs/DialogMedical";
import DialogCancelConfirm from "../../molecules/dialogs/DialogConfirm";
import isNumber from "lodash/isNumber";
// personal modules
// --------------------------------------------------
import LinkA from "../../atoms/links/LinkA";
import { MemoTextarea } from "../../molecules/appointment/MemoTextarea";
import { DrugInput } from "../../molecules/appointment/DrugInput";
import { BillingCategorySelect } from "../../molecules/appointment/BillingCategorySelect";
import { DrugNameInput } from "../../organisms/appointment/DrugNameInput";
import { DrugQuantitySelect } from "../../molecules/appointment/DrugQuantitySelect";
import { MedicalFeeInput } from "../../molecules/appointment/MedicalFeeInput";
import PatientInfo from "../dialog/PatientInfo";
import { japaneseList } from "../../../Resources/japaneseList";
import { toHalfWidthNumbers } from "../../../Utils/DisplayLogic";
import { getBillingCategorySettings } from "../../../Utils/BillingCategorySettings";
import {
  SETTLEMENT_ERROR,
  PAYMENT_METHOD,
  MEDICAL_METHOD,
  MEDICAL_MENU,
  BILLING_CATEGORY_OPTION,
  PRESCRIPTION_DRUG_INPUT_METHOD,
} from "../../../Utils/const";

const Resources = japaneseList.Components.Appointment;
const ResourcesPayment = japaneseList.Components.Payment;

const memoMaxlength = 500;
const drugNameMaxLengthEWP = 15;
const drugNameMaxLength = 30;
const drugQuantityMaxLength = 20;
const medicalFeeMax = 9999999;

// style
// --------------------------------------------------
const styles = (theme) => ({
  buttonOk: {
    color: theme.button.ok.color,
    marginRight: "0px",
    backgroundColor: theme.button.ok.backgroundColor,
    width: "120px",
    height: "40px",
    boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    fontWeight: "bold",
  },
  buttonCancel: {
    backgroundColor: "gray",
    color: "white",
    width: "120px",
    marginRight: "20px",
    height: "40px",
    boxShadow: "0px 2px 0px #505050",
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
    fontWeight: "bold",
  },
  buttonDisable: {
    backgroundColor: "#eee",
    pointerEvents: "none",
    color: "white",
    "&:hover": {
      backgroundColor: "#eee",
    },
    width: "120px",
    height: "40px",
    fontWeight: "bold",
  },
  tableStyle: {
    borderCollapse: "collapse",
    width: "310px",
    tableLayout: "auto",
  },
  thBirthDay: {
    width: "98px",
  },
  thAge: {
    width: "26px",
  },
  thGender: {
    width: "48px",
  },
  thPhonenumber: {
    width: "120px",
  },
  thStyle: {
    textAlign: "center",
    borderTop: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    backgroundColor: "#eee",
    fontSize: "12px",
    color: "#808080",
  },
  trContent: {
    height: "33px",
  },
  tdStyle: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    color: "#393939",
    whiteSpace: "nowrap",
  },
  tdStyleGenderMale: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
  tdStyleGenderFemale: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
  containerTable: {
    display: "flex",
    boxSizing: "border-box",
    flexWrap: "nowrap",
    width: "100%",
    justifyContent: "space-between",
    marginTop: "8px",
  },
  buttonCancelApp: {
    backgroundColor: "#4164b5",
    color: "white",
    "&:hover": {
      backgroundColor: "#4164b5",
    },
  },
  containerBorder: {
    borderBottom: "#ccc solid 1px",
    borderRight: "#ccc solid 1px",
    borderLeft: "#ccc solid 1px",
    width: "100%",
    marginTop: "20px",
  },
  containerBorderConfirm: {
    borderBottom: "#ccc solid 1px",
    borderRight: "#ccc solid 1px",
    borderLeft: "#ccc solid 1px",
    width: "100%",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "80px auto",
    borderTop: "#ccc solid 1px",
  },
  titleItem: {
    backgroundColor: "#eee",
    padding: "0 10px",
    fontSize: "12px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    color: "#808080",
  },
  contentItem: {
    padding: "10px 20px",
    fontSize: "14px",
    minHeight: "41px",
    lineHeight: "21px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
  contentItemMemoInput: {
    padding: "10px 20px",
    fontSize: "14px",
  },
  inputContent: {
    height: "34px",
    fontSize: "14px",
    backgroundColor: theme.color.primaryVariant3,
    border: `1px solid #DDDDDD`,
    borderRadius: "3px",
  },
  textAreaMemo: {
    height: "64px",
    backgroundColor: theme.color.primaryVariant3,
    border: `1px solid #DDDDDD`,
    borderRadius: "3px",
  },
  maxHeightDialog: {
    maxHeight: "96%",
  },
  flexContainerAction: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: 0,
  },
  linkAStyle: {
    fontSize: "14px",
    color: "#2563C0",
    marginTop: "9px",
    marginBottom: "9px",
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "none",
    },
  },
  linkAStyleNormal: {
    fontSize: "14px",
    color: "#2563C0",
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "none",
    },
  },
  gridContentPatient: {
    marginTop: "20px",
  },
  gridAvatar: {
    marginRight: "12px",
  },
  textName: {
    width: "calc(100% - 102px);",
  },
  textGiveName: {
    fontSize: "16px",
    fontWeight: "bold",
    wordBreak: "break-all",
  },
  textGiveNameKana: {
    fontSize: "12px",
    wordBreak: "break-all",
  },
  textQuestion: {
    fontSize: "14px",
    marginTop: "25px",
    marginBottom: "15px",
  },
  textMedicalFeeNote: {
    marginLeft: "15px",
    fontSize: "11px",
    color: "#808080",
  },

  displayValidateError: {
    margin: "auto",
    color: "#EF5E5E",
    fontWeight: "bold",
  },

  hasError: {
    background: "#FFF1F2 0% 0% no-repeat padding-box",
  },

  selfFundedNote: {
    margin: "10px 0",
    color: "#EF5E5E",
    background: "#fff1f2",
    padding: "5px 10px",
    borderRadius: "3px",
    fontSize: "14px",
  },

  dialogContentText: {
    padding: "0px 24px 0px 0px",
    color: "#393939",
    whiteSpace: "pre-line",
  },
  dialogContentTitleText: {
    padding: "14px 24px 0px 0px",
    color: "#EF5E5E",
    fontWeight: "bold",
  },
});

// class define
// --------------------------------------------------
class PaymentDialog extends Component {
  // default state
  // --------------------------------------------------
  static defaultProps = {
    open: false,
    onClose: function () {},
    maxWidth: "md",
    handleClickOk: function () {},
    handleClickCancel: function () {},
  };

  // constructor
  // --------------------------------------------------
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      isOpenCancelDialogConfirm: false,
      checkMemoInput: true,
      checkMemoEmpty: true,
      checkMedicalFeeZero: true,
      checkMedicalFeeLength: true,
      checkDrugNameEmpty: true,
      checkDrugNameLength: true,
      checkDrugQuantityEmpty: true,
      checkDrugQuantityLength: true,
      checkDrugQuantityValid: true,
      confirmation: false,
      enableSubmitButton: false,
      medicalExpense: "",
      billingCategory: "",
      drugsName: "",
      drugsQuantity: "",
      isDrugNameInputDisabled: false,
      isDrugQuantitySelectDisabled: false,
      memo: "",
      isShowDrug: false,
      drugQuantityOptionList: [],
      isShowBillingCategoryNote: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.appointment.contractPlan) this.setDrugState();
    const { appointment } = this.props;
    const isBillingEnterprise =
      appointment.menu &&
      appointment.menu.paymentMethod === PAYMENT_METHOD.BILLING_ENTERPRISE;
    if (isBillingEnterprise) {
      this.setState({
        isShowDrug: true,
        isShowBillingCategoryNote: true,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setDrugState = () => {
    const { inputMethod, fixedDrugsName } =
      this.props.appointment.contractPlan.plan.prescriptionDrug;

    const contentState = {
      drugsName: "",
      drugsQuantity: "",
      isDrugNameInputDisabled: false,
      isDrugQuantitySelectDisabled: false,
    };
    switch (inputMethod) {
      case PRESCRIPTION_DRUG_INPUT_METHOD.FIXED:
        contentState.drugsName = fixedDrugsName;
        contentState.isDrugNameInputDisabled = true;
        break;
      case PRESCRIPTION_DRUG_INPUT_METHOD.NONE:
        contentState.drugsQuantity = "0";
        contentState.isDrugNameInputDisabled = true;
        contentState.isDrugQuantitySelectDisabled = true;
        break;
      default:
        break;
    }

    if (this._isMounted) this.setState({ ...contentState });
  };

  validateFormWhenKeyUp = () => {
    let validationResult = {
      checkMedicalFeeZero:
        (toHalfWidthNumbers(this.state.medicalExpense) || 0) !== 0,
      checkDrugNameEmpty:
        !this.props.appointment.requireDrugName ||
        (this.state.drugsName && this.state.drugsName.trim().length > 0),
      checkDrugQuantityEmpty:
        !this.props.appointment.requireDrugQuantity ||
        (this.state.drugsQuantity &&
          this.state.drugsQuantity.trim().length > 0),
    };
    const enableSubmitButton = !Object.values(validationResult).some(
      (x) => x === false
    );
    this.setState({
      enableSubmitButton,
    });
    return enableSubmitButton;
  };

  validateBillingEnterpriseFormWhenKeyUp = () => {
    const validationResult = {
      checkDrugQuantityEmpty: !this.state.isShowDrug
        ? "0"
        : this.state.drugsQuantity,
      checkBillingCategoryEmpty: this.state.billingCategory,
    };
    const enableSubmitButton = !Object.values(validationResult).some((x) => !x);
    this.setState({
      enableSubmitButton,
    });
    return enableSubmitButton;
  };

  validateForm = () => {
    const validationResult = {
      checkMemoInput:
        !this.state.memo || this.state.memo.trim().length <= memoMaxlength,
      checkMedicalFeeZero: (this.state.medicalExpense || 0) !== 0,
      checkMedicalFeeLength: this.state.medicalExpense <= medicalFeeMax,
      checkDrugNameLength:
        !this.props.appointment.requireDrugName ||
        (this.state.drugsName &&
          this.state.drugsName.trim().length <= drugNameMaxLength),
      checkDrugNameEmpty:
        !this.props.appointment.requireDrugName ||
        (this.state.drugsName && this.state.drugsName.trim().length > 0),
      checkDrugQuantityLength:
        !this.props.appointment.requireDrugQuantity ||
        (this.state.drugsQuantity &&
          this.state.drugsQuantity.trim().length <= drugQuantityMaxLength),
      checkDrugQuantityEmpty:
        !this.props.appointment.requireDrugQuantity ||
        (this.state.drugsQuantity &&
          this.state.drugsQuantity.trim().length > 0),
    };
    const enableSubmitButton = !Object.values(validationResult).some(
      (x) => x === false
    );
    this.setState({
      ...validationResult,
      enableSubmitButton,
    });
    return enableSubmitButton;
  };

  validateBillingEnterpriseForm = () => {
    const validationResult = {
      checkDrugQuantityValid:
        this.state.billingCategory === BILLING_CATEGORY_OPTION.CONSULTATION
          ? this.state.drugsQuantity === "0"
          : true,
      checkDrugNameEmpty:
        this.state.drugsQuantity > "0" ? this.state.drugsName : true,
      checkDrugNameLength:
        this.state.drugsName.trim().length <= drugNameMaxLengthEWP,
      checkMemoInput: this.state.memo.trim().length <= memoMaxlength,
    };
    const enableSubmitButton = !Object.values(validationResult).some((x) => !x);
    this.setState({
      ...validationResult,
      enableSubmitButton,
    });
    return enableSubmitButton;
  };

  validateAndSubmitForm = () => {
    if (
      this.props.appointment.menu.paymentMethod ===
      PAYMENT_METHOD.BILLING_ENTERPRISE
        ? this.validateBillingEnterpriseForm()
        : this.validateForm()
    ) {
      this.setState({
        confirmation: true,
      });
    }
  };

  handleCancelDialogConfirm = () => {
    this.setState({
      isOpenCancelDialogConfirm: false,
    });
  };

  handleChangeMedicalFee = (e) => {
    this.setState({ medicalExpense: e.target.value }, () =>
      this.validateFormWhenKeyUp()
    );
  };

  handleBlurMedicalFee = (value) => {
    this.setState({
      medicalExpense: value,
    });
  };

  handleChangeDrugName = (e) => {
    this.setState({ drugsName: e.target.value }, () =>
      this.props.appointment.menu.paymentMethod ===
      PAYMENT_METHOD.BILLING_ENTERPRISE
        ? this.validateBillingEnterpriseFormWhenKeyUp()
        : this.validateFormWhenKeyUp()
    );
  };

  handleChangeBillingCategory = (e) => {
    this.setState({ billingCategory: e.target.value }, () =>
      this.validateBillingEnterpriseFormWhenKeyUp()
    );
    this.setState({ drugsName: "", drugsQuantity: "" });
    const prescriptionDrug =
      this.props.appointment.contractPlan?.plan?.prescriptionDrug;
    prescriptionDrug &&
    prescriptionDrug.inputMethod === PRESCRIPTION_DRUG_INPUT_METHOD.FIXED
      ? this.setState({
          drugsName: prescriptionDrug.fixedDrugsName,
        })
      : prescriptionDrug &&
        prescriptionDrug.inputMethod === PRESCRIPTION_DRUG_INPUT_METHOD.NONE
      ? this.setState({ drugsName: "", drugsQuantity: "0" })
      : null;
    const settings = getBillingCategorySettings(e.target.value);
    this.setState(settings);
  };

  handleChangeDrugQuantity = (e) => {
    this.setState({ drugsQuantity: e.target.value }, () =>
      this.props.appointment.menu.paymentMethod ===
      PAYMENT_METHOD.BILLING_ENTERPRISE
        ? this.validateBillingEnterpriseFormWhenKeyUp()
        : this.validateFormWhenKeyUp()
    );
  };

  handleChangeMemo = (e) => {
    this.setState({ memo: e.target.value }, () =>
      this.props.appointment.menu.paymentMethod ===
      PAYMENT_METHOD.BILLING_ENTERPRISE
        ? this.validateBillingEnterpriseFormWhenKeyUp()
        : this.validateFormWhenKeyUp()
    );
  };

  handleClickButtonOK = async () => {
    await this.props.requestPayment({
      medicalExpense: this.state.medicalExpense,
      billingCategory: this.state.billingCategory,
      drugsName: this.state.drugsName,
      drugsQuantity: this.state.drugsQuantity,
      memo: this.state.memo,
    });
  };

  handleClose = async () => {
    this.props.handleClosePaymentDialog();
    this.props.loadAppointmentList();
  };

  handleDialogActions = (confirmation, payment, errorResource) => {
    const { classes } = this.props;
    //No error in ConfirmationDialog
    if (confirmation && !errorResource) {
      return (
        <div className={classes.flexContainerAction}>
          <LinkA
            className={classes.linkAStyle}
            onClick={() => {
              this.setState({
                confirmation: false,
              });
            }}
          >
            {Resources.StatusChangeDialog.DialogActions.backButton}
          </LinkA>
          <div>
            <Button
              className={classes.buttonCancel}
              onClick={() => {
                this.setState({
                  isOpenCancelDialogConfirm: true,
                });
              }}
            >
              {Resources.StatusChangeDialog.DialogActions.cancelButton}
            </Button>
            <Button
              className={classes.buttonOk}
              onClick={this.handleClickButtonOK}
            >
              {ResourcesPayment.MedicalFeeDialog.ButtonOk}
            </Button>
          </div>
        </div>
      );
    } else if (!errorResource) {
      return (
        <React.Fragment>
          <Button
            className={classes.buttonCancel}
            onClick={() => {
              this.setState({
                isOpenCancelDialogConfirm: true,
              });
            }}
          >
            {Resources.StatusChangeDialog.DialogActions.cancelButton}
          </Button>
          {this.state.enableSubmitButton ? (
            <Button
              className={classes.buttonOk}
              onClick={this.validateAndSubmitForm}
            >
              {Resources.StatusChangeDialog.DialogActions.confirmButton}
            </Button>
          ) : (
            <Button className={classes.buttonDisable}>
              {Resources.StatusChangeDialog.DialogActions.confirmButton}
            </Button>
          )}
        </React.Fragment>
      );
    } else {
      return (
        <Button
          className={classes.buttonCancel}
          onClick={(e) => this.handleClose()}
        >
          {Resources.StatusChangeDialog.DialogActions.confirmButtonDialogClose}
        </Button>
      );
    }
  };

  pathCheck = (errorResource) => {
    const documentPath = "/documents";
    const contactPath = "/contact";

    const documentErrorSet = [
      SETTLEMENT_ERROR.CREDIT_CARD,
      SETTLEMENT_ERROR.DELIVERY_SYSTEM,
      SETTLEMENT_ERROR.DELIVERY_PATIENT,
    ];
    const contactErrorSet = [
      SETTLEMENT_ERROR.CARD_SYSTEM,
      SETTLEMENT_ERROR.UNEXPECTED_SYSTEM,
      SETTLEMENT_ERROR.FAILED_CANCEL,
    ];
    if (Boolean(errorResource.code)) {
      const documentError = documentErrorSet.includes(errorResource.code);
      const contactError = contactErrorSet.includes(errorResource.code);
      if (documentError) {
        return documentPath;
      }
      if (contactError) {
        return contactPath;
      }
    }
  };
  paymentError = (errorResource) => {
    const { classes } = this.props;
    const approachPath = this.pathCheck(errorResource);
    return (
      <React.Fragment>
        <DialogContentText className={classes.dialogContentTitleText}>
          {errorResource.title}
        </DialogContentText>
        <DialogContentText className={classes.dialogContentText}>
          {errorResource.text}
        </DialogContentText>
        {approachPath && Boolean(errorResource.approachText) && (
          <LinkA
            className={classes.linkAStyleNormal}
            href={approachPath}
            target="_blank"
          >
            {errorResource.approachText}
          </LinkA>
        )}
      </React.Fragment>
    );
  };

  confirmationDisplay = () => {
    const { appointment, classes } = this.props;
    const { requireDrugName, requireDrugQuantity, doctor, menu } = appointment;
    const isBillingEnterprise =
      appointment.menu &&
      appointment.menu.paymentMethod === PAYMENT_METHOD.BILLING_ENTERPRISE;
    const isSelfFundedAppointment = appointment.contractPlan
      ? isNumber(appointment.contractPlan.selfFundedAmount)
        ? true
        : false
      : false;
    const isMedicationInstructionOnline =
      menu?.medicalMethod === MEDICAL_METHOD.ONLINE &&
      menu?.medicalMenu === MEDICAL_MENU.MEDICATION_INSTRUCTION;

    return (
      <React.Fragment>
        <p className={classes.textQuestion}>
          {ResourcesPayment.MedicalFeeDialog.QuestionConfirm}
        </p>
        {isSelfFundedAppointment && (
          <div className={classes.selfFundedNote}>
            {ResourcesPayment.MedicalFeeDialog.selfFundedNote}
          </div>
        )}
        <div className={classes.containerBorderConfirm}>
          <div className={classes.container}>
            <div className={classes.titleItem}>
              {
                Resources.StatusChangeDialog.DialogContent.containerBorder
                  .undertaker
              }
            </div>
            <div className={classes.contentItem}>
              {menu && menu.medicalMethod === MEDICAL_METHOD.OFFLINE
                ? Resources.StatusChangeDialog.DialogContent.containerBorder
                    .offline
                : `${doctor.familyName} ${doctor.givenName}`}
            </div>
          </div>
          {!isBillingEnterprise && (
            <React.Fragment>
              <div className={classes.container}>
                <div className={classes.titleItem}>
                  {
                    Resources.StatusChangeDialog.DialogContent.containerBorder
                      .medicalFee
                  }
                </div>
                <div className={classes.contentItem}>
                  <span>
                    {this.state.medicalExpense
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                      Resources.StatusChangeDialog.DialogContent.containerBorder
                        .medicalFeeUnit}
                  </span>
                  <span className={classes.textMedicalFeeNote}>
                    {isMedicationInstructionOnline
                      ? ResourcesPayment.MedicalFeeDialog.MedicalFeeNoteConfirm
                          .medicationInstruction
                      : ResourcesPayment.MedicalFeeDialog.MedicalFeeNoteConfirm
                          .normalMenu}
                  </span>
                </div>
              </div>
              {requireDrugName && (
                <div className={classes.container}>
                  <div className={classes.titleItem}>
                    {
                      Resources.StatusChangeDialog.DialogContent.containerBorder
                        .drugName
                    }
                  </div>
                  <div className={classes.contentItem}>
                    {this.state.drugsName && this.state.drugsName.trim()}
                  </div>
                </div>
              )}
              {requireDrugQuantity && (
                <div className={classes.container}>
                  <div className={classes.titleItem}>
                    {
                      Resources.StatusChangeDialog.DialogContent.containerBorder
                        .drugQuantity
                    }
                  </div>
                  <div className={classes.contentItem}>
                    {this.state.drugsQuantity &&
                      this.state.drugsQuantity.trim()}
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
          {isBillingEnterprise && (
            <React.Fragment>
              <div className={classes.container}>
                <div className={classes.titleItem}>
                  {
                    Resources.StatusChangeDialog.DialogContent.containerBorder
                      .billingCategory
                  }
                </div>
                <div className={classes.contentItem}>
                  {
                    Resources.StatusChangeDialog.DialogContent.containerBorder
                      .billingCategoryOption[this.state.billingCategory]
                  }
                </div>
              </div>
              <div className={classes.container}>
                <div className={classes.titleItem}>
                  {
                    Resources.StatusChangeDialog.DialogContent.containerBorder
                      .drugNameEWP
                  }
                </div>
                <div className={classes.contentItem}>
                  {this.state.drugsName && this.state.drugsName.trim()}
                </div>
              </div>
              <div className={classes.container}>
                <div className={classes.titleItem}>
                  {
                    Resources.StatusChangeDialog.DialogContent.containerBorder
                      .drugQuantity
                  }
                </div>
                <div className={classes.contentItem}>
                  {this.state.drugsQuantity && this.state.drugsQuantity.trim()}
                </div>
              </div>
            </React.Fragment>
          )}
          <div className={classes.container}>
            <div className={classes.titleItem}>
              {Resources.StatusChangeDialog.DialogContent.containerBorder.memo}
            </div>
            <div className={classes.contentItem}>
              {this.state.memo && this.state.memo.trim()}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  // render
  // --------------------------------------------------
  render() {
    const { appointment, classes, open, errorResource } = this.props;
    const { confirmation } = this.state;
    const {
      patient,
      requireDrugName,
      requireDrugQuantity,
      payment,
      doctor,
      menu,
    } = appointment;
    const isBillingEnterprise =
      appointment.menu &&
      appointment.menu.paymentMethod === PAYMENT_METHOD.BILLING_ENTERPRISE;
    const isMedicationInstructionOnline =
      menu?.medicalMethod === MEDICAL_METHOD.ONLINE &&
      menu?.medicalMenu === MEDICAL_MENU.MEDICATION_INSTRUCTION;
    return (
      <React.Fragment>
        <DialogMedical
          open={open}
          handleClickCancel={this.props.handleClickCancel}
          dialogActions={this.handleDialogActions(
            confirmation,
            payment,
            errorResource
          )}
          title={
            isBillingEnterprise
              ? ResourcesPayment.MedicalFeeDialog.DialogBillingEnterpriseTitle
              : ResourcesPayment.MedicalFeeDialog.DialogTitle
          }
          handleClose={(e) => this.handleClose()}
        >
          {patient && (
            <PatientInfo
              patient={patient}
              enterpriseName={appointment.enterpriseName}
            />
          )}
          {errorResource && this.paymentError(errorResource)}
          {confirmation ? (
            !errorResource && this.confirmationDisplay()
          ) : (
            <div className={classes.containerBorder}>
              <div className={classes.container}>
                <div className={classes.titleItem}>
                  {
                    Resources.StatusChangeDialog.DialogContent.containerBorder
                      .undertaker
                  }
                </div>
                <div className={classes.contentItem}>
                  {menu && menu.medicalMethod === MEDICAL_METHOD.OFFLINE
                    ? Resources.StatusChangeDialog.DialogContent.containerBorder
                        .offline
                    : `${doctor.familyName} ${doctor.givenName}`}
                </div>
              </div>
              {!isBillingEnterprise && (
                <React.Fragment>
                  <MedicalFeeInput
                    title={
                      Resources.StatusChangeDialog.DialogContent.containerBorder
                        .medicalFee
                    }
                    isValidateError={
                      !(
                        this.state.checkMedicalFeeZero &&
                        this.state.checkMedicalFeeLength
                      )
                    }
                    errMessage={
                      !this.state.checkMedicalFeeLength
                        ? Resources.StatusChangeDialog.DialogContent
                            .containerBorder.medicalFeeLengthErrorMessage
                        : Resources.StatusChangeDialog.DialogContent
                            .containerBorder.medicalFeeZeroErrorMessage
                    }
                    medicalFeeValue={this.state.medicalExpense}
                    handleChangeMedicalFee={this.handleChangeMedicalFee}
                    handleBlurMedicalFee={this.handleBlurMedicalFee}
                    hasErrorClass={
                      !(
                        this.state.checkMedicalFeeZero &&
                        this.state.checkMedicalFeeLength
                      )
                        ? classes.inputContent + " " + classes.hasError
                        : classes.inputContent
                    }
                    medicalFeeUnit={
                      Resources.StatusChangeDialog.DialogContent.containerBorder
                        .medicalFeeUnit
                    }
                    medicalFeeNote={
                      isMedicationInstructionOnline
                        ? ResourcesPayment.MedicalFeeDialog.MedicalFeeNoteInput
                            .medicationInstruction
                        : ResourcesPayment.MedicalFeeDialog.MedicalFeeNoteInput
                            .normalMenu
                    }
                  />
                  {requireDrugName && (
                    <DrugInput
                      title={
                        Resources.StatusChangeDialog.DialogContent
                          .containerBorder.drugName
                      }
                      isValidateError={
                        !(
                          this.state.checkDrugNameLength &&
                          this.state.checkDrugNameEmpty
                        )
                      }
                      errMessage={
                        !this.state.checkDrugNameLength
                          ? Resources.StatusChangeDialog.DialogContent
                              .containerBorder.drugNameMaxLengthErrorMessage
                          : Resources.StatusChangeDialog.DialogContent
                              .containerBorder.drugNameEmptyErrorMessage
                      }
                      drugInputValue={this.state.drugsName}
                      drugInputPlaceholder={
                        ResourcesPayment.MedicalFeeDialog.PlaceHolderDrugsName
                      }
                      handleChangeDrugInput={this.handleChangeDrugName}
                      hasErrorClass={
                        !(
                          this.state.checkDrugNameLength &&
                          this.state.checkDrugNameEmpty
                        )
                          ? classes.inputContent + " " + classes.hasError
                          : classes.inputContent
                      }
                    />
                  )}
                  {requireDrugQuantity && (
                    <DrugInput
                      title={
                        Resources.StatusChangeDialog.DialogContent
                          .containerBorder.drugQuantity
                      }
                      isValidateError={
                        !(
                          this.state.checkDrugQuantityLength &&
                          this.state.checkDrugQuantityEmpty
                        )
                      }
                      errMessage={
                        !this.state.checkDrugQuantityLength
                          ? Resources.StatusChangeDialog.DialogContent
                              .containerBorder.drugQuantityMaxLengthErrorMessage
                          : Resources.StatusChangeDialog.DialogContent
                              .containerBorder.drugQuantityEmptyErrorMessage
                      }
                      drugInputValue={this.state.drugsQuantity}
                      drugInputPlaceholder={
                        ResourcesPayment.MedicalFeeDialog
                          .PlaceHolderDrugsQuantity
                      }
                      handleChangeDrugInput={this.handleChangeDrugQuantity}
                      hasErrorClass={
                        !(
                          this.state.checkDrugQuantityLength &&
                          this.state.checkDrugQuantityEmpty
                        )
                          ? classes.inputContent + " " + classes.hasError
                          : classes.inputContent
                      }
                    />
                  )}
                  <MemoTextarea
                    title={
                      Resources.StatusChangeDialog.DialogContent.containerBorder
                        .memo
                    }
                    checkMemoInput={this.state.checkMemoInput}
                    memoErrMessage={
                      Resources.StatusChangeDialog.DialogContent.containerBorder
                        .memoErrMessage
                    }
                    memoValue={this.state.memo}
                    placeholder={
                      ResourcesPayment.MedicalFeeDialog.PlaceHolderMemo
                    }
                    handleChangeMemo={this.handleChangeMemo}
                    hasErrorClass={
                      !this.state.checkMemoInput
                        ? classes.textAreaMemo + " " + classes.hasError
                        : classes.textAreaMemo
                    }
                  />
                </React.Fragment>
              )}
              {isBillingEnterprise && (
                <React.Fragment>
                  <BillingCategorySelect
                    title={
                      Resources.StatusChangeDialog.DialogContent.containerBorder
                        .billingCategory
                    }
                    billingCategoryValue={this.state.billingCategory}
                    handleChangeBillingCategory={
                      this.handleChangeBillingCategory
                    }
                    isShowNote={this.state.isShowBillingCategoryNote}
                  />
                  {this.state.isShowDrug && (
                    <DrugNameInput
                      title={
                        Resources.StatusChangeDialog.DialogContent
                          .containerBorder.drugNameEWP
                      }
                      isValidateError={
                        !(
                          this.state.checkDrugNameLength &&
                          this.state.checkDrugNameEmpty
                        )
                      }
                      errMessage={
                        !this.state.checkDrugNameEmpty
                          ? Resources.StatusChangeDialog.DialogContent
                              .containerBorder.drugNameEmptyErrorMessageEWP
                          : Resources.StatusChangeDialog.DialogContent
                              .containerBorder.drugNameMaxLengthErrorMessageEWP
                      }
                      drugsNameValue={this.state.drugsName}
                      inputMethod={
                        appointment.contractPlan.plan.prescriptionDrug
                          .inputMethod
                      }
                      selectableDrugNames={
                        appointment.contractPlan.plan.prescriptionDrug
                          .selectableDrugNames
                      }
                      disabled={this.state.isDrugNameInputDisabled}
                      handleChangeDrugName={this.handleChangeDrugName}
                    />
                  )}
                  {this.state.isShowDrug && (
                    <DrugQuantitySelect
                      title={
                        Resources.StatusChangeDialog.DialogContent
                          .containerBorder.drugQuantity
                      }
                      drugsQuantityValue={this.state.drugsQuantity}
                      handleChangeDrugQuantity={this.handleChangeDrugQuantity}
                      disabled={this.state.isDrugQuantitySelectDisabled}
                      isValidateError={!this.state.checkDrugQuantityValid}
                      errMessage={
                        Resources.StatusChangeDialog.DialogContent
                          .containerBorder.drugQuantityValidErrorMessageEWP
                      }
                      note={
                        Resources.StatusChangeDialog.DialogContent
                          .containerBorder.drugQuantityNote
                      }
                      drugQuantityOptionList={this.state.drugQuantityOptionList}
                    />
                  )}
                  <MemoTextarea
                    title={
                      Resources.StatusChangeDialog.DialogContent.containerBorder
                        .memo
                    }
                    checkMemoInput={this.state.checkMemoInput}
                    memoErrMessage={
                      Resources.StatusChangeDialog.DialogContent.containerBorder
                        .memoErrMessage
                    }
                    memoValue={this.state.memo}
                    placeholder={
                      ResourcesPayment.MedicalFeeDialog
                        .BillingEnterprisePlaceHolderMemo
                    }
                    handleChangeMemo={this.handleChangeMemo}
                    hasErrorClass={
                      !this.state.checkMemoInput
                        ? classes.textAreaMemo + " " + classes.hasError
                        : classes.textAreaMemo
                    }
                  />
                </React.Fragment>
              )}
            </div>
          )}
        </DialogMedical>
        <DialogCancelConfirm
          open={this.state.isOpenCancelDialogConfirm}
          handleCancelDialogConfirm={this.handleCancelDialogConfirm}
          handleCloseDialogConfirm={this.props.handleClosePaymentDialog}
          title={Resources.AppointmentList.DialogConfirm.title}
        >
          {Resources.AppointmentList.DialogConfirm.content}
        </DialogCancelConfirm>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(PaymentDialog);
