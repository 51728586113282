import React from "react";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";

const Resource = japaneseList.Components.Payment.HospitalPaymentList.HospitalPaymentRowName;

const TblCellName = styled.td`
  text-align: left;
  padding: 10px;
  border-left: 1px solid #cccccc;
  white-space: pre-wrap;
  word-break: break-word;
  width: 170px;
`;

const ClickArea = styled.div`
  display: inline-block;
  cursor: pointer;
  color: #2563c0;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

const NameKana = styled.div`
  font-size: 10px;
`;

const EnterpriseName = styled.div`
  font-size: 10px;
  color: #393939;
  word-break: break-word;
`;

const HospitalPaymentRowName = (props) => {
  const { appo, openPatientDetailWindow } = props;

  return (
    <TblCellName>
      <ClickArea
        onClick={() => openPatientDetailWindow(appo.patient.patientId)}
      >
        {appo.patient.familyName} {appo.patient.givenName}
        <NameKana>
          {appo.patient.familyNameKana} {appo.patient.givenNameKana}
        </NameKana>
      </ClickArea>
      {appo.enterpriseName && (
        <EnterpriseName>
          {`(${Resource.EnterpriseName}${appo.enterpriseName})`}
        </EnterpriseName>
      )}
    </TblCellName>
  );
};

export default HospitalPaymentRowName;
