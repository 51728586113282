// required　modules
// --------------------------------------------------
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { prefectureCodes } from "../../../../Utils/PrefectureCode";
import Button from "@material-ui/core/Button";
import DialogMedical from "../../../molecules/dialogs/DialogMedical";
import Radio from "../../../atoms/forms/Radio";
import {
  getDayTextJaFormatShort,
} from "../../../../Utils/DisplayLogic";

// personal modules
// --------------------------------------------------
import PatientInfo from "../../dialog/PatientInfo";
import { japaneseList } from "../../../../Resources/japaneseList";
import {
  DELIVERY_OPTION,
  MEDICAL_METHOD,
  PAYMENT_METHOD,
} from "../../../../Utils/const";
import { deliveryFeeCalculator } from "../../../../Utils/DeliveryFeeCalculator";
import CSVPostDeliveryFeeContent from "./CSVPostDeliveryFeeContent";

const Resources = japaneseList.Components.Payment.CSVPostDeliveryDialog;
const DeliveryProductName = japaneseList.models.appointment.deliveryProductName;

const FEATURE = {
  medicine: "medicine",
  prescription: "prescription",
};

// style
// --------------------------------------------------
const styles = (theme) => ({
  buttonOk: {
    color: theme.button.ok.color,
    marginRight: "0px",
    width: "120px",
    height: "40px",
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
    borderRadius: "6px",
    opacity: "1",
    margin: "0px",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    fontWeight: "bold",
  },
  buttonCancel: {
    backgroundColor: "gray",
    color: "white",
    width: "120px",
    height: "40px",
    background: "#808080 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 0px #505050",
    borderRadius: "6px",
    opacity: "1",
    margin: "0px 20px 0px 0px",
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
    fontWeight: "bold",
  },
  buttonDisable: {
    backgroundColor: "#eee",
    pointerEvents: "none",
    color: "white",
    "&:hover": {
      backgroundColor: "#eee",
    },
    width: "120px",
    height: "40px",
    fontWeight: "bold",
  },
  tableStyle: {
    borderCollapse: "collapse",
    width: "310px",
    tableLayout: "auto",
  },
  thBirthDay: {
    width: "98px",
  },
  thAge: {
    width: "26px",
  },
  thGender: {
    width: "48px",
  },
  thPhonenumber: {
    width: "120px",
  },
  thStyle: {
    textAlign: "center",
    borderTop: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    backgroundColor: "#eee",
    fontSize: "12px",
    color: "#808080",
  },
  tdStyle: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    color: "#393939",
    whiteSpace: "nowrap",
  },
  tdStyleGenderMale: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
  tdStyleGenderFemale: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
  containerTable: {
    display: "flex",
    boxSizing: "border-box",
    flexWrap: "nowrap",
    width: "100%",
    justifyContent: "space-between",
    marginTop: "8px",
  },
  buttonCancelApp: {
    backgroundColor: "#4164b5",
    color: "white",
    "&:hover": {
      backgroundColor: "#4164b5",
    },
  },
  containerBorder: {
    borderBottom: "#ccc solid 1px",
    borderRight: "#ccc solid 1px",
    borderLeft: "#ccc solid 1px",
    width: "100%",
    marginTop: "20px",
  },
  containerBorderConfirm: {
    borderBottom: "#ccc solid 1px",
    borderRight: "#ccc solid 1px",
    borderLeft: "#ccc solid 1px",
    width: "100%",
    marginTop: "15px",
  },
  containerBorderView: {
    borderBottom: "#ccc solid 1px",
    borderRight: "#ccc solid 1px",
    borderLeft: "#ccc solid 1px",
    width: "100%",
    marginTop: "20px",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "80px auto",
    gridGap: "10px",
    borderTop: "#ccc solid 1px",
  },
  titleItem: {
    backgroundColor: "#eee",
    padding: "10px",
    fontSize: "12px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    color: "#808080",
  },
  contentItem: {
    padding: "10px",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },

  contentItemSubGroup: {
    display: "flex",
    padding: "15px 10px",
    alignItems: "flex-start",
    gap: "10px",
  },
  contentFeeBlock: {
    display: "flex",
    minWidth: "42px",
    height: "21px",
    paddingRight: "0px",
    justifyContent: "left",
    alignItems: "flex-end",
    gap: "4px",
  },
  contentFeeItem: {
    textAlign: "right",
    fontSize: "12px",
  },
  contentDescriptionItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#808080",
    fontSize: "11px",
    lineHeight: "normal",
    whiteSpace: "pre-wrap",
    alignSelf: "center",
  },

  noteContainer: {
    paddingTop: "20px",
    display: "grid",
    gridTemplateColumns: "15px auto",
  },
  noteTittle: {
    fontSize: "14px",
    gridColumn: "1 / span 2",
  },
  noteContent: {
    fontSize: "11px",
    lineHeight: "20px",
    color: "#aaaaaa",
  },
  actionDiv: {
    display: "grid",
    gridTemplateColumns: "50px auto",
    width: "100%",
  },
  buttonDiv: {
    display: "flex",
    justifyContent: "flex-end",
  },
  linkDiv: {
    display: "flex",
    alignItems: "center",
  },
  wrapText: {
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
  radioStyle: {
    verticalAlign: "sub",
    width: "20px",
    height: "20px",
    background: "#EEEEEE 0% 0% no-repeat padding-box",
    backdropFilter: "blur(10px)",
    opacity: 1,
    webkitBackdropFilter: "blur(10px)",
    boxShadow: "0px 0px 0px #CCC",
    "&:checked": {
      border: `2px solid ${theme.color.borderRadio}`,
    },
    border: "2px solid #CCCCCC",
  },
  radioStyleCheck: {
    verticalAlign: "sub",
    width: "20px",
    height: "20px",
    background: "#EEEEEE 0% 0% no-repeat padding-box",
    backdropFilter: "blur(10px)",
    opacity: 1,
    webkitBackdropFilter: "blur(10px)",
    "&:checked": {
      border: `2px solid ${theme.color.borderRadio}`,
    },
  },
});

const SpanCustom = styled.span`
  padding-right: 20px;
  margin-left: 5px;
  font-size: 14px;
`;

// class define
// --------------------------------------------------
class CSVPostDeliveryDialog extends Component {
  // constructor
  // --------------------------------------------------
  constructor(props) {
    super(props);
    this.state = { feature: null };
  }

  handledialogActions = (appo) => {
    const { classes } = this.props;
    return (
      <div className={classes.actionDiv} style={{ margin: 0 }}>
        <div className={classes.linkDiv}></div>
        <div className={classes.buttonDiv}>
          <Button
            className={classes.buttonCancel}
            onClick={this.props.handleCancel}
            style={{ marginRight: "20px" }}
          >
            {Resources.buttonCancel}
          </Button>

          <Button
            className={
              this.state.feature || appo.deliveryProductName
                ? classes.buttonOk
                : classes.buttonDisable
            }
            onClick={this.props.handleConfirm}
          >
            {Resources.buttonOk.j001}
          </Button>
        </div>
      </div>
    );
  };

  handleSelect = (e) => {
    this.setState({ feature: e.target.value });
    this.props.handleChangeDeliveryState({ name: e.target.value });
  };

  // render
  // --------------------------------------------------
  render() {
    const { appointment, classes, open } = this.props;
    const { feature } = this.state;
    const patient = appointment.patient;
    const doctor = appointment.doctor;
    const menu = appointment.menu;
    const address = patient && (patient.address || {});
    const prefecture = address.prefectureCode
      ? prefectureCodes.find((c) => address.prefectureCode === c.code).jpName ||
        ""
      : "";
    const postalCode =
      address && address.postalCode
        ? address.postalCode.slice(0, 3) + "-" + address.postalCode.slice(3)
        : "";

    const displayDeliveryFee = deliveryFeeCalculator(
      appointment,
      DELIVERY_OPTION.csvPost
    );

    const isBillingEnterprise =
      appointment.menu &&
      appointment.menu.paymentMethod === PAYMENT_METHOD.BILLING_ENTERPRISE;

    return (
      <DialogMedical
        open={open}
        handleClickCancel={() => {
          this.setState({ isOpenMedicalFee: false });
        }}
        appointment={appointment}
        dialogActions={this.handledialogActions(appointment)}
        title={Resources.DialogTitle.create}
      >
        <div className={classes.noteContainer}>
          <div className={classes.noteTittle}>{Resources.note.title}</div>
          <div className={classes.noteContent}>{Resources.note.symbol}</div>
          <div className={classes.noteContent}>
            {Resources.note.content.j001}
            {Resources.note.content.j002}
          </div>
          <div></div>
          <div className={classes.noteContent}>
            {Resources.note.content.j003}
          </div>
        </div>
        {patient && (
          <PatientInfo
            patient={patient}
            enterpriseName={appointment.enterpriseName}
          />
        )}
        <div className={classes.containerBorder}>
          <div className={classes.container}>
            <div className={classes.titleItem}>
              {Resources.information.undertaker}
            </div>
            <div className={classes.contentItem}>
              {menu && menu.medicalMethod === MEDICAL_METHOD.OFFLINE
                ? Resources.information.offline
                : `${doctor.familyName} ${doctor.givenName}`}
            </div>
          </div>
          <div className={classes.container}>
            <div className={classes.titleItem}>
              {Resources.information.treatmentDay}
            </div>
            <div className={classes.contentItem}>
              {appointment.from
                ? getDayTextJaFormatShort(appointment.from)
                : ""}
            </div>
          </div>
          <div className={classes.container}>
            <div className={classes.titleItem}>
              {Resources.information.address}
            </div>
            {address.prefectureCode ? (
              <div className={classes.contentItem}>
                {Resources.information.addressSymbol}
                {postalCode}
                <br />
                {`${prefecture} ${address.addressCity} ${
                  address.addressStreetNumber
                } ${address.addressBuilding || ""}`}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={classes.container}>
            <div className={classes.titleItem}>
              {Resources.information.patientName}
            </div>
            <div className={classes.contentItem}>
              {patient.familyName} {patient.givenName}
            </div>
          </div>
          {!isBillingEnterprise &&
            displayDeliveryFee &&
            displayDeliveryFee.deliveryFee &&
            displayDeliveryFee.deliveryArrangementFee && (
              <CSVPostDeliveryFeeContent
                deliveryFee={displayDeliveryFee.deliveryFee}
                deliveryArrangementFee={
                  displayDeliveryFee.deliveryArrangementFee
                }
              />
            )}
          <div className={classes.container}>
            <div className={classes.titleItem}>
              {Resources.information.contentSelect}
            </div>
            <div className={classes.contentItem}>
              <Radio
                className={
                  feature === FEATURE.medicine
                    ? classes.radioStyleCheck
                    : classes.radioStyle
                }
                name="feature"
                value={FEATURE.medicine}
                onClick={this.handleSelect}
                onChange={() => {}}
                checked={feature === FEATURE.medicine}
              />
              <SpanCustom>{DeliveryProductName.medicine}</SpanCustom>
              <Radio
                className={
                  feature === FEATURE.prescription
                    ? classes.radioStyleCheck
                    : classes.radioStyle
                }
                name="feature"
                value={FEATURE.prescription}
                onClick={this.handleSelect}
                onChange={() => {}}
                checked={feature === FEATURE.prescription}
              />
              <SpanCustom>{DeliveryProductName.prescription}</SpanCustom>
            </div>
          </div>
        </div>
      </DialogMedical>
    );
  }
}

export default withStyles(styles)(CSVPostDeliveryDialog);
