import gql from "graphql-tag";

export const GetAppointment = gql`
  query GetAppointment(
    $patientId: ID!
    $createTime: ID!
    $medicalDoctorId: String
  ) {
    appointment(
      patientId: $patientId
      createTime: $createTime
      doctorId: $medicalDoctorId
    ) {
      hospitalId
      createTime
      from
      to
      status
      patient {
        patientId
        familyName
        givenName
        familyNameKana
        givenNameKana
        gender
        birthdate
        phoneNumber
        cardStatus
        insuranceCard {
          fileName
          fileType
          filePath
          updatedTime
        }
        address {
          postalCode
          prefectureCode
          addressCity
          addressStreetNumber
          addressBuilding
        }
      }
      menu {
        supportsInsuranceTreatment
        medicalMenu
        medicalMethod
        canStartCallBeforeMinutes
        supportPaymentByContractCompany
        paymentMethod
      }
      companyName
      content
      interviewSheetAnswerStatus
      payment {
        paymentTime
        status
        deliveryStatus
        medicalExpense
        gmoCode
        gmoInfo
        errorCode
        selfFundedFee
      }
      attachment {
        fileName
        fileType
        filePath
        status
        expiredTime
        updatedTime
      }
      patientAttachments {
        fileName
        fileType
        filePath
        thumbnailFilePath
        uploadedTime
        updatedTime
      }
      medicineDelivery {
        accountStatus
        deliveryMethod
        deliveryHospitalId
        name
        prefectureCode
        addressBuilding
        addressCity
        addressStreetNumber
        tel
        fax
        postalCode
      }
      memo
      drugsName
      drugsQuantity
      billingCategory
      contractPlan {
        contractPlanId
        contractPlanName
        selfFundedAmount
        plan {
          planId
          planName
          displayTagName
          prescriptionDrug {
            inputMethod
            fixedDrugsName
            selectableDrugNames
          }
        }
      }
      requireMedicalExpense
      requireDrugName
      requireDrugQuantity
      enterpriseName
    }
  }
`;
