import React, { Component } from "react";
import { Button, Typography } from "@material-ui/core";
import { DialogActions, DialogContent, DialogTitle } from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import {
  format,
  differenceInMinutes,
  differenceInCalendarDays,
  addMinutes,
  parseISO,
  isAfter,
} from "date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import jaLocale from "date-fns/locale/ja";

import TimePicker from "./Stepper/TimePicker";
import { client, UpdateAppointment } from "../../../graphql";
import { endMonthOfNextYear } from "../../../Utils/DateUtil";
import { japaneseList } from "../../../Resources/japaneseList";
import styled from "styled-components";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { NativeSelect } from "@material-ui/core/";
import PatientInfo from "../dialog/PatientInfo";
import ja from "date-fns/locale/ja";
import AccessTime from "@material-ui/icons/AccessTime";
import DateRange from "@material-ui/icons/DateRange";
import { LoadingScreenForDialog } from "../../molecules/others/LoadingScreenForDialog";

const AppointmentUpdateResource =
  japaneseList.Components.Appointment.AppointmentUpdate;

const styles = (theme) => ({
  dialogTitle: {
    width: "810px",
    height: "68px",
    margin: "0",
    padding: "24px 0 24px 24px",
    borderBottom: "1px solid #cccccc",
  },
  dialogContent: {
    width: "810px",
    margin: "0",
    padding: "0 25px 20px 24px",
  },
  dialogActions: {
    width: "810px",
    height: "70px",
    margin: "0",
    padding: "14px 25px 16px 24px",
    borderTop: "1px solid #cccccc",
    "& button": {
      height: "40px",
    },
    "& span": {
      fontSize: "14px",
      fontWeight: "bold",
      color: "#ffffff",
    },
  },
  buttonOk: {
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
  },
  dialogActionsButtonOk: {
    width: "120px",
    margin: "0",
    padding: "13px 22px",
    borderRadius: "5px",
  },
  buttonCancel: {
    backgroundColor: "#808080",
    boxShadow: `0px 2px 0px ${theme.button.cancel.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
  },
  dialogActionsButtonCancel: {
    width: "120px",
    margin: "0 20px 0 0",
    padding: "13px 25px",
    borderRadius: "5px",
  },
  doctor: {
    height: "35px",
  },
  input: {
    width: "194px",
    height: "34px",
    lineHeight: "34px",
    alignItems: "center",
    verticalAlign: "middle",
    padding: "10px 0px 10px 12px",
    fontSize: "14px",
    border: "1px solid #dddddd",
    background: `${theme.color.primaryVariant3} 0% 0% no-repeat padding-box`,
    borderRadius: "3px 0px 0px 3px",
    textAlign: "left",
    color: "#393939",
    cursor: "pointer",
    "&:focus": {
      outline: "none",
    },
  },
  iconButton: {
    padding: 0,
    backgroundColor: theme.button.ok.backgroundColor,
    maxWidth: "34px",
    maxHeight: "33px",
    minWidth: "34px",
    minHeight: "33px",
    marginLeft: "-34px",
    marginTop: "-1px",
    borderRadius: "0 3px 3px 0",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    boxShadow: `0px 1px 0px ${theme.button.ok.shadowColor}`,
  },
});

const DialogTitleText = styled.div`
  height: 20px;
  line-height: 20px;
  font-size: 20px;
  font-weight: bold;
`;

const PatientArea = styled.div`
  margin: 0;
`;

const AppointmentArea = styled.div`
  margin-top: 20px;
`;

const Container = styled.div`
  border-bottom: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
`;

const Item = styled.div`
  display: grid;
  grid-template-columns: 68px auto;
  border-top: 1px solid #cccccc;
  height: 55px;
`;

const Title = styled.div`
  background-color: #eeeeee;
  padding-left: 10px;
  color: #808080;
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
`;

const Content = styled.div`
  padding-left: 20px;
  color: #393939;
  text-align: left;
  font-size: 14px;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  word-break: break-word;
`;

const CustomNativeSelect = styled(NativeSelect)`
  width: 194px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  background: 0% 0% no-repeat padding-box
    ${(p) => p.theme.color.primaryVariant3};
  select {
    width: calc(100% - 10px);
    padding: 0 0 0 10px;
    height: 34px;
    font-size: 14px;
    &:focus {
      background: 0% 0% no-repeat padding-box
        ${(p) => p.theme.color.primaryVariant3};
    }
  }
  &:hover svg {
    background-color: ${(p) => p.theme.color.primaryVariant};
  }
`;

const ArrowDropDownIcon = withStyles((theme) => ({
  root: {
    backgroundColor: theme.button.ok.backgroundColor,
    width: "34px",
    height: "33px",
    color: "#ffffff",
    background: "#27cdcb 0% 0% no-repeat padding-box",
    boxShadow: `0px 1px 0px ${theme.color.shadow}`,
    borderRadius: "0px 3px 3px 0px",
    marginTop: "-5px",
    left: "159px",
  },
}))(ArrowDropDown);

class AppointmentUpdate extends Component {
  constructor(props) {
    super(props);
    const { appointment } = props;
    const delta = format(parseISO(appointment.to), "HH:mm") === "23:59" ? 1 : 0;
    this.state = {
      selectedDate: format(parseISO(appointment.from), "yyyy-MM-dd"),
      start: format(parseISO(appointment.from), "HH:mm"),
      end: format(parseISO(appointment.to), "HH:mm"),
      time:
        differenceInMinutes(
          parseISO(appointment.to),
          parseISO(appointment.from)
        ) + delta,
      error: null,
      isOpenStartTimePicker: false,
      isOpenEndTimePicker: false,
      disable: true,
      isLoading: false,
    };
  }

  openPicker = () => {
    this.picker.open();
  };

  handleDateChange = (date) => {
    if (this.state.selectedDate === format(date, "yyyy-MM-dd")) return false;
    this.setState({ selectedDate: format(date, "yyyy-MM-dd") });
    this.afterChangedSchedule();
  };

  formatterSelectedDate = (date) => {
    return format(parseISO(date), "yyyy/MM/dd(iii)", { locale: ja });
  };

  handleStartTimeChange = (start) => {
    this.handleCloseStartTimePicker();
    if (this.state.start === start) return false;
    this.calculateEndTime(this.state.time, start);
    this.afterChangedSchedule();
  };

  handleEndTimeChange = (end) => {
    this.handleCloseEndTimePicker();
    if (this.state.end === end) return false;
    const endTime = parseISO(`${this.state.selectedDate}T${end}:00`);
    const startTime = addMinutes(endTime, -this.state.time);
    if (differenceInCalendarDays(startTime, endTime) !== 0) {
      this.setState({
        end,
        start: format(startTime, "HH:mm"),
        error: AppointmentUpdateResource.handleEndTimeChange.j001,
      });
    } else {
      this.setState({
        end,
        start: format(startTime, "HH:mm"),
        error: null,
      });
    }
    this.afterChangedSchedule();
  };

  handleTimeChange = (e) => {
    if (this.state.time === e.target.value) return false;
    this.calculateEndTime(e.target.value);
  };

  calculateEndTime = (time, start = this.state.start) => {
    const startTime = parseISO(`${this.state.selectedDate}T${start}:00`);
    const endTime = addMinutes(startTime, time);
    if (differenceInCalendarDays(startTime, endTime) !== 0) {
      this.setState({
        end: format(endTime, "HH:mm"),
        time: time,
        start: start,
        error: AppointmentUpdateResource.calculateEndTime.j001,
      });
    } else {
      this.setState({
        end: format(endTime, "HH:mm"),
        time: time,
        start: start,
        error: null,
      });
    }
    this.afterChangedSchedule();
  };

  openStartTimer = () => {
    this.startTimer.open();
  };

  openEndTimer = () => {
    this.endTimer.open();
  };

  // Execute after changing schedule
  afterChangedSchedule = () => {
    this.setState({
      disable: false,
    });
  };
  onClickUpdateButton = async () => {
    if (this.state.disable) return false;
    this.setState({
      disable: true,
    });
    try {
      const payload = this.props.appointment.doctor
        ? {
            patientId: this.props.appointment.patient.patientId,
            createTime: this.props.appointment.createTime,
            from: parseISO(
              `${this.state.selectedDate}T${this.state.start}:00`
            ).toISOString(),
            to: parseISO(
              `${this.state.selectedDate}T${this.state.end}:00`
            ).toISOString(),
            medicalDoctorId: this.props.appointment.doctor.doctorId,
          }
        : {
            patientId: this.props.appointment.patient.patientId,
            createTime: this.props.appointment.createTime,
            from: parseISO(
              `${this.state.selectedDate}T${this.state.start}:00`
            ).toISOString(),
            to: parseISO(
              `${this.state.selectedDate}T${this.state.end}:00`
            ).toISOString(),
          };
      const compFrom = parseISO(payload.from);
      const compTo = parseISO(payload.to);
      if (!isAfter(compTo, compFrom)) {
        this.setState({
          error: AppointmentUpdateResource.update.j009,
        });
        return;
      }
      this.setState({ isLoading: true });
      const { errors } = await client.mutate({
        mutation: UpdateAppointment,
        variables: payload,
      });
      if (errors) {
        let error = AppointmentUpdateResource.update.j001;
        if (errors.find((e) => e.errorType === "E02")) {
          error = AppointmentUpdateResource.update.j002;
        } else if (errors.find((e) => e.errorType === "E03")) {
          error = AppointmentUpdateResource.update.j003;
        } else if (errors.find((e) => e.errorType === "E05")) {
          error = AppointmentUpdateResource.update.j004;
        } else if (errors.find((e) => e.errorType === "E06")) {
          error = AppointmentUpdateResource.update.j006;
        } else if (errors.find((e) => e.errorType === "E07")) {
          error = AppointmentUpdateResource.update.j007;
        } else if (errors.find((e) => e.errorType === "E08")) {
          error = AppointmentUpdateResource.update.j008;
        } else if (errors.find((e) => e.errorType === "E09")) {
          error = AppointmentUpdateResource.update.j006;
        }
        this.setState({ error });
        return;
      }
      this.setState({
        error: null,
      });
      this.props.onFinished();
      this.props.onClose();
    } catch (err) {
      this.setState({
        error: AppointmentUpdateResource.update.j005,
      });
    } finally {
      this.setState({
        disable: false,
        isLoading: false,
      });
    }
  };

  handleOpenStartTimePicker = () => {
    this.setState({
      isOpenStartTimePicker: true,
    });
  };

  handleCloseStartTimePicker = () => {
    this.setState({
      isOpenStartTimePicker: false,
    });
  };

  handleOpenEndTimePicker = () => {
    this.setState({
      isOpenEndTimePicker: true,
    });
  };

  handleCloseEndTimePicker = () => {
    this.setState({
      isOpenEndTimePicker: false,
    });
  };

  render() {
    const { appointment, classes } = this.props;
    const { patient, doctor } = appointment;
    const { selectedDate, start, end } = this.state;
    const enterpriseName = appointment.enterpriseName || "";

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
        <DialogTitle className={classes.dialogTitle}>
          <DialogTitleText>
            {
              AppointmentUpdateResource.render.MuiPickersUtilsProvider
                .DialogTitle.Text.j001
            }
          </DialogTitleText>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <PatientArea>
            <PatientInfo patient={patient} enterpriseName={enterpriseName} />
          </PatientArea>
          <AppointmentArea>
            <Container>
              <Item className={classes.doctor}>
                <Title>
                  {
                    AppointmentUpdateResource.render.MuiPickersUtilsProvider
                      .DialogContent.Appointment.Container.Title.j001
                  }
                </Title>
                <Content>
                  {doctor
                    ? `${doctor.familyName} ${doctor.givenName}`
                    : AppointmentUpdateResource.render.MuiPickersUtilsProvider
                        .DialogContent.Appointment.Container.Content.j001}
                </Content>
              </Item>
              <Item>
                <Title>
                  {
                    AppointmentUpdateResource.render.MuiPickersUtilsProvider
                      .DialogContent.Appointment.Container.Title.j002
                  }
                </Title>
                <Content>
                  <input
                    className={classes.input}
                    readOnly={true}
                    value={this.formatterSelectedDate(selectedDate)}
                    onClick={this.openPicker}
                  />
                  <Button
                    color="primary"
                    variant="raised"
                    className={classes.iconButton}
                    onClick={this.openPicker}
                  >
                    <DateRange />
                  </Button>
                  <DatePicker
                    style={{ display: "none" }}
                    format="yyyy MM dd"
                    ref={(node) => {
                      this.picker = node;
                    }}
                    value={this.formatterSelectedDate(selectedDate)}
                    onChange={this.handleDateChange}
                    maxDate={endMonthOfNextYear()}
                  />
                </Content>
              </Item>
              <Item>
                <Title>
                  {
                    AppointmentUpdateResource.render.MuiPickersUtilsProvider
                      .DialogContent.Appointment.Container.Title.j003
                  }
                </Title>
                <Content>
                  <CustomNativeSelect
                    disableUnderline
                    value={this.state.time}
                    onChange={this.handleTimeChange}
                    IconComponent={ArrowDropDownIcon}
                  >
                    <option value={15}>
                      15
                      {
                        AppointmentUpdateResource.render.MuiPickersUtilsProvider
                          .DialogContent.Appointment.Container
                          .ItemSecondaryAction.Select.option.j001
                      }
                    </option>
                    <option value={30}>
                      30
                      {
                        AppointmentUpdateResource.render.MuiPickersUtilsProvider
                          .DialogContent.Appointment.Container
                          .ItemSecondaryAction.Select.option.j001
                      }
                    </option>
                    <option value={60}>
                      60
                      {
                        AppointmentUpdateResource.render.MuiPickersUtilsProvider
                          .DialogContent.Appointment.Container
                          .ItemSecondaryAction.Select.option.j001
                      }
                    </option>
                  </CustomNativeSelect>
                </Content>
              </Item>
              <Item>
                <Title>
                  {
                    AppointmentUpdateResource.render.MuiPickersUtilsProvider
                      .DialogContent.Appointment.Container.Title.j004
                  }
                </Title>
                <Content>
                  <input
                    className={classes.input}
                    readOnly={true}
                    value={start}
                    onClick={this.handleOpenStartTimePicker}
                  />
                  <Button
                    variant="raised"
                    color="primary"
                    className={classes.iconButton}
                    onClick={this.handleOpenStartTimePicker}
                  >
                    <AccessTime />
                  </Button>
                  <TimePicker
                    time={this.state.start}
                    updateTime={this.handleStartTimeChange}
                    open={this.state.isOpenStartTimePicker}
                    handleClose={this.handleCloseStartTimePicker}
                  />
                </Content>
              </Item>
              <Item>
                <Title>
                  {
                    AppointmentUpdateResource.render.MuiPickersUtilsProvider
                      .DialogContent.Appointment.Container.Title.j005
                  }
                </Title>
                <Content>
                  <input
                    className={classes.input}
                    readOnly={true}
                    value={end}
                    onClick={this.handleOpenEndTimePicker}
                  />
                  <Button
                    variant="raised"
                    color="primary"
                    className={classes.iconButton}
                    onClick={this.handleOpenEndTimePicker}
                  >
                    <AccessTime />
                  </Button>
                  <TimePicker
                    time={this.state.end}
                    updateTime={this.handleEndTimeChange}
                    open={this.state.isOpenEndTimePicker}
                    handleClose={this.handleCloseEndTimePicker}
                  />
                </Content>
              </Item>
            </Container>
          </AppointmentArea>
          <Typography variant="subheading" gutterBottom color="error">
            {this.state.error}
          </Typography>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            className={`${classes.buttonCancel} ${classes.dialogActionsButtonCancel}`}
            onClick={this.props.onClose}
          >
            {
              AppointmentUpdateResource.render.MuiPickersUtilsProvider
                .DialogActions.Button.j001
            }
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={`${classes.buttonOk} ${classes.dialogActionsButtonOk}`}
            disabled={this.state.disable}
            onClick={this.onClickUpdateButton}
          >
            {
              AppointmentUpdateResource.render.MuiPickersUtilsProvider
                .DialogActions.Button.j002
            }
          </Button>
        </DialogActions>
        <LoadingScreenForDialog isLoading={this.state.isLoading} />
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(AppointmentUpdate);
