import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FontP from "../../../atoms/fonts/FontP";
import Radio from "../../../atoms/forms/Radio";
import PatientInfo from "../../dialog/PatientInfo";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core/";

import { formatPeriod } from "../../../../Utils/DateUtil";
import { japaneseList } from "../../../../Resources/japaneseList";
import Panel from "../../../atoms/panels/Panel";
import AppointmentRejectConfirmDialog from "../../../organisms/appointment/unapproved/AppointmentRejectConfirmDialog";
import IconNotInsurance from "../../../atoms/icons/IconNotInsurance";

const maxlength = 200;
const AppointmentRejectDialogResource =
  japaneseList.Components.Appointment.AppointmentRejectDialog;
const AppointmentListResource =
  japaneseList.Components.Appointment.AppointmentList;

const styles = (theme) => ({
  enable: {
    pointerEvents: "auto",
    backgroundColor: "#eafbf9 0% 0% no-repeat padding-box;",
    marginTop: "4px",
    width: "100%",
  },
  disable: {
    pointerEvents: "none",
    backgroundColor: "#cccccc",
    marginTop: "4px",
    width: "100%",
  },
  dialogTitle: {
    width: "810px",
    height: "68px",
    margin: "0",
    padding: "24px 0 24px 24px",
    borderBottom: "1px solid #cccccc",
  },
  dialogContent: {
    width: "810px",
    margin: "0",
    padding: "22px 25px 22px 24px",
  },
  dialogActions: {
    width: "810px",
    height: "70px",
    margin: "0",
    padding: "14px 25px 16px 24px",
    borderTop: "1px solid #cccccc",
    "& button": {
      height: "40px",
    },
    "& span": {
      fontSize: "14px",
      fontWeight: "bold",
      color: "#ffffff",
    },
  },
  buttonOk: {
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
  },
  dialogActionsButtonOk: {
    width: "120px",
    margin: "0",
    padding: "13px 46px",
    borderRadius: "5px",
  },
  buttonCancel: {
    backgroundColor: "#808080",
    boxShadow: `0px 2px 0px ${theme.button.cancel.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
  },
  dialogActionsButtonCancel: {
    width: "120px",
    margin: "0 20px 0 0",
    padding: "13px 25px",
    borderRadius: "5px",
  },
  iconNotInsurance: {
    border: `1px solid ${theme.color.notInsurance}`,
    fontSize: "10px",
    padding: "0",
    marginLeft: "10px",
  },
  iconInsurance: {
    fontSize: "10px",
    border: `1px solid ${theme.color.primary1}`,
    padding: "0",
    marginLeft: "10px",
  },
  panel: {
    padding: "15px 0 0 0",
    margin: "0",
    color: "#808080",
  },
  fontPStyle: {
    fontSize: "11px",
    lineHeight: "1.3",
  },
  radio: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "4px",
  },
});

const Hospital = styled.div`
  height: 18px;
  line-height: 18px;
`;

const Other = styled.div`
  margin-top: 10px;
`;

const ReasonOption = styled.div`
  font-size: 14px;
  line-height: 14px;
  margin-left: 5px;
  display: inline-block;
`;

const TextareaCustom = styled.textarea`
  display: flex;
  width: 640px;
  height: 64px;
  padding: 5px 10px;
  resize: none;
  font-size: 13px;
  font-family: inherit;
  background-color: ${(p) => p.theme.color.primaryVariant3};
  color: ${(p) => p.theme.color.font};
  border-radius: 5px;
  -webkit-appearance: none;
  &:focus {
    outline: none;
  }
`;

const AppointmentArea = styled.div`
  margin: 21px 0 0 0;
`;

const Container = styled.div`
  border-bottom: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
`;

const DialogContentText = styled.div`
  height: 14px;
  line-height: 14px;
  font-size: 14px;
`;

const PatientArea = styled.div`
  margin: 0;
`;

const Title = styled.div`
  background-color: #eeeeee;
  height: 35px;
  padding-left: 10px;
  color: #808080;
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
`;

const Content = styled.div`
  height: 35px;
  padding-left: 20px;
  color: #393939;
  text-align: left;
  font-size: 14px;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  word-break: break-word;
`;

const DialogTitleText = styled.div`
  height: 20px;
  line-height: 20px;
  font-size: 20px;
  font-weight: bold;
`;

const Item = styled.div`
  display: grid;
  grid-template-columns: 68px auto;
  border-top: 1px solid #cccccc;
`;

const TitleRejectReson = styled(Title)`
  height: 135px;
`;

const ContentRejectReson = styled(Content)`
  height: 135px;
  display: table-cell;
  padding-top: 6px;
  padding-bottom: 10px;
  margin-right: 32px;
`;

class AppointmentRejectDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectDefault: true,
      openRejectConfirmDialog: false,
      reasonText: "",
      reasonTextTrim: "",
      enable: true,
    };
  }

  handleClickOk = () => {
    this.state.enable &&
      this.setState({
        openRejectConfirmDialog: true,
      });
  };

  handleCloseConfirmDialog = () => {
    this.setState({ openRejectConfirmDialog: false });
  };

  handleSelect = (e) => {
    this.setState({
      selectDefault: e.target.value === "default",
      enable:
        e.target.value === "default" ||
        (this.state.reasonTextTrim && this.state.reasonTextTrim !== ""),
      reasonText: e.target.value === "default" ? "" : this.state.reasonText,
      reasonTextTrim:
        e.target.value === "default" ? "" : this.state.reasonTextTrim,
    });
  };

  handleClose = () => {
    this.setState({
      selectDefault: true,
      openRejectConfirmDialog: false,
      reasonText: "",
      reasonTextTrim: "",
      enable: true,
    });
    this.props.onClose();
  };

  handleChangeText = (e) => {
    let limitStr = e.target.value.substring(0, maxlength);
    this.setState({
      reasonText: limitStr,
      reasonTextTrim: limitStr.trim(),
      enable: limitStr.trim() !== "",
    });
  };

  render() {
    const { classes, appointment } = this.props;
    const patient = appointment && appointment.patient;
    const doctor = appointment && appointment.doctor;
    const enterpriseName = appointment.enterpriseName || "";

    return (
      appointment && (
        <div>
          <Dialog
            disableBackdropClick
            open={this.props.open && !this.state.openRejectConfirmDialog}
            onClose={this.handleClose}
            maxWidth="md"
          >
            <DialogTitle className={classes.dialogTitle}>
              <DialogTitleText>
                {
                  AppointmentRejectDialogResource.render.div.Dialog.DialogTitle
                    .j001
                }
              </DialogTitleText>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <DialogContentText>
                {
                  AppointmentRejectDialogResource.render.div.Dialog
                    .DialogContent.NoteTitle.j001
                }
              </DialogContentText>
              <PatientArea>
                <PatientInfo patient={patient} enterpriseName={enterpriseName} />
              </PatientArea>
              <AppointmentArea>
                <Container>
                  <Item>
                    <Title>
                      {
                        AppointmentRejectDialogResource.render.div.Dialog
                          .DialogContent.Item.j001
                      }
                    </Title>
                    <Content>
                      {doctor.familyName} {doctor.givenName}
                    </Content>
                  </Item>
                  <Item>
                    <Title>
                      {
                        AppointmentRejectDialogResource.render.div.Dialog
                          .DialogContent.Item.j002
                      }
                    </Title>
                    <Content>
                      {appointment.menu.displayName}
                      <IconNotInsurance
                        className={
                          appointment.menu.supportsInsuranceTreatment
                            ? classes.iconInsurance
                            : classes.iconNotInsurance
                        }
                      >
                        {appointment.menu.supportsInsuranceTreatment
                          ? AppointmentListResource.insuranceCard.ok
                          : AppointmentListResource.insuranceCard.ng}
                      </IconNotInsurance>
                    </Content>
                  </Item>
                  <Item>
                    <Title>
                      {
                        AppointmentRejectDialogResource.render.div.Dialog
                          .DialogContent.Item.j003
                      }
                    </Title>
                    <Content>
                      {appointment.from && appointment.to
                        ? formatPeriod(appointment.from, appointment.to)
                        : ""}
                    </Content>
                  </Item>
                  <Item>
                    <TitleRejectReson>
                      {
                        AppointmentRejectDialogResource.render.div.Dialog
                          .DialogContent.Item.j004
                      }
                    </TitleRejectReson>
                    <ContentRejectReson>
                      <Hospital>
                        {this.state.selectDefault ? (
                          <Radio
                            defaultChecked
                            onClick={this.handleSelect}
                            name="Reason"
                            value="default"
                            className={classes.radio}
                          ></Radio>
                        ) : (
                          <Radio
                            onClick={this.handleSelect}
                            name="Reason"
                            value="default"
                            className={classes.radio}
                          ></Radio>
                        )}
                        <ReasonOption>
                          {
                            AppointmentRejectDialogResource.render.div.Dialog
                              .DialogContent.Reason.j003
                          }
                        </ReasonOption>
                      </Hospital>
                      <Other>
                        {this.state.selectDefault ? (
                          <Radio
                            onClick={this.handleSelect}
                            name="Reason"
                            value="other"
                            className={classes.radio}
                          ></Radio>
                        ) : (
                          <Radio
                            defaultChecked
                            onClick={this.handleSelect}
                            name="Reason"
                            value="other"
                            className={classes.radio}
                          ></Radio>
                        )}
                        <ReasonOption>
                          {
                            AppointmentRejectDialogResource.render.div.Dialog
                              .DialogContent.Reason.j004
                          }
                        </ReasonOption>
                      </Other>
                      <TextareaCustom
                        placeholder={
                          AppointmentRejectDialogResource.render.div.Dialog
                            .DialogContent.TextareaCustom.j001
                        }
                        value={this.state.reasonText}
                        className={
                          this.state.selectDefault
                            ? classes.disable
                            : classes.enable
                        }
                        onChange={this.handleChangeText}
                        disabled={this.state.selectDefault}
                      ></TextareaCustom>
                    </ContentRejectReson>
                  </Item>
                </Container>
              </AppointmentArea>
              <Panel padding={"vmh0"} className={classes.panel}>
                <FontP
                  color="gray"
                  whiteSpace={"preWrap"}
                  className={classes.fontPStyle}
                >
                  {
                    AppointmentRejectDialogResource.render.div.Dialog
                      .DialogContent.Note.j001
                  }
                </FontP>
              </Panel>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button
                variant="raised"
                onClick={this.handleClose}
                className={`${classes.buttonCancel} ${classes.dialogActionsButtonCancel}`}
              >
                {
                  AppointmentRejectDialogResource.render.div.Dialog
                    .DialogContent.Button.DisplayText.j001
                }
              </Button>
              <Button
                variant="raised"
                onClick={this.handleClickOk}
                className={`${classes.buttonOk} ${classes.dialogActionsButtonOk}`}
                disabled={!this.state.enable}
              >
                {
                  AppointmentRejectDialogResource.render.div.Dialog
                    .DialogContent.Button.DisplayText.j002
                }
              </Button>
            </DialogActions>
          </Dialog>
          {this.props.appointment && (
            <AppointmentRejectConfirmDialog
              history={this.props.history}
              open={this.state.openRejectConfirmDialog}
              handleClickRejectConfirmButton={
                this.handleClickRejectConfirmButton
              }
              appointment={this.props.appointment}
              onClose={this.handleCloseConfirmDialog}
              selectDefault={this.state.selectDefault}
              reasonText={this.state.reasonTextTrim}
              doctor={doctor}
              handleClose={this.handleClose}
              facilityType={this.props.facilityType}
            />
          )}
        </div>
      )
    );
  }
}

export default withStyles(styles)(AppointmentRejectDialog);
