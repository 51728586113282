import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Fade from "@material-ui/core/Fade";
import Dialog from "@material-ui/core/Dialog";
import Heading1 from "../../../atoms/headings/Heading1";
import LoadingScreen from "../../../molecules/others/LoadingScreen";
import AppointmentUnapprovedList from "../../../organisms/appointment/AppointmentUnapprovedList";
import AppointmentApprovedDialog from "../../../organisms/appointment/unapproved/AppointmentApprovedDialog";
import DialogWithStylesUtil from "../../../molecules/dialogs/DialogWithStylesUtil";
import AppointmentRejectDialog from "../../../organisms/appointment/unapproved/AppointmentRejectDialog";

import { Query } from "@apollo/react-components";
import {
  client,
  ApproveAppointment,
  GetDoctor,
  GetHospital,
  ListAppointments,
} from "../../../../graphql";

import BasicTemplate from "../../../templates/BasicTemplate";
import { japaneseList } from "../../../../Resources/japaneseList";
import Titles from "../../../../Resources/Titles";
import { startOfDay } from "date-fns";
import { endMonthOfNextYear } from "../../../../Utils/DateUtil";

const styles = (theme) => ({
  root: {
    minWidth: "80%",
    marginTop: theme.spacing.unit * 3,
  },
});
const AppointmentUnapprovedResource = japaneseList.Pages.Appointment.Unapproved;
const ApprovedCompleteResources =
  japaneseList.Components.Appointment.ApprovedStatusComplete.ApprovedComplete;
const ApprovedErrorResources =
  japaneseList.Components.Appointment.ApprovedStatusComplete.ApprovedError;

const FACILTY_TYPE = { hospital: "hospital", pharmacy: "pharmacy" };

class AppointmentUnapproved extends Component {
  state = {
    variables: {
      start: startOfDay(new Date()),
      end: endMonthOfNextYear(),
      status: ["unapproved"],
    },
    isOpenConfirmApprovedDialog: false,
    isOpenApprovedStatusDialog: false,
    approvedStatus: true,
    appointmentInfor: {},
    isLoading: false,
    openRejectDialog: false,
    numberOfUnapprovedAppointment: null,
    facilityType: null,
    isSupportOnlineQualificationSystem: false,
  };

  ErrorMessage;

  setNumberOfUnapprovedAppointment = () => {
    client
      .query({
        query: GetDoctor,
      })
      .then((data) => {
        if (data) {
          this.setState({
            numberOfUnapprovedAppointment:
              (data.data.doctor &&
                data.data.doctor.numberOfUnapprovedAppointment) ||
              0,
          });
        }
      });
  };

  initializeHospital = () => {
    client
      .query({
        query: GetHospital,
      })
      .then((res) => {
        this.setState({
          facilityType: res.data.hospital.facilityType,
          isSupportOnlineQualificationSystem:
            res.data.hospital.isSupportOnlineQualificationSystem,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // dialog event
  // --------------------------------------------------
  handleCloseConfirmApprovedDialog = (e) => {
    this.setState({
      isOpenConfirmApprovedDialog: false,
    });
  };

  handleOpenConfirmApprovedDialog = (appo) => {
    this.setState({
      isOpenConfirmApprovedDialog: true,
      appointmentInfor: {
        ...appo.patient,
        ...appo.menu,
        from: appo.from,
        to: appo.to,
        createTime: appo.createTime,
        doctor: appo.doctor,
        enterpriseName: appo.enterpriseName || "",
      },
    });
  };

  handleCloseApprovedStatusDialog = (e) => {
    this.setState({
      isOpenApprovedStatusDialog: false,
      isOpenConfirmApprovedDialog: false,
    });
  };

  handleGotoAppointmentList = (e) => {
    this.props.history.push("/appointment");
  };

  handleOpenApprovedStatusDialog = async (appointmentKey, appointmentTime) => {
    this.setState({
      isLoading: true,
    });

    try {
      let { data, errors } = await client.mutate({
        mutation: ApproveAppointment,
        variables: {
          patientId: appointmentKey.patientId,
          createTime: appointmentKey.createTime,
        },
      });
      if (errors) {
        errors.forEach((error) => {
          let errorResource = ApprovedErrorResources.Errors.find(
            (item) => item.code === error.errorType
          );
          this.ErrorMessage = errorResource && errorResource.text;
        });
        this.setState({
          isLoading: false,
          approvedStatus: false,
          isOpenApprovedStatusDialog: true,
        });
        return;
      }
      if (data && data.approveAppointment.approvedSucceed) {
        this.setState({
          isLoading: false,
          approvedStatus: true,
          isOpenApprovedStatusDialog: true,
          isOpenConfirmApprovedDialog: false,
        });
      }
    } catch (error) {
      console.log("error", error);
      let errorResource = ApprovedErrorResources.Errors.find(
        (item) => item.code === "E02"
      );
      this.ErrorMessage = errorResource && errorResource.text;
      this.setState({
        isLoading: false,
        approvedStatus: false,
        isOpenApprovedStatusDialog: true,
      });
      return;
    }
  };

  handleClickRejectButton = (appo) => {
    this.setState({
      openRejectDialog: true,
      appointmentSelect: appo,
    });
  };

  handleCloseRejectDialog = () => {
    this.setState({ openRejectDialog: false });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.numberOfUnapprovedAppointment !==
      this.state.numberOfUnapprovedAppointment
    ) {
      this.refetch();
    }
  }
  componentDidMount() {
    this.setNumberOfUnapprovedAppointment();
    this.initializeHospital();
  }

  render() {
    const { classes } = this.props;
    const { variables } = this.state;
    const setRefetch = (refetch) => {
      this.refetch = refetch;
    };
    let appointments = [];
    const main = (
      <React.Fragment>
        <div className={classes.root}>
          <Heading1>
            {AppointmentUnapprovedResource.render.div.h1.j001}
          </Heading1>
          {
            // polling every 7 seconds, because apply changing by doctor.
            <Query query={ListAppointments} variables={variables}>
              {({ loading, error, data, refetch }) => {
                if (loading) {
                  setRefetch(refetch);
                  return (
                    <Fade
                      in={loading}
                      style={{ transitionDelay: loading ? "800ms" : "0ms" }}
                      unmountOnExit
                    >
                      <LinearProgress />
                    </Fade>
                  );
                }
                if (error) {
                  // handle error
                } else {
                  appointments =
                    (data.appointments && data.appointments.items) ||
                    appointments;
                }
                return (
                  <React.Fragment>
                    <AppointmentUnapprovedList
                      appointments={appointments}
                      isSupportOnlineQualificationSystem={
                        this.state.isSupportOnlineQualificationSystem
                      }
                      handleOpenConfirmApprovedDialog={
                        this.handleOpenConfirmApprovedDialog
                      }
                      handleClickRejectButton={this.handleClickRejectButton}
                    />
                    {this.state.appointmentInfor.doctor && (
                      <AppointmentApprovedDialog
                        open={this.state.isOpenConfirmApprovedDialog}
                        handleCloseConfirmApprovedDialog={
                          this.handleCloseConfirmApprovedDialog
                        }
                        onClose={this.handleCloseConfirmApprovedDialog}
                        handleOpenApprovedStatusDialog={
                          this.handleOpenApprovedStatusDialog
                        }
                        appointmentInfor={this.state.appointmentInfor}
                      />
                    )}
                    <DialogWithStylesUtil
                      open={this.state.isOpenApprovedStatusDialog}
                      onClose={() => {
                        window.location.reload();
                      }}
                      title={
                        this.state.approvedStatus
                          ? ApprovedCompleteResources.render.div.Dialog
                              .DialogTitle.j001
                          : ""
                      }
                      children={(this.state.approvedStatus
                        ? this.state.facilityType === FACILTY_TYPE.hospital
                          ? ApprovedCompleteResources.render.div.Dialog
                              .DialogContent.j001
                          : ApprovedCompleteResources.render.div.Dialog
                              .DialogContent.j002
                        : this.ErrorMessage
                      )
                        .split("\n")
                        .map((item, index) => {
                          return (
                            <span key={index}>
                              {index > 0 ? <br /> : ""}
                              {item}
                            </span>
                          );
                        })}
                      buttons={{
                        ok: {
                          display: true,
                          text: this.state.approvedStatus
                            ? this.state.facilityType === FACILTY_TYPE.hospital
                              ? ApprovedCompleteResources.buttonHospitalOk
                              : ApprovedCompleteResources.buttonPharmacyOk
                            : ApprovedErrorResources.buttonOk,
                          handleClick: this.state.approvedStatus
                            ? this.handleGotoAppointmentList
                            : () => {
                                window.location.reload();
                              },
                        },
                        cancel: {
                          display: this.state.approvedStatus,
                          text: ApprovedCompleteResources.buttonCancel,
                          handleClick: () => {
                            window.location.reload();
                          },
                        },
                      }}
                      alignCenter={true}
                    />
                    <Dialog
                      open={Boolean(this.state.isLoading)}
                      fullWidth={true}
                      PaperProps={{
                        style: {
                          willChange: "initial !important",
                        },
                      }}
                    >
                      <LoadingScreen
                        isLoading={Boolean(this.state.isLoading)}
                      />
                    </Dialog>
                    {this.state.appointmentSelect && (
                      <AppointmentRejectDialog
                        history={this.props.history}
                        open={this.state.openRejectDialog}
                        appointment={this.state.appointmentSelect}
                        onClose={this.handleCloseRejectDialog}
                        facilityType={this.state.facilityType}
                      />
                    )}
                  </React.Fragment>
                );
              }}
            </Query>
          }
        </div>
        <LoadingScreen isLoading={Boolean(this.state.isLoading)} />
      </React.Fragment>
    );
    return <BasicTemplate main={main} title={Titles.unapprovedAppointment} />;
  }
}

export default withStyles(styles)(AppointmentUnapproved);
